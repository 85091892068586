export class Capping {

  deliver?: number;     // Eroga
  eachNumber?: number;  // A distanza di
  everyNumber?: number; // Ogni

  deliverType?: number; // Eroga "Tipo"
  eachType?: number;    // A distanza di "Tipo"
  everyType?: number;   // Ogni "Tipo"
  disabled?: number;
  device?: number;
}
