import {Component, Input} from '@angular/core';
import {NotificationBarElement} from '../_helpers/notificationBarElement';
import {NotificationTypeEnum} from '../_helpers/notificationTypeEnum';
import {SelectItem} from 'primeng/api';
import {EntityAccount} from '../../../__helpers/entities/EntityAccount';
import {JwtPermissions} from '../../../__helpers/jwtPermissions.service';
import {InfoService} from '../../../services/info.service';
import { Constants } from 'app/__helpers/entities/Constants';
import {CommunicationBarService} from '../communicationBar.service';
import {NavigationEnd, Router} from '@angular/router';
import {BillingService} from "../../../_billingMVC/billing.service";

@Component({
  selector: 'app-communication-bar',
  templateUrl: './communicationBar.component.html',
  styleUrls: ['./communicationBar.component.css']
})

export class CommunicationBarComponent {
  @Input() myBarList: NotificationBarElement[];
  @Input() entityInfo: EntityAccount;

  readonly MAP_NOTIFIC_CLASS: SelectItem[] = [
    {value: NotificationTypeEnum.NOTICE, label: 'message-info-notification', icon: 'fal fa-info-circle blueColor'},
    {value: NotificationTypeEnum.ALERT, label: 'message-alert-notification', icon: 'fal fa-exclamation-triangle yellowColor'}
  ];

  Constants = Constants;

  constructor(private infoService: InfoService, private router: Router) {
    router.events.subscribe((val) => {
      if ( val instanceof NavigationEnd) {
        CommunicationBarService.currentPreview = undefined;
      }
    });
  }

  isPublisher() {
    return this.infoService.isPublisher();
  }

  isAdmin() {
    return JwtPermissions.admin;
  }

  removeMyBar(myIndex, myList) {
    myList.splice(myIndex, 1);
  }

  haveToShowPaymentProfileAlert(entityInfo: EntityAccount): boolean {
    return BillingService.haveToShowPaymentProfileAlert(entityInfo);
  }

  get currentPreview(): NotificationBarElement[] {
    return CommunicationBarService.currentPreview;
  }

}
