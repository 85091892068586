/**
 * @Pipe
 * @description pipe to format numeric values to argentina readable currency values
 * @input number
 * @output formatted numeric value
 */

import { Pipe, PipeTransform } from '@angular/core';
import {SelectItem} from 'primeng/api';

@Pipe({
  name: 'getElBySpecifiedValue'
})

// Filtra l'array con gli elementi che hanno value.prop settato a value.value
export class GetElBySpecifiedValuePipe implements PipeTransform {
  transform(choice: any[], value?: {value: any, prop: string}): any {
    let elemen: SelectItem;
    if (value && choice && choice.length > 0) {
      elemen = choice.find(elem => elem[value.prop] === value.value);
    }
    return elemen;
  }
}

