import {Injectable} from '@angular/core';

@Injectable()
export class PageService {
  windowWidth = window.innerWidth;
  constructor() {
  }

  // Ritorna true se la dimensione della finestra è < di len
  isMobile(len: number): boolean {
    this.windowWidth = window.innerWidth;
    return (this.windowWidth <= len);
  }


}
