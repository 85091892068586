export class MediaFilter {
  entity?: number[];
  advertiser?: number[];
  newDsp?: number[];
  newSsp?: number[];
  segment?: number[];
  campaign?: number[];
  strategy?: number[];
  substrategy?: number[];
  creativeGroup?: number[];
  media?: number[];
  deal?: number[];
  brand?: number[];
  format?: number[];
  agency?: number[];
  creativeGroupCategory?: number[];
  creativeGroupRelatedCampaign?: number[];
}
