import {SelectItem} from 'primeng/api';
import {SegmentTypeElement} from './SegmentTypeElement';
import {SegmentPath} from './SegmentPath';
import {Constants} from './Constants';

export class SegmentTypes {

  static readonly TAG_ONETAG = 2;
  static readonly TAG_AWIN = 3;
  static readonly TAG_NO_ONETAG = 1;

  static readonly AUDIENCE = 1;

  static readonly FUNNEL_GENERIC = 2;
  static readonly STEP1 = 5;
  static readonly STEP2 = 6;
  static readonly STEP3 = 7;
  static readonly STEP4 = 8;
  static readonly STEP5 = 9;

  static readonly TYP_OPEN = 4; // Conversions open
  static readonly TYP_CLOSED = 3; // Attributed converions

  static readonly LANDING_CLOSED = 10;
  static readonly LANDING_OPEN = 11;
  static readonly GENERATED = 12;
  static readonly ECOMMERCE_CART = 13;
  static readonly ECOMMERCE_CHECKOUT = 14;
  static readonly ECOMMERCE_AUDIENCE = 15;

  // activators
  static readonly ACTIVATOR_BY_CODE = 1;
  static readonly ACTIVATOR_PAGE_VIEW = 2;
  static readonly ACTIVATOR_CLICK_ELEMENT = 3;


  static readonly ALL_PAGES = 16;

  static readonly EMAIL_1 = 19;
  static readonly EMAIL_2 = 20;

  static readonly CART_AWIN = 21;
  static readonly CHECKOUT_AWIN = 22;


  static readonly GROUP_AUDIENCE = 1;
  static readonly GROUP_FUNNEL = 2;
  static readonly GROUP_CONVERSION = 3;
  static readonly GROUP_LANDING = 4;
  static readonly GROUP_GENERATED = 5;

  static readonly AWIN_PARTNER = 1;


  static readonly GROUPS: SelectItem[] = [
    {value: SegmentTypes.GROUP_AUDIENCE, label: 'Audience'},
    {value: SegmentTypes.GROUP_FUNNEL, label: 'Funnel'},
    {value: SegmentTypes.GROUP_CONVERSION, label: 'Conversion'},
    {value: SegmentTypes.GROUP_LANDING, label: 'Landing'},
    {value: SegmentTypes.GROUP_GENERATED, label: 'Generated'}
  ];

  static SEGMENT_TYPES: SegmentTypeElement[] = [];
  static SEGMENT_TYPES_SELECTABLE: (SelectItem & {description: string})[] = [];
  static SEGMENT_TYPES_SELECTABLE_VOID_SEL: SelectItem[] = [];

  static readonly GROUPS_AGGREGATOR: {valueSegm: number, valueGroup: number}[] = [
    {valueSegm: SegmentTypes.AUDIENCE, valueGroup: SegmentTypes.GROUP_AUDIENCE},
    {valueSegm: SegmentTypes.FUNNEL_GENERIC, valueGroup: SegmentTypes.GROUP_FUNNEL},
    {valueSegm: SegmentTypes.STEP1, valueGroup: SegmentTypes.GROUP_FUNNEL},
    {valueSegm: SegmentTypes.STEP2, valueGroup: SegmentTypes.GROUP_FUNNEL},
    {valueSegm: SegmentTypes.STEP3, valueGroup: SegmentTypes.GROUP_FUNNEL},
    {valueSegm: SegmentTypes.STEP4, valueGroup: SegmentTypes.GROUP_FUNNEL},
    {valueSegm: SegmentTypes.STEP5, valueGroup: SegmentTypes.GROUP_FUNNEL},
    {valueSegm: SegmentTypes.TYP_CLOSED, valueGroup: SegmentTypes.GROUP_CONVERSION},
    {valueSegm: SegmentTypes.TYP_OPEN, valueGroup: SegmentTypes.GROUP_CONVERSION},
    {valueSegm: SegmentTypes.LANDING_CLOSED, valueGroup: SegmentTypes.GROUP_LANDING},
    {valueSegm: SegmentTypes.LANDING_OPEN, valueGroup: SegmentTypes.GROUP_LANDING},
    {valueSegm: SegmentTypes.GENERATED, valueGroup: SegmentTypes.GROUP_GENERATED}
  ];

  static readonly CONVERSION_EVENT = 277; // il vecchio LEAD_EVENT
  static readonly SALE_EVENT = 278;

  static readonly CLOSED_CONVERSION_EVENT = 270;
  static readonly CLOSED_SALE_EVENT = 271;

  static readonly ATTRIBUTION_MODE_LAST_VIEW = {value: Constants.LAST_IMPRESSION, label: 'Last View'};
  static readonly ATTRIBUTION_MODE_LAST_CLICK = {value: Constants.LAST_CLICK, label: 'Last Click'};


  static readonly ALL_PAGES_EVENT: SegmentPath = { name: 'All Pages', type: SegmentTypes.ALL_PAGES};

  static readonly AWIN_DEFAULT_EVENTS: SegmentPath[] = [
    { name: 'All conversions', type: SegmentTypes.TYP_OPEN, event:  SegmentTypes.SALE_EVENT, partner: SegmentTypes.AWIN_PARTNER,
      closed_segment: { name: 'Attributed All Conversions', type: SegmentTypes.TYP_CLOSED, event: SegmentTypes.CLOSED_SALE_EVENT, partner: SegmentTypes.AWIN_PARTNER}}
  ];

 /* static readonly AWIN_DEFAULT_EVENTS: SegmentPath[] = [
    { name:"Cart Awin", type: SegmentTypes.CART_AWIN, partner : SegmentTypes.AWIN_PARTNER},
    { name:"Checkout Awin", type: SegmentTypes.CHECKOUT_AWIN, partner : SegmentTypes.AWIN_PARTNER},
    { name:"Sale Awin", type: SegmentTypes.TYP_OPEN, event:  271, partner : SegmentTypes.AWIN_PARTNER}
  ];*/

  static readonly AWIN_DEFAULT_ATTR_CONV: SegmentPath[] = [
    { name: 'Attributed All Conversions', type: SegmentTypes.TYP_CLOSED, event: SegmentTypes.CLOSED_SALE_EVENT, partner: SegmentTypes.AWIN_PARTNER},
  ];

}
