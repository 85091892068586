import {JwtPermissions} from './jwtPermissions.service';

export class ErrorManager {

  public static myError: string;

  static handleError(error: any): Promise<any> {
    console.log(error);
    switch (error.status) {
      case 401: {
        ErrorManager.myError = 'User not authorized';
        break;
      }
      case 404: {
        ErrorManager.myError = 'Server unreachable';
        break;
      }
      case 500: {
        const myErr = (error && error.error) ? error.error : undefined;
        if (myErr) {
          if (myErr.id === 1) { // name already inserted
            ErrorManager.myError = 'This name is already present in the list';
          } else if (myErr.id === 3) { // geneic element not present
            ErrorManager.myError = myErr.error + ' not present';
          }
        } else {
          // Default unknown error
          const label = 'An unexpected error has occurred. Please try again later or report us the problem.';
          ErrorManager.myError = (error && error.error && JwtPermissions.admin) ? (label + ': (' + error.error + ')') : (label);
        }
        break;
      }
    }
    return Promise.reject(error.error || error.message || error);
  }
}
