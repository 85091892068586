import {SelectItem} from 'primeng/api';

export class DestinationDetails {
  public static readonly billingDestinationOTLTD = 1;
  public static readonly billingDestinationOTItaly = 2;
  public static readonly billingDestinationChoice: SelectItem[] = [
    {value: DestinationDetails.billingDestinationOTLTD, label: 'Onetag LTD'},
    {value: DestinationDetails.billingDestinationOTItaly, label: 'Onetag Italy'}];

  public static readonly billingStatsOTLTD = 1;
  public static readonly billingStatsAdServer = 0;
  public static readonly billingStats: SelectItem[] = [
    {value: DestinationDetails.billingStatsOTLTD, label: 'Onetag LTD'},
    {value: DestinationDetails.billingStatsAdServer, label: 'Publisher AdServer'}];

  public static readonly billingBankWISE = "WISE";
  public static readonly billingBankBARCLAYS = "BARCLAYS";
  public static readonly billingBanks: SelectItem[] = [
    {value: DestinationDetails.billingBankWISE, label: 'Wise'},
    {value: DestinationDetails.billingBankBARCLAYS, label: 'Barclays'}];


  public static readonly autoInvoice: SelectItem[] = [
    {value: false, label: 'No'},
    {value: true, label: 'Yes'}];
}
