import {SelectItem} from 'primeng/api';
import {Constants} from '../../__helpers/entities/Constants';
import {Column} from '../../__helpers/entities/Column';
import {EventsIds} from '../../__helpers/entities/EventsIds';
import {MetricsIds} from '../../__helpers/entities/MetricsIds';

export class CerConstants {

  // Market
  static readonly MARKET_NA_AND_LATAM = 0;
  static readonly MARKET_EMEA = 1;

  // Campaign Goal
  static readonly GOAL_BUTTON_DRIVE_USERS = 1;
  static readonly GOAL_BUTTON_BRAND_AWARENESS = 2;
  static readonly GOAL_BUTTON_CONVERSION = 4;
  static readonly CAMPAIGN_GOAL_CHOICE = [{value: CerConstants.GOAL_BUTTON_DRIVE_USERS, label: 'Consideration'},
    {value: CerConstants.GOAL_BUTTON_BRAND_AWARENESS, label: 'Branding'},
    {value: CerConstants.GOAL_BUTTON_CONVERSION, label: 'Performance'}];

  static readonly CHANNEL_WEB = 1;
  static readonly CHANNEL_FACEBOOK = 2;

  // ADGROUP SUBTYPE
  static readonly ADGROUP_WEB = 'web';
  static readonly ADGROUP_SOCIAL = 'facebook';

  // CREATIVE_GROUP SUBTYPE
  static readonly CREATIVEGROUP_WEB = 'web';
  static readonly CREATIVEGROUP_SOCIAL = 'facebook';
  static readonly CREATIVES_MAP = new Map([[CerConstants.CREATIVEGROUP_WEB, 'Web'],
    [CerConstants.CREATIVEGROUP_SOCIAL, 'Facebook']]);

  // Native creative on banner adgroup
  static readonly ALL_SIZES = 0;
  static readonly RECOMMENDED_SIZES = 1;
  static readonly BANNER_NATIVE_CHOICES: SelectItem[] = [{
    value: CerConstants.ALL_SIZES,
    label: 'All sizes'
  }, {value: CerConstants.RECOMMENDED_SIZES, label: 'Recommended sizes'}];

  // ID del tipo campagna
  static readonly RETARGETING_AI_2 = 4;
  static readonly INTENT_CUSTOM = 3;
  static readonly AUTO_PROSP_RET = 2;
  static readonly RETARGETING = 1;
  static readonly PROSPECTING = 0;
  static readonly INTENTS_ARRAY = [{label: 'Prospecting', value: CerConstants.PROSPECTING}, {label: 'Retargeting', value: CerConstants.RETARGETING}, {label: 'Ret. AI 2.0', value: CerConstants.RETARGETING_AI_2}];
  static readonly INTENTS_EXTENDED_ARRAY = [{label: 'Prospecting', value: CerConstants.PROSPECTING},
    {label: 'Retargeting', value: CerConstants.RETARGETING}, {label: 'Custom', value: CerConstants.INTENT_CUSTOM}];
  static readonly INTENTS_PROSP_ARRAY = [{label: 'Prospecting', value: CerConstants.PROSPECTING}, {label: 'Custom', value: CerConstants.INTENT_CUSTOM}];
  static readonly INTENTS_RET_ARRAY = [{label: 'Retargeting', value: CerConstants.RETARGETING}, {label: 'Custom', value: CerConstants.INTENT_CUSTOM}];
  static readonly INTENTS_MAP = new Map([[CerConstants.AUTO_PROSP_RET, 'Auto'], [CerConstants.PROSPECTING, 'Prospecting'],
    [CerConstants.RETARGETING, 'Retargeting'], [CerConstants.INTENT_CUSTOM, 'Custom']]);

  static readonly CURRENCY_ARRAY_SHORT: SelectItem[] = [
    {value: -MetricsIds.CPM, label: 'MAX CPM'}
  ];

  static readonly METRICS_MAP = new Map([[0, 'Dynamic CPM'], [MetricsIds.CPM, 'CPM'], [MetricsIds.CTR, 'CTR'],
    [MetricsIds.CPC, 'CPC'], [MetricsIds.VTR, 'VTR'], [MetricsIds.CPS, 'CPS'], [MetricsIds.CPA, 'CPA'], [MetricsIds.NEW_CPA, 'New CPA'], [MetricsIds.COST_PER_LANDING, 'COST PER LANDING'],
    [MetricsIds.CPV, 'CPV'], [MetricsIds.CPCV, 'CPCV'], [MetricsIds.VIEWABILITY, 'Viewability']]);
  static readonly EVENTS_MAP = new Map([[MetricsIds.CPM, 'Impressions'], [MetricsIds.CPC, 'Clicks'], [MetricsIds.CPCV, 'Completed Views'],
    [MetricsIds.CPA, 'Conversions'], [MetricsIds.NEW_CPA, 'Conversions'], [MetricsIds.CPS, 'of total sales'], [MetricsIds.COST_PER_LANDING, 'Landings']]);
  static readonly CURRENCY_METRIC_MAP = new Map([[MetricsIds.CPM, '€'], [MetricsIds.CTR, '%'], [MetricsIds.CPC, '€'], [MetricsIds.CPCV, '€'],
    [MetricsIds.CPA, '€'], [MetricsIds.NEW_CPA, '€'], [MetricsIds.CPS, '%'], [MetricsIds.VTR, '%'], [MetricsIds.VIEWABILITY, '%'],
    [MetricsIds.COST_PER_LANDING, '€'], [MetricsIds.VIEWABILITY, '%']]);

  /*************************** ADGROUP AUCTION TYPE *******************************/
  static readonly AUCTION_TYPE_OPEN = 0;
  static readonly AUCTION_TYPE_FIXED_PRICE = 1;
  static readonly AUCTION_TYPE_AUCTION_PACKAGE = 2;
  static readonly AUCTION_TYPE_CHOICE = [
    {value: CerConstants.AUCTION_TYPE_OPEN, label: 'Public Inventory - Open'},
    {value: CerConstants.AUCTION_TYPE_FIXED_PRICE, label: 'Fixed Price Deal'},
    {value: CerConstants.AUCTION_TYPE_AUCTION_PACKAGE, label: 'Inventory Package'},
  ];

  /*************************** FORMAT TYPE IDS *******************************/
  static readonly FORMAT_BANNER = 2;
  static readonly FORMAT_VIDEO = 3;
  static readonly FORMAT_SKIN = 4;
  static readonly FORMAT_SITEUNDER = 5;
  static readonly FORMAT_NATIVE = 6;

  static readonly FORMAT_PARALLAX = 7;
  static readonly FORMAT_DRIVETOSTORE = 8;
  static readonly FORMAT_CAROUSELTOSTORE = 9;
  static readonly FORMAT_VIDEOFEED = 10;
  static readonly VIDEOSKIN_ID = 11;
  static readonly FORMAT_CAROUSEL = 12;
  static readonly FORMAT_SOCIAL = 13;
  static readonly FORMAT_SEARCH = 14;

  static readonly DISPLAY_FORMAT: SelectItem = {value: CerConstants.FORMAT_BANNER, label: 'Display'};
  static readonly VIDEO_FORMAT: SelectItem = {value: CerConstants.FORMAT_VIDEO, label: 'Video'};
  static readonly VIDEO_NATIVE: SelectItem = {value: CerConstants.FORMAT_NATIVE, label: 'Native'};
  static readonly WEB_STANDARD_FORMATS_CHOICE: SelectItem[] = [CerConstants.DISPLAY_FORMAT, CerConstants.VIDEO_FORMAT, CerConstants.VIDEO_NATIVE];

  // static readonly FORMATS_ARRAY = [];
  static readonly FORMATS_MAP = new Map([[CerConstants.FORMAT_BANNER, 'Display'],
    [CerConstants.FORMAT_VIDEO, 'Video'], [CerConstants.FORMAT_SKIN, 'Skin'], [CerConstants.FORMAT_NATIVE, 'Native'],
    [CerConstants.FORMAT_PARALLAX, 'Parallax'], [CerConstants.FORMAT_DRIVETOSTORE, 'Drive to store'],
    [CerConstants.FORMAT_CAROUSELTOSTORE, 'Carousel to Store'], [CerConstants.FORMAT_VIDEOFEED, 'Video feed'],
    [CerConstants.FORMAT_CAROUSEL, 'Carousel'],
    [CerConstants.FORMAT_SOCIAL, 'Facebook Post'], [CerConstants.FORMAT_SEARCH, 'Google Search']]);


  /*************************************************** CREATIVES TYPES ************************************************************/
  static readonly HTML_CREATIVE_ID = 1;
  static readonly VAST_CREATIVE_ID = 2;
  static readonly UPLOAD_CREATIVE_ID = 3;
  static readonly TEXTLINK_CREATIVE_ID = 4;
  static readonly RICHMEDIA_CREATIVE_ID = 5;

  static readonly CREATIVES_TYPE_MAP = new Map([[CerConstants.HTML_CREATIVE_ID, 'HTML'],
    [CerConstants.VAST_CREATIVE_ID, 'Vast'], [CerConstants.UPLOAD_CREATIVE_ID, 'Upload'],
    [CerConstants.TEXTLINK_CREATIVE_ID, 'Native'], [CerConstants.RICHMEDIA_CREATIVE_ID, 'Rich Media']]);

  // STATUS
  static readonly DELETED = -1;
  static readonly PAUSE = 0;
  static readonly ACTIVE = 1;
  static readonly DRAFT = 5;
  static readonly ARCHIVED = 8;
  static readonly UNKNOWN = 10;
  static readonly COMPLETED = 11;
  static readonly STATUS_EXTENDED_ARRAY = [{label: 'Pause', value: CerConstants.PAUSE, color: 'yellowColor'},
    {label: 'Active', value: CerConstants.ACTIVE, color: 'greenColor'}, {label: 'Draft', value: CerConstants.DRAFT, color: 'blueColor'},
    {label: 'Deleted', value: CerConstants.DELETED, color: 'greyColor'}];
  static readonly STATUS_ARRAY = [{label: 'Pause', value: CerConstants.PAUSE, color: 'yellowColor'},
    {label: 'Active', value: CerConstants.ACTIVE, color: 'greenColor'}];

  static readonly ALL_STATUS_ARRAY = [{label: 'Pause', value: CerConstants.PAUSE, color: 'yellowColor'},
    {label: 'Active', value: CerConstants.ACTIVE, color: 'greenColor'}, {label: 'Draft', value: CerConstants.DRAFT, color: 'blueColor'},
    {label: 'Deleted', value: CerConstants.DELETED, color: 'greyColor'}, {label: 'Archived', value: CerConstants.ARCHIVED, color: 'blueColor'},
    {label: 'Completed', value: CerConstants.COMPLETED, color: 'blueColor'}, {label: 'Unknown', value: CerConstants.UNKNOWN, color: 'greyColor'}];

  // BIDDING STRATEGY
  static readonly AUTOMATIC = 0;
  static readonly MANUAL = 1;
  static readonly BIDDING_STRATEGY_ARRAY = [{label: 'Auto', value: CerConstants.AUTOMATIC},
    {label: 'Manual', value: CerConstants.MANUAL}];

  // Delivery Mode
  static readonly DELIVERY_MODE_ARRAY: SelectItem[] = [{label: 'Automatic', value: CerConstants.AUTOMATIC},
    {label: 'Manual', value: CerConstants.MANUAL}];

  // CAPPING VALUES
  static cappingChoice: SelectItem[] = [{label: 'Impression', value: 1}, {label: 'Click', value: 2}];
  static eachChoice: SelectItem[] = [];
  static everyChoice: SelectItem[] = [];

  static cappingChoiceShort: SelectItem[] = [{label: 'Imps', value: 1}, {label: 'Click', value: 2}];
  static timeChoiceShort: SelectItem[] = [{label: 'm', value: Constants.MINUTE_CAPPING}, {label: 'h', value: Constants.HOUR_CAPPING}, {label: 'd', value: Constants.DAY_CAPPPING}];

  static readonly DEFAULT_REPORT_COLS: Column[] = [
    {id: EventsIds.TOTAL_COST, type: 'EVENT'},
    {id: EventsIds.IMPRESSIONS, type: 'EVENT'},
    {id: EventsIds.CLICK, type: 'EVENT'},
    {id: MetricsIds.CTR, type: 'METRIC'},
    {id: MetricsIds.VIEWABILITY, type: 'METRIC'},
    {id: MetricsIds.WINRATE, type: 'METRIC'}
  ];
  // static readonly DEFAULT_REPORT_COLS_ADMIN: Column[] = [...CerConstants.DEFAULT_REPORT_COLS, ...[{id: MetricsIds.INTERNAL_SPENT, type: 'METRIC'}]];
  static readonly DEFAULT_REPORT_COLS_ADMIN: Column[] = [...CerConstants.DEFAULT_REPORT_COLS];

  // REPORT TYPE
  static readonly LIST_TYPE_ADVERTISER = -1;
  static readonly LIST_TYPE_CAMPAIGN = 0;
  static readonly LIST_TYPE_ADGROUP = 1;
  static readonly LIST_TYPE_SUBSTRATEGY = 2;
  static readonly LIST_TYPE_CREATIVE = 3;

  // FEE REF. TYPE
  static readonly FEE_ON_MEDIA_COST = 0;
  static readonly FEE_ON_TOTAL_COST = 1;
  static readonly FEE_ONETAG_TECH = 2;
  static readonly FEE_ON_TOTAL_MARGIN = 3;

  // SITES APPROVATION
  static readonly STATUS_WAITING = 0;
  static readonly STATUS_APPROVED = 1;
  static readonly STATUS_DISCARDED = 2;

  static readonly TAB_ADGROUP_INVENTORY_BIDDING = 0;
  static readonly TAB_ADGROUP_TARGETING = 1;
  static readonly TAB_ADGROUP_KPI = 2;
  static readonly TAB_ADGROUP_CREATIVE_GROUP = 3;
}
