import {Column} from './Column';
import {Filter} from './Filter';

export class DataGenerateTable {
  cols: Column[] = [];
  groupBy: number[] = [];
  timezone: string;
  filters: Filter;
  new_report?: number;
  able_child_domain?: number;
  uid?: number; // id per la callback
}
