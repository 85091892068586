import {Component, EventEmitter, Input, Output, TemplateRef, ViewChild} from '@angular/core';

@Component({
  selector: 'app-sidepanel',
  templateUrl: './sidePanel.component.html'
})
export class SidePanelComponent {

  @ViewChild('content', { static: true }) public content: TemplateRef<any>;

  @Output() public open = new EventEmitter<void>();
  @Output() public openDone = new EventEmitter<void>();
  @Output() public close = new EventEmitter<void>();
  @Output() public closeDone = new EventEmitter<void>();

  @Input() isVisible: boolean;
  @Input() closesOnEscape = false;
  @Input() closesOnClickOut = false;

  constructor() {}

}
