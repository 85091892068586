<ng-container *ngIf="!isReport">
  <div class="loadingContainer" [class.full-white]="isHeavy">
    <div class="loader">
      <img src="./assets/imgs/onetag_logo_arrow_blue.png" class="loading-arrow"/>
      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
  </div>
</ng-container>

<div *ngIf="isReport" class="reportContainer">
  <div>
    <div class="flashit">
      <p><i class="w3-spin fal fa-cog icon-load"></i></p>
    </div>
  </div>
</div>


