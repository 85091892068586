import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityAccount } from 'app/__helpers/entities/EntityAccount';
import { GlobalSettings } from 'GlobalSettings';
import { Observable } from 'rxjs';
import {SelectItem} from 'primeng/api';
import {Referrer} from '../__helpers/entities/Referrer';
import {Feature} from '../_cerberusMVC/cerberusPublisher/entity/Feature';
import {RevenueShareThresholds} from '../_dealsMVC/entity/RevenueShareThresholds';
import {ReferrerLog} from '../_privateMVC/_helpers/ReferrerLog';

@Injectable()
export class EntityService {

  private readonly entityPath = GlobalSettings.APIurl + '/api/v1/user/entity';
  private readonly entityNonApiPath = GlobalSettings.APIurl + '/v1/user/entity';
  private readonly entityLoggedApiPath = GlobalSettings.APIurl + '/logged/v1/user/entity';
  private readonly invitePath = GlobalSettings.APIurl + '/api/v1/user/invite';
  private readonly inviteObPath = GlobalSettings.APIurl + '/api/v1/user/inviteOb';
  private readonly referrerURL = GlobalSettings.APIurl + '/api/v1/user/referrer';
  private readonly associableURL = GlobalSettings.APIurl + '/api/v1/user/associable';
  private readonly associableObURL = GlobalSettings.APIurl + '/api/v1/user/associableOb';
  private readonly associatePublisherURL = GlobalSettings.APIurl + '/api/v1/user/associate';
  private readonly bankDetailURL = GlobalSettings.APIurl + '/api/v1/user/bankDetails';
  private readonly setOpenBiddingGAMIDURL = GlobalSettings.APIurl + '/api/v1/user/entity/googlePubId';
  private readonly generateLinkPath = this.invitePath + '/link';
  private readonly inviteSyncPath = this.invitePath + '/sync';
  private readonly shareThresholdURL = GlobalSettings.APIurl + '/api/v1/publisher/{:idEntity}/rev-share-threshold';
  private readonly referrerLogURL = GlobalSettings.APIurl + '/api/v1/publisher/referrer/log/';
  private readonly salesOwnerURL = GlobalSettings.APIurl + '/api/v1/user/sales-owner';
  private readonly dealExistenceURL = GlobalSettings.APIurl + '/api/v1/deal/existence/';

  constructor(private http: HttpClient) {}

  getEntityDetail(idEntity?: number, key?: string): Observable<EntityAccount> {
    let params: any;
    let path: string;
    if (key) {
      path = this.entityNonApiPath;
      params = {
        key: key
      };
    } else if (idEntity) {
      path = this.entityPath;
      params = {
        idEntity: idEntity.toString()
      };
    } else {
      return null;
    }
    return this.http.get<EntityAccount>(path, {params: params});
  }

  getBankDetail(idEntity?: number): Observable<EntityAccount> {
    const params: any = {};
    if (idEntity !== undefined) { params['idEntity'] = idEntity; }
    return this.http.get<EntityAccount>(this.bankDetailURL, {params: params});
  }

  getEntityDetailNonApiPathFromUserId(idUser: any): Observable<EntityAccount> {

    const params = {
      idUser: idUser
    }
    return this.http.get<EntityAccount>(this.entityNonApiPath, {params: params});
  }

  updateEntityDetail(entityAccount: EntityAccount): Observable<EntityAccount> {
    return this.http.put<EntityAccount>(this.entityPath, entityAccount);
  }

  deleteEntity(idEntity: string) {
    const params = {
      idEntity: idEntity
    }
    return this.http.delete(this.entityPath, {params: params});
  }

  getContractFile(href: string) {
    return this.http.get(href, {
      responseType: 'blob'
    });
  }

  getInviteList(): Observable<EntityAccount[]> {
    return this.http.get<EntityAccount[]>(this.invitePath);
  }

  saveInvite(entity: EntityAccount, isOb: boolean) {
    if (isOb) {
      return this.postInviteOb(entity);
    }
    return this.postInvite(entity);
  }

  postInvite(entity: EntityAccount): Observable<number> {
    return this.http.post<number>(this.invitePath, entity);
  }

  postInviteOb(entity: EntityAccount): Observable<number> {
    return this.http.post<number>(this.inviteObPath, entity);
  }

  getAssociable(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(this.associableURL);
  }

  getAssociableOb(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(this.associableObURL);
  }

  generateLink(entityId: string): Observable<EntityAccount> {
    return this.http.post<EntityAccount>(this.generateLinkPath, entityId);
  }

  associatePublisher(entityId: number, associateEntityId: number): Observable<EntityAccount> {
    const myObj = {
      entityId: entityId,
      associateEntityId: associateEntityId
    }
    return this.http.post<EntityAccount>(this.associatePublisherURL, myObj);
  }

  eversignSync(): Observable<any> {
    return this.http.post<any>(this.inviteSyncPath, null);
  }

  postEntityLogged(entity: EntityAccount): Observable<any> {
    return this.http.post<any>(this.entityLoggedApiPath, entity);
  }

  getReferrers(): Observable<Referrer[]> {
    return this.http.get<Referrer[]>(this.referrerURL);
  }

  setOpenBiddingGAMID(myGamId: string[], entityId: number) {
    const params: any = {};
    if (entityId !== undefined) { params['entity'] = entityId; }
    return this.http.put<Feature>(this.setOpenBiddingGAMIDURL, myGamId, {params: params});
  }

  shareThreshold(entityId: number): Observable<RevenueShareThresholds> {
    return this.http.get<RevenueShareThresholds>(this.shareThresholdURL.replace('{:idEntity}', entityId?.toString()));
  }

  getReferrerLogs(entityId: number): Observable<ReferrerLog[]> {
    return this.http.get<ReferrerLog[]>(this.referrerLogURL + entityId);
  }

  postReferrerLogs(entity: ReferrerLog): Observable<ReferrerLog> {
    return this.http.post<ReferrerLog>(this.referrerLogURL, entity);
  }

  removeReferrerLog(idEntityReferrerLog: number): Observable<ReferrerLog> {
    return this.http.delete<ReferrerLog>(this.referrerLogURL + idEntityReferrerLog);
  }

  getSalesOwner(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(this.salesOwnerURL);
  }

  getDealExistence(idEntity: number): Observable<SelectItem[]> {
    const params: any = {};
    params['type'] = 2;
    return this.http.get<SelectItem[]>(this.dealExistenceURL + idEntity, {params: params});
  }
}
