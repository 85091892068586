import {Injectable} from '@angular/core';
import 'rxjs/add/operator/toPromise';
import {UserData} from '../__helpers/entities/UserData';
import {GlobalSettings} from '../../GlobalSettings';
import {ResponseInfo} from '../__helpers/entities/ResponseInfo';
import {Router} from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Site} from '../__helpers/entities/Domains';
import {SelectItem} from 'primeng/api';
import {UserDetail} from '../__helpers/entities/UserDetail';
import {SetupReg} from '../__helpers/entities/SetupReg';
import {Constants} from '../__helpers/entities/Constants';
import {Observable} from 'rxjs';
import {Traffic} from '../__helpers/entities/Traffic';
import {JwtPermissions} from '../__helpers/jwtPermissions.service';
import { SiteInsertionResponse } from 'app/_publisherMVC/entity/SiteInsertionResponse';
import {ProcessDataPoint} from '../_publicMVC/openBiddingSignup/_helpers/ProcessDataPoint';
import {UserAs} from '../__helpers/UserAs';

@Injectable({providedIn: 'root'})
export class LoginService {

  static language = 'en';

  loadingButton: boolean;

  error = '';

  loggedPrivate = false; // allow private browser to logged in

  readonly AlreadySendEmailForResetMessageId = 1000;
  readonly AlreadySendEmailForResetMessage = 'An email has been sent to you. Check your email or try again later.';
  readonly NeedsResetPasswordMessageId = 2000;
  readonly NeedsResetPasswordMessage = this.AlreadySendEmailForResetMessage;

  // REST calls' URLs
  private readonly doLoginURL = GlobalSettings.APIurl + '/login';
  private readonly doLoginSsoURL = GlobalSettings.APIurl + '/loginSSO';
  private readonly refreshTokenURL = GlobalSettings.APIurl + '/refresh';
  private readonly refreshSSOTokenURL = GlobalSettings.APIurl + '/refreshSSO';
  private readonly doLoggedLoginURL = GlobalSettings.APIurl + '/logged/login';
  private readonly doRegisterURL = GlobalSettings.APIurl + '/register';
  private readonly doAdminRegisterURL = GlobalSettings.APIurl + '/api/manager/register';
  private readonly recoverPasswordURL = GlobalSettings.APIurl + '/v1/recoverPassword';
  private readonly changePasswordOldURL = GlobalSettings.APIurl + '/api/changePassword';
  private readonly changePasswordURL = GlobalSettings.APIurl + '/api/v1/user/changePassword';
  private readonly getUserLoggedURL = GlobalSettings.APIurl + '/logged/getUser';
  private readonly getTermsURL = GlobalSettings.APIurl + '/logged/getTerms';
  private readonly getSitesURL = GlobalSettings.APIurl + '/logged/getSitesList';
  private readonly getContractURL = GlobalSettings.APIurl + '/logged/getContract';
  private readonly saveContractURL = GlobalSettings.APIurl + '/logged/saveContract';
  private readonly acceptTermsURL = GlobalSettings.APIurl + '/logged/acceptTerms';
  private readonly getSetupBuyerURL = GlobalSettings.APIurl + '/logged/getSetupBuyer';
  private readonly getPaymentDetailsURL = GlobalSettings.APIurl + '/logged/getPaymentDetails';
  private readonly modifyPaymentDetailsURL = GlobalSettings.APIurl + '/logged/modifyPaymentDetails';
  private readonly updateSetupBuyerURL = GlobalSettings.APIurl + '/logged/updateSetupBuyer';
  private readonly newPasswordURL = GlobalSettings.APIurl + '/newPassword';
  private readonly sendCodeURL = GlobalSettings.APIurl + '/sendCode';
  private readonly getChannelURL = GlobalSettings.APIurl + '/getChannel';
  private readonly getCountriesURL = GlobalSettings.APIurl + '/getCountries';
  private readonly getAdditionalTxtUnloggedURL = GlobalSettings.APIurl + '/logged/getAdditionalTxt';
  private readonly getOnetagEntitiesURL = GlobalSettings.APIurl + '/api/getOnetagEntities';
  private readonly getTrafficURL = GlobalSettings.APIurl + '/logged/getTraffic';
  private readonly sendTrafficURL = GlobalSettings.APIurl + '/logged/sendTraffic';

  // Logged call
  private readonly loggedSitesURL = GlobalSettings.APIurl + '/logged/v1/publisher/site';

  constructor(private http: HttpClient, private router: Router) {}

  /**
   * Esegue il logout dell'utente
   */
  logout() {
    JwtPermissions.removeToken();
    UserAs.emptyFields();
    this.router.navigate(['/' + Constants.LINK_LOGIN]);
    location.reload();
  }


  /****************************************************************** API ****************************************************************/

  doLogin(user: UserData): Observable<ResponseInfo> {
    return this.http.post<ResponseInfo>(this.doLoginURL, user);
  }

  doLoginSso(code: String): Observable<ResponseInfo> {
    return this.http.get<ResponseInfo>(this.doLoginSsoURL + "?code=" + code);
  }

  refreshToken(sso: boolean): Observable<ResponseInfo> {
    if (JwtPermissions.getCurrentRefreshToken()) {
      if (sso) {
        return this.http.post<ResponseInfo>(this.refreshSSOTokenURL, JwtPermissions.getCurrentRefreshToken());
      } else {
        return this.http.post<ResponseInfo>(this.refreshTokenURL, JwtPermissions.getCurrentRefreshToken());
      }
    } else {
      this.logout();
    }
  }

  doLoggedLogin(): Observable<ResponseInfo> {
    return this.http.post<ResponseInfo>(this.doLoggedLoginURL, null);
  }

  recoverPassword(user: UserData): Observable<any> {
    return this.http.post(this.recoverPasswordURL, user);
  }

  // deprecated
  changePasswordAlias(passNew: string, passOld?: string, idAlias?: number, idUser?: number): Observable<string> {
    const params: any = {};
    if (idAlias) { params['idAlias'] = idAlias.toString(); }
    if (idUser) { params['idUser'] = idUser.toString(); }
    let passwords;
    if (!passOld) {
      passwords = {newPassword: passNew}
    } else {
      passwords = {oldPassword: passOld, newPassword: passNew};
    }
    return this.http.post<string>(this.changePasswordOldURL, passwords, {params: params});
  }


  changePassword(passNew: string, passOld: string, myUsername: string, isUserProfile?: boolean): Observable<string> {
    const params: any = {};
    const passwords: any = {};
    if (myUsername) {params['username'] = myUsername.toString(); }
    if (isUserProfile) {passwords.accessToken = JwtPermissions.getCurrentAccessToken(); }
    if (!passOld) {
      passwords.newPassword = passNew;
    } else {
      passwords.oldPassword = passOld;
      passwords.newPassword = passNew;
    }
    return this.http.post<string>(this.changePasswordURL, passwords, {params: params});
  }

  newPassword(newPass: {newPassword?: string}, id?: string, code?: string): Observable<string> {
    const params: any = {};
    if (id !== undefined) {params['id'] = id.toString(); }
    if (code !== undefined) {params['code'] = code.toString(); }
    return this.http.post<string>(this.newPasswordURL, newPass, {params: params});
  }

  getUser(): Observable<UserData> {
    return this.http.get<UserData>(this.getUserLoggedURL);
  }

  doRegister(user: UserData, recaptchaToken?: any): Observable<ResponseInfo> {
    let str = this.doRegisterURL;
    if (recaptchaToken) { str = this.doRegisterURL + '?' + 'g-recaptcha-response=' + recaptchaToken; }
    return this.http.post<ResponseInfo>(str, user);
  }

  sendCode(mail: string, name: string, lang: number, additionalUsers?: string[], myDataPoints?: ProcessDataPoint, exist?: boolean, entityId?: number): Observable<any> {
    const params: any = {};
    if (mail) {params['email'] = mail; }
    if (name) {params['name'] = name; }
    if (lang) {params['lang'] = lang.toString(); }
    if (exist) {params['exist'] = 1; }
    if (entityId) {params['entityId'] = entityId; }
    if (myDataPoints && myDataPoints['publisher-id']) {params['googlePubId'] = myDataPoints['publisher-id']; }
    if (additionalUsers && additionalUsers.length > 0) {
      return this.http.post(this.sendCodeURL, additionalUsers, {params: params});
    } else {
      return this.http.post(this.sendCodeURL, null, {params: params});
    }
  }

  updateSetupBuyer(setup: SetupReg): Observable<any> {
    return this.http.post<string>(this.updateSetupBuyerURL, setup);
  }

  getChannel(): Observable <SelectItem[]> {
    return this.http.get<SelectItem[]>(this.getChannelURL);
  }

  getCountries(): Observable <SelectItem[]> {
    return this.http.get<SelectItem[]>(this.getCountriesURL);
  }

  getTraffic(): Observable<Traffic> {
    return this.http.get<Traffic>(this.getTrafficURL);
  }

  sendTraffic(traffic: Traffic): Observable<Traffic> {
    return this.http.post<Traffic>(this.sendTrafficURL, traffic);
  }

  sendDomains(insertionSiteAppList: any): Observable<SiteInsertionResponse>  {
    return this.http.post<SiteInsertionResponse>(this.loggedSitesURL, insertionSiteAppList);
  }

  getTerms(): Observable<SelectItem> {
    return this.http.get<SelectItem>(this.getTermsURL);
  }

  getSites(): Observable<Site[]> {
    return this.http.get<Site[]>(this.getSitesURL);
  }

  getContract(): Observable<{map: {contract: string}}> {
    return this.http.get<{map: {contract: string}}>(this.getContractURL);
  }

  saveContract(contract: {sign?: string, contract_file?: string}): Observable<any> {
    return this.http.post<{sign?: string, contract_file?: string}>(this.saveContractURL, contract);
  }

  getSetupBuyer(id: number): Observable<SetupReg> {
    const params: any = {};
    if (id) {params['id'] = id.toString(); }
    return this.http.get<SetupReg>(this.getSetupBuyerURL, {params: params});
  }

  acceptTerms(id: number, contract?: {sign?: string, contract_file?: string}): Observable<any> {
    const params: any = {};
    if (id !== undefined) {params['id'] = id.toString(); }
    return this.http.post(this.acceptTermsURL, contract, {params: params});
  }

  // restituisce lista di tutti i gruppi se sei admin
  getPaymentDetails(): Observable<UserDetail> {
    return this.http.get<UserDetail>(this.getPaymentDetailsURL);
  }

  modifyPaymentDetails(param: UserDetail): Observable<any> {
    return this.http.post(this.modifyPaymentDetailsURL, param);
  }

  doAdminRegister(user: UserData): Observable<ResponseInfo> {
    return this.http.post<ResponseInfo>(this.doAdminRegisterURL, user);
  }

  getOnetagEntities(): Observable<{ value: number, label: string, label1?: any }[]> {
    return this.http.get<{ value: number, label: string, label1?: any }[]>(this.getOnetagEntitiesURL).map( res =>
      (res && res.length > 0) ? res.filter(elem => elem.label) : []);
  }
}
