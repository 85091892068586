import {UserFilter} from './filters/UserFilter';
import {MediaFilter} from './filters/MediaFilter';
import {InventoryFilter} from './filters/InventoryFilter';
import {Period} from './filters/Period';

export class Filter {
  period: Period;
  inventoryFilter?: InventoryFilter;
  mediaFilter?: MediaFilter;
  userFilter?: UserFilter;
}
