import {Component, OnDestroy, ViewChild} from '@angular/core';
import {SidePanelComponent} from '../sidePanel.component';
import {animate, animateChild, query, state, style, transition, trigger, AnimationEvent, group} from '@angular/animations';

@Component({
  selector: 'app-sidepanelstack',
  templateUrl: './sidePanelStack.component.html',
  styleUrls: ['./sidePanelStack.component.css'],
  animations: [
      trigger('container', [
          transition('void <=> *', [
              query('@*', animateChild())
          ])
      ]),
      trigger('overlay', [
          transition(':enter', [
              style({ opacity: 0 }),
              animate('.3s linear')
          ]),
          transition(':leave', [
            animate('.3s linear'),
            style({ opacity: 0 })
          ])
      ]),
      trigger('panel', [
          transition(':enter', [
              style({ opacity: 0, transform: 'translateX(1em)' }),
              animate('.5s ease-out'),
          ]),
          transition(':leave', [
              animate('.5s ease-out'),
              style({ opacity: 0, transform: 'translateX(1em)' })
          ]),
      ]),
      trigger('stacker', [
         // Queste 3 righe impediscono il funzionamento del componente dopo la build del progetto. Per adesso commento.
         // ...[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(
         //    index => state(index.toString(), style({ opacity: 1, transform: `translateX(${-index * 50}px)` }))
         // ),
          transition('* => *', [
              group([
                  query('@*', animateChild(), { optional: true }),
                  animate('.5s ease-out')
              ])
          ])
      ])
  ]
})

export class SidePanelStackComponent implements OnDestroy {

  stack: SidePanelComponent[] = [];

  private offset: number;

  constructor() {
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  push(panel: SidePanelComponent) {
    if (this.stack.length === 10) {
      throw new Error('Panel stack\'s maximum size of 10 reached');
    }
    this.stack.push(panel);
    panel.isVisible = true;
    if (this.stack.length === 1) {
      this.hideScrollBar();
      document.body.addEventListener('keydown', this.onKeyDown);
    }
  }

  pop() {
    if (!this.stack.pop()) {
      return;
    }
    if (this.stack.length === 0) {
      this.showScrollBar();
      document.body.removeEventListener('keydown', this.onKeyDown);
    }
  }

  isEmpty(): boolean {
    return (this.stack.length === 0);
  }

  showScrollBar() {
    const scrollY = this.offset + 'px';
    document.body.style.position = '';
    document.body.style.width = '';

    // This soluction sminchia le posizioni di tooltip e suggerimenti
    // document.body.style.top = '';

    window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
  }

  hideScrollBar() {
    this.offset = -window.scrollY;
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';

    // This soluction sminchia le posizioni di tooltip e suggerimenti
    // document.body.style.top = -window.scrollY + 'px';
  }

  replace(panel: SidePanelComponent) {
    this.pop();
    this.push(panel);
  }

  public onClick(event: MouseEvent) {
    if (event.eventPhase === Event.AT_TARGET && this.stack[this.stack.length - 1].closesOnClickOut) {
      this.pop();
    }
  }

  private onPanelAnimationStart(panel: SidePanelComponent, event: AnimationEvent) {
    if (event.fromState === 'void') {
      panel.open.emit();
    } else if (event.fromState === null) {
      panel.close.emit();
    }
  }

  onPanelAnimationEnd(panel: SidePanelComponent, event: AnimationEvent) {
    if (event.fromState === 'void') {
      panel.openDone.emit();
    } else if (event.fromState === null) {
      panel.closeDone.emit();
      panel.isVisible = false;
    }
  }

  ngOnDestroy(): void {
    if (this.stack.length !== 0) {
      this.showScrollBar();
    }
    document.body.removeEventListener('keydown', this.onKeyDown);
  }

  private onKeyDown(event: KeyboardEvent) {
    let isEscape: boolean;
    if (event.key != null) {
      isEscape = (event.key === 'Escape' || event.key === 'Esc');
    } else {
      isEscape = (event.keyCode === 27);
    }
    if (isEscape && this.stack[this.stack.length - 1].closesOnEscape) {
      this.pop();
    }
  }

}
