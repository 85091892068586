<div>
<!-------------------------------------------- Mobile ------------------------------------------>
<!--Mobile sidebar trigger-->
  <span id="toggle_sidebar" class="fal fa-bars w3-hide-large" [class.shortViewToggle]="shortView" (click)="openMobileNav()"></span>
<!--Mobile sidebar-->
  <div id="myMobileSidenav" class="sidenav">
    <button (click)="closeMobileNav()" class="otp-btn" id="close_mobile_sidenav_button"><i class="fal fa-chevron-left"></i></button>
    <br><br>
    <ng-container *ngIf="myElements && myElements.items?.length > 0">
      <div *ngFor="let item of myElements.items;">
        <label *ngIf="item.bold === 1" class="middleTitle">{{item.label}}</label>
        <div *ngIf="item.bold !== 1" class="sidebarTitle sidebarMenu" routerLink="./{{item.subItems[0]?.link}}" (click)="closeMobileNav()" style="cursor: pointer">
          <label class="iconsSideMobile">
            <i class="fal {{item.icon}} sidebarMenuIcon"></i>
          </label>
          <span>{{item.label}}</span>
        </div>
      </div>
    </ng-container>
  </div>

<!------------------------------------------- Desktop ------------------------------------------->
  <!--SideBar desktop-->
  <div *ngIf="!infoService.bodyExpanded" [class.shortView]="shortView" id="mySidenav" class="sidenav w3-hide-small" style="height: 100%">

    <a [routerLink]="'/' + Constants.LINK_HOME" [class.homeLink-shortView]="shortView">
      <div class="homeLink">
        <ng-container *ngIf="myUser">
          <img *ngIf="!isPublicis()" [class]="className" [src]="sideImg"/>
          <img *ngIf="isPublicis()" class="publicisLogo" [src]="'../../assets/imgs/logo_publicis_white.png'"/>
        </ng-container>
      </div>
    </a>

    <a *ngIf="!JwtPermissions.admin && (!myElements?.homePage || myElements?.homePage < 0)" [routerLink]="'/' + Constants.LINK_HOME" class="sidebarLink">
      <div class="sidebarTitle" [class.selectedMenu]="('/' + Constants.LINK_HOME) === url">
        <label>
          <i class="fal fa-chart-bar sidebarMenuIcon"></i>
        </label>
        <span class="sidebarElementLabel">Dashboard</span>
      </div>
    </a>

    <ng-container *ngIf="myElements && myElements.items && myElements.items.length > 0">
      <div *ngFor="let item of myElements.items;">
        <label *ngIf="!shortView && item.bold === 1" class="middleTitle">{{item.label}}</label>
        <a *ngIf="item.bold !== 1" class="sidebarLink" routerLink="./{{item.subItems[0]?.link}}">
          <div class="sidebarTitle" [class.selectedMenu]="(url | containsUrl: item :myElements)">
            <label>
              <!--<i *ngIf="item.bold === 1" class="far {{item.icon}} sidebarMenuIcon"></i>-->
              <i class="fal {{item.icon}} sidebarMenuIcon" [pTooltip]="item.label"></i>
            </label>
            <span class="sidebarElementLabel">{{item.label}}</span>
          </div>
        </a>
      </div>
      <div>
        <img *ngIf="UtilsFun.matchDestinationCarat()" src="../../../assets/imgs/poweredbyBlack.png" style="position: absolute; bottom: 10px; left: 10px; width: 50px;"/>
      </div>
    </ng-container>
  </div>
</div>
