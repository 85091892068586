/**
 * @Pipe
 * @description pipe to format numeric values to argentina readable currency values
 * @input number
 * @output formatted numeric value
 */

import { Pipe, PipeTransform } from '@angular/core';
import {SelectItem} from 'primeng/api';

@Pipe({
  name: 'removeAzure'
})
export class RemoveAzurePipe implements PipeTransform {
  transform(myUsername: string): string {
    return myUsername ? myUsername.replace('azure_', '') : '';
  }
}

