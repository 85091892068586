import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {InfoService} from '../../services/info.service';
import {NotificationData} from '../../__helpers/entities/NotificationData';
import {environment} from '../../../environments/environment';
import {DashboardService} from '../../services/dashboard.service';
import {MessageService} from 'primeng/api';
import {JwtPermissions} from '../../__helpers/jwtPermissions.service';

@Component({
  selector: 'app-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.css']
})

export class NotifyComponent implements OnInit {

  waitSound: boolean;


  @Output() notifications = new EventEmitter();

  constructor(private infoService: InfoService, private dashboardService: DashboardService, private messageService: MessageService) {}

  ngOnInit(): void {
    this.infoService.getUserGettedInObs().subscribe((cond2) => {
      if (cond2) {
        this.registerHandler();
      }
    });
  }

  registerHandler() {
    if (JwtPermissions.getCurrentTokenID()) {
      let numb: number;
      if (JwtPermissions.admin) {
        numb = 3;
      } else {
        numb = this.infoService.user.id;
      }
      this.dashboardService.eventBus.registerHandler('newNotification.' + numb, (error, message) => { // ID user o 3 se sono admin
        this.notifications.emit(message.body);
        if (JwtPermissions.admin) {
          const temp = JSON.parse(message.body) as NotificationData;
          this.messageService.add({severity: 'info', summary: temp.username, detail: temp.text});
          if (!this.waitSound && environment.production) {
            this.waitSound = true;
            setTimeout(() => this.waitSound = false, 1000);
          }
        }
      });
      this.dashboardService.eventBus.registerHandler('newNotificationGroup.' + this.infoService.user.configurationId, (error, message) => { // ID (Buyer, Publisher...)
        // this.counter = message.body;
      });
    }
  }
}
