import {GeoTag} from '../GeoTag';

export class UserFilter {
  device?: number[];
  source?: number[];
  country?: number[];
  region?: number[];
  browser?: number[];
  os?: number[];
  geo?: GeoTag;
  gender?: number[];
  range?: number[];
}
