/**
 * Constants used in this project
 */
import {Message, SelectItem} from 'primeng/api';
import {MetricsIds} from './MetricsIds';
import {DealTypeEnum} from "../../_dealsMVC/entity/dealTypeEnum";
import {PriceTypeEnum} from "../../_dealsMVC/entity/priceTypeEnum";
import {CurrencyHelper} from "./CurrencyHelper";

export class Constants {

  // Regex
  static readonly currencyRegex = new RegExp('[$€£]([0-9]+[\.]*[0-9]*)');
  static readonly currencyRegexSpace = new RegExp('[$€£] ([0-9]+[\.]*[0-9]*)');
  static readonly percentageRegex = new RegExp('([0-9]+[\.]*[0-9]*)%');
  static readonly numbRegex = new RegExp('^[-+]?([0-9]+[\.]*[0-9]*)$');
  static readonly numericRegex = new RegExp('^[-+]?[0-9]+$');
  static readonly letterRegex = new RegExp('^[a-zA-Z]+$');
  static readonly urlRegex = new RegExp('^(https?:\\/\\/)?(www\\d*\\.)?([^\\/\\n]+)(\\/.*)?$');
  static readonly urlRegexDomain = new RegExp('^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-zA-Z0-9ñ]+([\\-\\.]{1}[a-zA-Z0-9]+)*\\.[a-z]{2,9}(:[0-9]{1,5})?(\\/.*)?$');
  static readonly urlRegexDomainCyrillic = new RegExp('^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[а-яА-Яa-zA-Z0-9ñ]+([\-\.]{1}[а-яА-Яa-zA-Z0-9]+)*\.[а-яА-Яa-z]{2,9}(:[0-9]{1,5})?(\/.*)?$');
  static readonly isurlHttpRegex = new RegExp('^(https?:\\/\\/)(www\\d*\\.)?([^\\/\\n]+)(\\/.*)?$');
  static readonly isnotHttpsRegex = new RegExp('^(http:\\/\\/www\\.|http:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$');
  static readonly isurlHttpRegexLight = new RegExp('^(?=.{1,400}$)(https?:\\/\\/)([\\da-z\\.-]+).*\\/?$');
  static readonly isIPRegex = new RegExp('^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$');
  // static readonly isurlHttpRegex = new RegExp('^(?=.{1,50}$)(https?:\\/\\/)([\\da-z\\.-]+)\\.([a-z\\.]{2,6})([\\/\\w \\.-]*)*\\/?$', 'i');
  // static readonly isUrlRegex = new RegExp('^(?=.{1,50}$)(https?:\\/\\/)?([\\da-z\\.-]+)\\.([a-z\\.]{2,6})([\\/\\w \\.-]*)*\\/?$', 'i');
  static readonly dateRegex = new RegExp('^(\\d{1,2})\\/(\\d{1,2})\\/(\\d{2,4})$');
  static readonly shortdateRegex = new RegExp('^(\\d{1,2})\\/(\\d{2,4})$');
  static readonly partialdateRegex = new RegExp('^(\\d{1,2})\\/(\\d{1,2})\\/(\\d{2,4})');
  static readonly hourRegex = new RegExp('^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$');
  static readonly hourMinutesRegex = new RegExp('^((?:[01]\\d|2[0-3]):[0-5]\\d$)');
  static readonly emailRegex = new RegExp('^(([a-zA-Z0-9_\\-\\.\\+]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5}){1,25})' +
    '+([;.](([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5}){1,25})+)*$');

  static readonly CHOICES_COLORS = [
    '#4ca6ff',
    '#37bd6c',
    '#ffa100',
    '#0068FA',
    '#9a91ff',
    '#ff4c6a',
    'rgb(255, 203, 23)',
    'rgb(255, 135, 76)',
    '#ff4ca6',
    '#ff4c6a',
    '#7310b7',
    '#40dc7e',
  ];

  static readonly GROUP_DAY = 2;
  static readonly GROUP_DEAL = 13;
  static readonly FORMAT = 15;
  static readonly DEAMAND_PARTNERS = 27;

  static readonly GROUPBY = [
    {value: 1, label: 'Ora', width: 80},
    {value: Constants.GROUP_DAY, label: 'Giorno', width: 100},
    {value: 3, label: 'Mese', width: 80},
    {value: 4, label: 'Dominio', width: 250},
    {value: 5, label: 'Exchange', width: 300},
    {value: 6, label: 'Publisher', width: 300},
    {value: 7, label: 'Template', width: 150},
    {value: 8, label: 'Advertiser', width: 200},
    {value: 9, label: 'Segmento', width: 300},
    {value: 9, label: 'Segmento', width: 300},
    {value: 10, label: 'Campagna', width: 300},
    {value: 11, label: 'Strategy', width: 300},
    {value: 12, label: 'Creatività', width: 200},
    {value: Constants.GROUP_DEAL, label: 'Deal', width: 200},
    {value: 14, label: 'Brand', width: 250},
    {value: Constants.FORMAT, label: 'Formato', width: 100},
    {value: 16, label: 'Device', width: 90},
    {value: 17, label: 'Sorgente di traffico', width: 180},
    {value: 18, label: 'Nazione', width: 200},
    {value: 19, label: 'Regione', width: 200},
    {value: 20, label: 'Buyer', width: 200},
    {value: 21, label: 'Spazi', width: 120},
    {value: Constants.DEAMAND_PARTNERS, label: 'DSP', width: 200},
    {value: 28, label: 'Categoria Principale', width: 200},
    {value: 29, label: 'Categoria secondaria', width: 200},
  ];

  /*************************************************** Campaign Types ***********************************************************/
  static readonly PERFORMANCE = 1;
  static readonly BRANDING = 2;
  static readonly INTEGRATION = 3;

  /*************************************************** Attribution Mode ***********************************************************/
  static readonly LAST_IMPRESSION = 1;
  static readonly LAST_CLICK = 2;
  static readonly REATTRIBUTION = 3;

  static readonly ATTRIBUTION_MODE_CHOICE = [{value: Constants.LAST_IMPRESSION, label: 'Last View/Click'}, {value: Constants.LAST_CLICK, label: 'Last Click'},
    {value: Constants.REATTRIBUTION, label: 'Re-attribution'}];

  /*************************************************** Targeting Rules **********************************************************/

  static readonly DEVICE_RULE = 1;
  // static readonly CATEGORY_RULE = 2; // not for now
  static readonly WEBSITE_RULE = 3;
  static readonly ISP_CARRIER_RULE = 4;
  // static readonly CAMPAIGN_SEGMENT_RULE = 6; // not for now
  static readonly GEO_RULE = 7;
  static readonly SCREEN_SIZE_RULE = 8;
  static readonly BROWSER_RULE = 9;
  static readonly OPERATING_SYSTEMS_RULE = 10;
  static readonly EXCHANGE_RULE = 11;
  static readonly MULTIPLE_KEYWORDS = 12;
  static readonly DOMAIN_RULE = 13;
  static readonly PUBLISHER_RULE = 14;
  static readonly SEGMENTS_RULE = 15;
  static readonly KEYWORD_RULE = 16;
  // static readonly PAGE_POSITION_RULE = 17; // sostituita da TEMPLATE_ADSPACE_POSITION_RULE
  static readonly CAPPING_RULE = 18;
  static readonly TRAFFING_SOURCE_RULE = 19;
  static readonly TEMPLATE_ADSPACE_POSITION_RULE = 20;
  static readonly CAMPAIGNS_RULE = 21;
  // static readonly ADSPACES_BUYER_RULE = 22; // not for now
  static readonly BRANDS_RULE = 23;
  static readonly DMP_DATA_RULE = 24;
  static readonly BRAND_SAFETY_RULE = 42;
  static readonly DMP_CATEGORY_RULE = 43;
  static readonly INVENTORY_CATEGORIES_RULE = 44;
  static readonly DEALS_RULE = 45;
  static readonly DIMENSION_SPACE_RULE = 46;
  static readonly IP_RULE = 47;
  // static readonly DSP_RULE = 49; // No more
  static readonly IAB_CATEGORY_RULE = 50;
  static readonly CAP_RULE = 51;
  static readonly GEO_MAP_RULE = 52;
  static readonly NO_INTERACTION_RULE = 53;
  static readonly FORMATS_RULE = 54;
  static readonly GEEK_USER_RULE = 55;
  static readonly LANGUAGE_RULE = 56;
  static readonly CLUSTER_USER_RULE = 57;
  static readonly MULTI_LAT_LONG_RULE = 58;
  static readonly CHOICE_SOCIAL_GEO_RULE = 59;
  static readonly MULTI_SOCIAL_ADDRESS_RULE = 60;
  static readonly SOCIO_DEMO_RULE = 61;
  static readonly SOCIO_DEMO_2_RULE = 62;
  static readonly HOT_USERS_RULE = 63;
  static readonly SEGMENTS_GLOBAL_RULE = 64;
  static readonly IAS_RULE = 65;
  static readonly CHOICE_SEARCH_GEO_RULE = 66;
  static readonly APP_RULE = 67;
  static readonly NEW_USERS_RULE = 68;
  static readonly ANCHORAGE_RULE = 69;
  static readonly CAPPING_DEAL_RULE = 70;
  static readonly CONTEXT_4D_RULE = 71;
  static readonly GEO_HYPERLOCAL_MULTI = 72;
  static readonly SEMANTIC_TOPIC_RULE = 73;
  static readonly VERTICAL_RULE = 74;
  static readonly BRAND_SAFETY_KEYWORD_LIST = 75;


  // TargetingState: (Limita a: 0, Escludi: 1)
  static readonly LIMIT_TO_TARGETING = 0;
  static readonly EXCLUDE_TARGETING = 1;

  /*************************************************** Messages/Alerts ***********************************************************/

  // Array contenente tutti gli alert
  static readonly alerts: Message[] = [
    {severity: 'success', summary: 'Saved!',
      detail: 'Can access to your report in the page "Saved Reports".'},
    {severity: 'success', summary: 'Done!',
      detail: 'Automatic scheduling for this report has been set up', life: 7000},
    {severity: 'info', summary: 'Do you know it?',
      detail: 'In the "Selected Columns" panel you can drag and drop them to reorder'},
    {severity: 'info', summary: 'Do you know it?',
      detail: 'In "Saved Reports" page you can set an automatic report and receive it to your email address'},
    {severity: 'warn', summary: 'Disabled!',
      detail: 'You will not receive the email anymore', life: 7000},
    {severity: 'success', summary: 'Password changed!',
      detail: 'You changed the password'},
    {severity: 'error', summary: 'Error!',
      detail: 'Check the fields'},
    {severity: 'error', summary: 'Error!',
      detail: 'Select at least one column'},
    {severity: 'error', summary: 'Error!',
      detail: 'Select at least one groupBy'},
    {severity: 'error', summary: 'Error!',
      detail: 'Operation failed'},
    {severity: 'success', summary: 'Success!',
      detail: 'Successful operation'}];

  static readonly monthsLabels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

/*************************** DEFAULT COORDS ****************************/
  static readonly DEFAULT_LAT = 41.90;
  static readonly DEFAULT_LNG = 12.49;

/*************************** REPORT TIME ID ****************************/

  static readonly TODAY = 1;
  static readonly YESTERDAY = 2;
  static readonly LAST_7_DAYS = 3;
  static readonly LAST_30_DAYS = 4;
  static readonly CURRENT_WEEK = 5;
  static readonly CURRENT_MONTH = 6;
  static readonly LAST_MONTH = 7;
  static readonly LAST_2_MONTH = 8;
  static readonly SELECT_DATE = 9;
  static readonly SELECT_PERIOD = 10;
  static readonly LAST_15_DAYS = 11;
  static readonly LAST_4_MONTH = 20;

/*************************** FORMAT TYPE IDS *******************************/
  static readonly DISPLAY = 2;
  static readonly VIDEO = 3;
  static readonly SKIN = 4;
  static readonly NATIVE = 6;

  static readonly FORMAT_CHOICE: SelectItem[] = [{value: Constants.DISPLAY, label: 'Display'},
    {value: Constants.VIDEO, label: 'Video'}, {value: Constants.NATIVE, label: 'Native'}];
  // , {value: Constants.SKIN, label: 'Skin'}];

  /*************************** CAPPING ****************************/

  static readonly MINUTE_CAPPING = 0;
  static readonly HOUR_CAPPING = 1;
  static readonly DAY_CAPPPING = 2;

/*************************** DIFFERENT REPORT ID ****************************/
  static readonly REPORT_CAMPAIGN_ID = 0;
  static readonly REPORT_REVENUE_ID = 1;
  static readonly REPORT_SEGMENT_ID = 2;
  static readonly REPORT_PAGE_ID = 4;
  static readonly REPORT_OM = 5;
  static readonly REPORT_HEATMAP = 6;
  static readonly REPORT_AUDIENCE_EXTENSION_ID = 8;

  static readonly SELECT_REPORT: SelectItem[] = [
    {value: Constants.REPORT_CAMPAIGN_ID, label: 'Campaign Report'},
    {value: Constants.REPORT_REVENUE_ID, label: 'Revenue Report'},
    {value: Constants.REPORT_AUDIENCE_EXTENSION_ID, label: 'Audience Extension Report'}];

/****************************** RULE NAME TYPE IDS ******************************/
  static readonly ADS_RULE_TYPE = 'ads';
  static readonly ADVERTISER_RULE_TYPE = 'advertisers';
  static readonly DEALS_RULE_TYPE = 'deals';
  static readonly MACRO_RULE_TYPE = 'macros';
  static readonly SEGMENT_RULE_TYPE = 'segments';

/******************************* Entity Type ****************************/
  static readonly ADMIN = 1;
  static readonly PUBLISHER = 2;
  static readonly EXCHANGE = 3;
  static readonly BUYER = 4;
  // static readonly NETWORK = 5;
  // static readonly DIRECT_INTEGRATION = 6;
  // static readonly BUYER_NETWORK = 7;
  // static readonly RESELLER = 8;
  // static readonly META_DSP = 9;
  static readonly BILLING = 10;
  // static readonly ADVERTISER = 12;
  static readonly BUYER_DEAL = 13;
  static readonly REFERRER = 14;

/******************************* Entity Type Color ****************************/
  static readonly ADMIN_COLOR = '#ffa100';
  static readonly PUBLISHER_COLOR = '#37bd6c';
  static readonly EXCHANGE_COLOR = '#ff4c6a';
  static readonly BUYER_COLOR = '#0068FA';
  // static readonly NETWORK_COLOR = '#b78365';
  // static readonly DIRECT_INTEGRATION_COLOR = '#8ab720';
  // static readonly RESELLER_COLOR = '#556150';
  static readonly BILLING_COLOR = '#036c03';
  // static readonly ADVERTISER_COLOR = '#da31c2';
  static readonly BUYER_DEAL_COLOR = '#0068ff';
  static readonly REFERRER_COLOR = '#556150';


/******************************* Entity Type Array ****************************/
  static readonly ENTITIES: {id: number, name: string, color: string}[] = [
    {id: 0, name: 'All', color: '#08192c'},
    {id: Constants.ADMIN, name: 'Admin', color: Constants.ADMIN_COLOR},
    // {id: Constants.ADVERTISER, name: 'Advertiser', color: Constants.ADVERTISER_COLOR},
    // {id: Constants.BILLING, name: 'Billing', color: Constants.BILLING_COLOR},
    {id: Constants.BUYER, name: 'Buyer', color: Constants.BUYER_COLOR},
    // {id: Constants.BUYER_DEAL, name: 'Buyer Deal', color: Constants.BUYER_DEAL_COLOR},
    {id: Constants.EXCHANGE, name: 'Exchange', color: Constants.EXCHANGE_COLOR},
    // {id: Constants.NETWORK, name: 'Network', color: Constants.NETWORK_COLOR},
    {id: Constants.PUBLISHER, name: 'Publisher', color: Constants.PUBLISHER_COLOR},
    // {id: Constants.DIRECT_INTEGRATION, name: 'Publisher Direct Integration', color: Constants.DIRECT_INTEGRATION_COLOR},
    // {id: Constants.RESELLER, name: 'Publisher Reseller', color: Constants.RESELLER_COLOR}
    {id: Constants.REFERRER, name: 'Referrer', color: Constants.REFERRER_COLOR}
    // {id: 11, name: '', color: '#000'},
  ];
/***************************** Configuration Id  *******************************/
  static readonly ADMIN_CONF_ID = 3; // Admin
  static readonly FASTBID_PUBLISHER = 13; //  se configuration_id in getUser = FASTBID_PUBLISHER allora è un nuovo publisher
  static readonly ADVERTISER_CONF_ID = 12; //
  static readonly SUB_PUBLISHER = 16; //
  static readonly BUYER_CERBERUS = 18; // Buyer di cerberus
  static readonly REFERRER_CONF_ID = 21; // Config Referrer
  static readonly ONE_TAG_SUITE = 1; // Onetag_Suite
  static readonly ONE_TAG_EXCHANGE = 0; // Onetag_Exchange

/*************************** CAMPAIGN PERMISSION ***************************/
  static readonly VIEWABILITY_BOOST_PERM = 'Viewability_boost';
  static readonly INTEGRATION_PERM = 'Integration';
  static readonly MAX_BID_PERM = 'Max_bid';
  static readonly APPROVAL_PERM = 'Approval_not_need';
  static readonly ENABLE_DN_PERM = 'Enable_dn';
  static readonly HIDE_REFERER_PERM = 'Hide_referer';
  static readonly VIDEO_SYNDACATION = 'Enable_video_syndacation';
  static readonly ALL_NETWORK_PERM = 'All_network';

/****************************GENERIC PERMISSION*******************************/
  static readonly AGENCY_PERM = 'Agency_Managment';
  static readonly MASTER_USER_PERM = 'Master_User';
  static readonly LOG_PERM = 'Enable_log';
  static readonly FLOOR_PRICE_PERM = 'FloorPrice_Managment';
  static readonly TRIGGER_CODE_PERM = 'Trigger_code';
  static readonly NETWORK_PERM = 'Network';
  static readonly USER_CONTROL_PERM = 'User_Control';

  /****************************KPI DEAL CURATE PERMISSION*******************************/
  static readonly DC_VTR_KPI_PERM = 'dc_vtr';
  static readonly DC_VIEWABILITY_KPI_PERM = 'dc_viewability';
  static readonly DC_SCREEN_REAL_ESTATE_KPI_PERM = 'dc_screen_real_estate';
  static readonly DC_ATTENTION_MAX_TIME_OPT_PERM = 'dc_opt_attention_max_time_in_view';
  static readonly DC_ATTENTION_FIND_LOW_OPT_PERM = 'dc_opt_attention_find_lower_ad_density';
  static readonly DC_QUALITY_FILTER_MFA_OPT_PERM = 'dc_opt_quality_filter_mfa_patterns';
  static readonly DC_QUALITY_AD_REFRESH_OPT_PERM = 'dc_opt_quality_minimize_ad_refresh';

  /****************************KPI OPENV ERIFIED PERMISSION*******************************/
  static readonly OV_VTR_KPI_PERM = 'ov_vtr';
  static readonly OV_VIEWABILITY_KPI_PERM = 'ov_viewability';
  static readonly OV_SCREEN_REAL_ESTATE_KPI_PERM = 'ov_screen_real_estate';
  static readonly OV_ATTENTION_MAX_TIME_OPT_PERM = 'ov_opt_attention_max_time_in_view';
  static readonly OV_ATTENTION_FIND_LOW_OPT_PERM = 'ov_opt_attention_find_lower_ad_density';
  static readonly OV_QUALITY_FILTER_MFA_OPT_PERM = 'ov_opt_quality_filter_mfa_patterns';
  static readonly OV_QUALITY_AD_REFRESH_OPT_PERM = 'ov_opt_quality_minimize_ad_refresh';

/*************************************************** LOCALSTORAGE ***********************************************************/
  static readonly SAVEDCAMPAIGN = '_OneTag_savedCampaign';
  static readonly SAVEDTAGCAMPAIGN = '_OneTag_savedPubCampaign';
  static readonly SAVEDCOLUMNS = '_OneTag_savedColumns';
  static readonly SAVEDREPORT = '_OneTag_savedReport';
  static readonly SAVEDRECENTUSERS = '_OneTag_recentUsers';

  /**************************************************** EXCHANGE ID *************************************************************/
  static readonly ADFORM_DSP_EXCHANGE_ID = 18731;

  /**************************************************** ENTITY ID *************************************************************/
  static readonly ONETAG_ENTITY_ID = 5;
  static readonly ONETAG_MANAGED_ENTITY_ID = 1498;
  static readonly OT_MANAGED_ENTITY_ID = 1498;
  static readonly ITALIA_ONLINE_ENTITY = 7183;
  static readonly DAILYMOTION_ENTITY = 89447;
  static readonly OT_ADOPS_ENTITY = 143766;
  static readonly ADYSSEUM_ENTITY_ID = 175637;
  static readonly POKI_ENTITY_ID = 305178;
  static readonly PUBLICIS_MEDIA_BUYER_ENTITY_ID = 145694;
  static readonly PUBLICIS_MEDIA_PUBLISHER_ENTITY_ID = 325816;
  static readonly _77AGENCY_ENTITY_ID = 341119;
  static readonly ZIFF_MEDIA_ENTITY_ID = 337605;
  static readonly CHRISTIANBAER_ID = 319617;
  static readonly ROBINSKRZYPEK_ID = 319618;
  static readonly LOTTOMATICA_ID = 316709;
  static readonly LOTTOMATICA_BETFLAG_ID = 336766;
  static readonly LOTTOMATICA_OPTIMOVE_ID = 350423;
  static readonly MATIIVA_ID = 344426;
  static readonly WINNER_ID = 347730;
  static readonly US_TEAM_ID = 324978;
  static readonly JOSHPORTER_ID = 332099;
  static readonly U_NADAT = 335775;
  static readonly TOM_ID = 333795;
  static readonly P_ENGELHART = 336842;
  static readonly A_COOK = 347605;
  static readonly M_AHMAD = 359228;
  static readonly ADFORM_ENTITY_ID = 368465;

/***************************************************** USER ID **************************************************************/
  static readonly MEDIAMATH_ADMIN = 6;
  static readonly OT_INTEGRATION = 284;
  static readonly DEVOPS_ONETAG = 1216;
  static readonly ANDREA_ONETAG = 3611;
  static readonly ANDREA_ONETAG_MAIL = 'andrea@onetag.com';
  static readonly MODA_ONETAG_MAIL = 'm.moda@onetag.com';
  static readonly BARRETTA_ONETAG_MAIL = 'v.barretta@onetag.com';
  static readonly JACKSON_ONETAG_MAIL = 's.jackson@onetag.com';
  static readonly BENNET_ONETAG_MAIL = 'j.bennett@onetag.com';
  static readonly DIPE_ONETAG_MAIL = 'm.dipetrillo@onetag.com';
  static readonly TRIOLO_ONETAG_MAIL = 'd.triolo@onetag.com';
  static readonly PUGGELLI_ONETAG_MAIL = 'l.puggelli@onetag.com';

  static readonly AZURE_PROVIDER = 'azure';

/*************************************************** CREATIVES TYPES ************************************************************/

  static readonly HTML_CREATIVE_ID = 1;
  static readonly VAST_CREATIVE_ID = 2;
  static readonly UPLOAD_CREATIVE_ID = 3;
  static readonly TEXTLINK_CREATIVE_ID = 4;
  static readonly RICHMEDIA_CREATIVE_ID = 5;


  /************************************************** METRIC CHOICE **************************************************/
   static readonly allMetricsChoice: SelectItem[] = [{value: MetricsIds.CTR, label: 'CTR'}, {value: MetricsIds.VTR, label: 'VTR'},
     {value: MetricsIds.VIEWABILITY, label: 'VIEWABILITY'}, {value: MetricsIds.CPA, label: 'CPA'}, {value: MetricsIds.NEW_CPA, label: 'New CPA'}, {value: MetricsIds.CPA_OVERALL, label: 'CPA OVERALL'},
     {value: MetricsIds.COST_PER_LANDING, label: 'COST PER LANDING'}, {value: MetricsIds.CPV, label: 'CPV'}];

   /********** PROFILE CONSTANTS ***************/
   static readonly TECH_FEE_PUBLISHER_DEFAULT = 0;
   static readonly OB_TECH_FEE_DEFAULT = 5;

   /*********************************************** COUNTRY IDs ****************************************************/
   static readonly ITALY_ID = 115;
   static readonly GERMANY_ID = 61;
   static readonly SPAIN_ID = 72;
   static readonly FRENCH_ID = 80;
   static readonly UK_ID = 82;
   static readonly GREECE_ID = 94;
   static readonly ROMANIA_ID = 194;
   static readonly US_ID = 238;

/*********************************************** REGISTRATION **********************************************************/

  static readonly REG_TYPE_ENTITY = 0;
  static readonly REG_TYPE_USER = 1;
  static readonly REG_TYPE_PUBLISHER = 2;
  static readonly REG_TYPE_BUYER = 3;

/************************************************ PRIME CALENDAR **********************************************************/

  static calen = {
    firstDayOfWeek: 0,
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    monthNames: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ],
    monthNamesShort: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ],
    today: 'Today',
    clear: 'Clear'
  };


  static readonly ORTB_CODE = 'https://bid-${LOCATION}.onetag-sys.com/openrtb/${PUB_ID}';
  static readonly SYNC_IFRAME = '\r\n' +
    '<script>\n' +
    '  var iframe = document.createElement("iframe");\n' +
    '  iframe.src = "https://onetag-sys.com/usync/?pubId=${PUB_ID}&gdpr=${GDPR}&gdpr_consent=${GDPR_STRING}&us_privacy=${US_PRIVACY}";\n' +
    '  iframe.style.display = "none";\n' +
    '  document.body.appendChild(iframe);\n' +
    '</script>';

  static readonly SYNC_HTML_IFRAME = '\r\n' +
    '<iframe src="https://onetag-sys.com/usync/?pubId=${PUB_ID}&gdpr=${GDPR}&gdpr_consent=${GDPR_STRING}&us_privacy=${US_PRIVACY}" style="display: none;"></iframe>';

  static readonly TAG_MANUAL = '  onetag("initAds");\r\n';

  static readonly DFP_SCRIPTS3 = '\n' +
    'googletag.enableServices();';

  /**************************************************** ADVERTISER BILLING DESTINATION ********************************************************/

  static readonly BILLING_DESTINATION_NONE = 0;
  static readonly BILLING_DESTINATION_AGENCY = 1;
  static readonly BILLING_DESTINATION_INVOICE = 2;

  /**************************************************** LINKS ********************************************************/

  static readonly LINK_ADMIN_PART = 'admin';
  static readonly LINK_APPROVAL_PART = 'approval';
  static readonly LINK_BILLING_PART = 'billings';
  static readonly LINK_BILLING_ADMIN_PART = 'admin/billings';
  static readonly LINK_CAMPAIGN_PART = 'campaign-manager';
  static readonly LINK_NEW_CAMPAIGN_PART = 'edit';
  static readonly LINK_DEAL_PART = 'deal';
  static readonly LINK_INVITES_PART = 'invites';
  static readonly LINK_MANAGER_PART = 'account';
  static readonly LINK_REPORT_PART = 'stats';
  static readonly LINK_RULES_PART = 'rules';
  static readonly LINK_PUBLISHER_PART = 'sites-apps';
  static readonly LINK_SEGMENTS_PART = 'pixel';
  static readonly LINK_SELLERS = 'admin/publishers/sellers';
  static readonly LINK_QUALITY_VETTING_WEB = 'admin/quality-vetting-web';
  static readonly LINK_QUALITY_VETTING_APP = 'admin/quality-vetting-app';
  static readonly LINK_GCMA_AND_IVT = 'admin/publishers/gcmaivt';
  static readonly LINK_COMMUNICATION_BAR = 'admin/communication-bar';
  static readonly LINK_QUALITY_BLOCKLIST = 'admin/quality-blocklist';
  static readonly LINK_TEST_PART = 'test';
  static readonly LINK_DEMAND_PARTNERS_PART = 'demand-partners';
  static readonly LINK_DASHBOARD_CERBERUS_BUYER_PART = 'buyer-dashboard';
  static readonly LINK_NEW_CAMPAIGN_CERBERUS_BUYER_PART = 'buyer-campaign';
  static readonly LINK_USER_MANAGEMENT_ADMIN_PART = 'user-management';

  // RULES
  static readonly LINK_GLOBAL_TARGETING = 'global-targeting';
  static readonly LINK_PUBLISHER_TARGETING = 'rules';

  // ADMIN
  static readonly LINK_ALIAS = 'alias';
  static readonly LINK_COL_MANAGE = 'columns-config';
  static readonly LINK_GROUP_MANAGEMENT = 'groups-config';
  static readonly LINK_INTEGRATIONS = 'integrations';
  static readonly LINK_MY_BUYERS = 'my-buyers';
  static readonly LINK_MY_PUBLISHERS = 'my-publishers';
  static readonly LINK_OWNER_MANAGE = 'owners-config';
  static readonly LINK_PUBLISHER_SETTINGS = 'publishers/settings';
  static readonly LINK_BUYER_IGAMING = 'buyers/igaming';
  static readonly LINK_BUYER_LABELS = 'buyers/labels';
  static readonly LINK_BUYER_RELATED_CAMPAIGNS = 'buyers/related-campaigns';
  static readonly LINK_QPS_BLOCKLIST = 'publishers/inventory-limiting';
  static readonly LINK_SOCIAL_PAGES = 'social-pages';
  static readonly LINK_USER_MANAGEMENT = 'users-config';
  static readonly LINK_USERS = 'users-list';
  static readonly LINK_FEE_BREAKDOWN = 'fee-breakdown';
  static readonly LINK_USER_MANAGE = 'user-manager';
  static readonly LINK_JOB_SCHEDULE = 'job-schedule';

  // APEIRON
  static readonly LINK_CODE_SNIPPETS = 'code-snippets';

  // APPROVAL
  static readonly LINK_APPROVAL_CAMPAIGN = 'campaigns';
  static readonly LINK_APPROVAL_PUBLISHER = 'publishers/approval';
  static readonly LINK_APPROVAL_BUYER = 'buyers/approval';
  static readonly LINK_APPROVAL_CREATIVES = 'creatives';

  // BILLING
  static readonly LINK_PRENOS = 'orders';
  static readonly LINK_BILLING_PUBLISHER = 'billing';
  static readonly LINK_BILLING_PUBLISHER_ADMIN = 'publisher-payment';
  static readonly LINK_BILLING_BUYER = 'billing-buyer';
  static readonly LINK_BILLING_BUYER_ADMIN = 'buyer-invoicing';
  static readonly LINK_INSERTION_ORDERS_SELF = 'insertion-orders-self';
  static readonly LINK_INSERTION_ORDERS_MANAGED = 'insertion-orders-managed';
  static readonly LINK_BILLING_DEADLINE = 'deadlines';

  // CAMPAIGNS
  static readonly LINK_ADAPTER = 'adapter';
  static readonly LINK_ADVERTISERS = 'advertisers';
  static readonly LINK_CAMP_LIST = 'campaigns-list';
  static readonly LINK_DASHBOARD_CAMPAIGN = 'dashboard-campaigns';
  static readonly LINK_MANAGEMENT = 'management';
  static readonly LINK_NEW_CAMPAIGN = 'form-campaign';

  // CERBERUS BUYER
  static readonly LINK_DASHBOARD_CERBERUS = 'dashboard';
  static readonly LINK_CAMPAIGN_CERBERUS = 'form-campaign';

  // CERBERUS PUBLISHER
  static readonly LINK_DEMAND_MANAGEMENT = 'demand-management';

  // DEAL
  static readonly LINK_DASHBOARD_DEAL = 'dashboard-deals';
  static readonly LINK_DEALS_PUBLISHER = 'deals';
  static readonly LINK_DEALS_AUCTION_PACKAGE = 'inventory-packages';
  static readonly LINK_DEALS_OPEN_VERIFIED = 'open-verified';
  static readonly LINK_DEALS_BUYER = 'deals-buyer';

  // HOME
  static readonly LINK_HOME = 'dashboard';

  // INVITES
  static readonly LINK_INVITE_PUB = 'publishers/invites';

  // MANAGER
  static readonly LINK_ACCOUNT_DETAILS = 'my-details';
  static readonly LINK_COMPANY_DETAILS = 'company-details';
  static readonly LINK_PAYMENT_PROFILE = 'payment-profile';
  static readonly LINK_ADMIN_ZONE = 'admin-zone';
  static readonly LINK_BILLING_ADMIN_ZONE = 'billing-profile';

  // REPORTS
  static readonly LINK_REPORT_CAMPAIGN = 'report';
  static readonly LINK_REPORT_REVENUE = 'earnings';
  static readonly LINK_REPORT_SEGMENT = 'report-segment';
  static readonly LINK_REPORT_HB = 'report-hb';
  static readonly LINK_REPORT_PAGE = 'report-page';
  static readonly LINK_REPORT_SAVED = 'saved-reports';
  static readonly LINK_REPORT_QUICKSIGHT = 'quicksight';
  static readonly LINK_REPORT_SAVED_CAMP = 'campaigns/' + Constants.LINK_REPORT_SAVED;
  static readonly LINK_REPORT_SAVED_REVE = 'earnings/' + Constants.LINK_REPORT_SAVED;
  static readonly LINK_REPORT_SAVED_CURATION = 'earnings/' + Constants.LINK_REPORT_SAVED + '-curation';
  static readonly LINK_REPORT_SAVED_SEGM = 'segments/' + Constants.LINK_REPORT_SAVED;
  static readonly LINK_REPORT_SAVED_PAGE = 'pages/' + Constants.LINK_REPORT_SAVED;
  static readonly LINK_REPORT_SAVED_ANALYTICS = 'analytics/' + Constants.LINK_REPORT_SAVED;
  static readonly LINK_REPORT_GEO = 'report-geo';
  static readonly LINK_REPORT_AUDIENCE_EXTENSION = 'audience-extension';


  // PUBLIC
  static readonly LINK_LOGIN = 'login';
  static readonly LINK_LOGIN_SSO = 'login-sso';
  static readonly LINK_SIGNUP = 'signup';
  static readonly LINK_REGISTRATION_PUBLISHER = 'signup';
  static readonly LINK_SIGNUP_BUYER = 'buyer';
  static readonly LINK_SIGNUP_PUBLISHER = 'publisher';
  static readonly LINK_INVITATION = 'invitation';
  static readonly LINK_PASS_RECOVERY = 'password-recovery';
  static readonly LINK_INVITE_PUBLISHER = 'invite';
  static readonly LINK_OB_2PART = 'open-bidding';
  static readonly LINK_INVITE_GOOGLE_PUBLISHER = Constants.LINK_REGISTRATION_PUBLISHER + '/' + Constants.LINK_OB_2PART;
  static readonly LINK_INVITE_ADDITIONAL_USER = 'join';

  // PUBLISHER
  static readonly LINK_ADS_TXT = 'adstxt';
  static readonly LINK_CODES = 'codes';
  static readonly LINK_DFP_LIST = 'dfp-settings';
  static readonly LINK_DOMAINS = 'domains';
  static readonly LINK_FLOOR = 'floors';
  static readonly LINK_PLACEMENTS = 'placements';
  static readonly LINK_BLOCKLIST = 'blocklist';
  static readonly LINK_PAGE_CODES = 'page-codes';
  static readonly LINK_SPACES_AUTO = 'adspaces-auto';
  static readonly LINK_SPACES_IAB = 'adspaces-IAB';

  // SEGMENTS
  static readonly LINK_SEGMENTS = 'segments';

  // TESTS
  static readonly LINK_PAGE_TEST = 'page-test';

  // USER MANAGEMENT ADMIN
  static readonly LINK_MANAGEMENT_PUB = 'publishers/management';
  static readonly LINK_MANAGEMENT_BUY = 'buyers/management';
  static readonly LINK_MANAGEMENT_AGENCIES = 'buyers/agencies';
  static readonly LINK_MANAGEMENT_INVOICING_PROFILE = 'buyers/invoicingProfile';

  // DASHBOARD MANAGEMENT ADMIN
  static readonly LINK_MANAGEMENT_DASHBOARD = 'publishers/dashboard';

  /* API per PREBID */
  static readonly API_PREBID = '\r\n' +
  'pbjs.setConfig({' + '\r\n' +
  '  userSync: {' + '\r\n' +
  '      filterSettings: {' + '\r\n' +
  '          iframe: {' + '\r\n' +
  '              bidders: \'*\',   // \'*\' means all bidders' + '\r\n' +
  '              filter: \'include\'' + '\r\n' +
  '          }' + '\r\n' +
  '      }' + '\r\n' +
  '  }' + '\r\n' +
  '});';

  static readonly BIDDER_CONFIGURATION_1 = '\r\n' +
  '{' + '\r\n' +
  '  bidder: "onetag",' + '\r\n' +
  '  params: {' + '\r\n' +
  '      pubId: "${PUB_ID}" ' + '\r\n' +
  '  }' + '\r\n' +
  '}';

  static readonly BIDDER_CONFIGURATION_2 =  '\r\n' +
  'var adUnits = [' + '\r\n' +
  '  {' + '\r\n' +
  '     code: "PLACEMENT_NAME",' + '\r\n' +
  '     mediaTypes: {' + '\r\n' +
  '          banner: {' + '\r\n' +
  '              sizes: [[300, 250]]' + '\r\n' +
  '          }' + '\r\n' +
  '      },' + '\r\n' +
  '      bids: [' + '\r\n' +
  '           {' + '\r\n' +
  '              bidder: "onetag",' + '\r\n' +
  '              params: {' + '\r\n' +
  '                   pubId: "${PUB_ID}"' + '\r\n' +
  '              }' + '\r\n' +
  '           }' + '\r\n' +
  '      ]' + '\r\n' +
  '   }' + '\r\n' +
  ']';

  static readonly PLACEMENT_MAPPING = '\r\n' +
  '   {' + '\r\n' +
  '      bidder: "onetag",' + '\r\n' +
  '      params: {' + '\r\n' +
  '         pubId: "${PUB_ID}",' + '\r\n' +
  '         ext: { placement_name: "CUSTOM_PLACEMENT_NAME"},' + '\r\n' +
  '      }' + '\r\n' +
  '   }';

  static readonly AMP_USER_SYNC_1 = '\r\n' +
  '<amp-iframe width="1" title="User Sync"' + '\r\n' +
  '  height="1"' + '\r\n' +
  '  sandbox="allow-scripts allow-same-origin"' + '\r\n' +
  '  frameborder="0"' + '\r\n' +
  '  src="https://onetag-sys.com/usync/?pubId=${PUB_ID}&amp=1&prebid_instance=appnexus">' + '\r\n' +
  '  <amp-img layout="fill" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" placeholder></amp-img>' + '\r\n' +
  '</amp-iframe>';

  static readonly AMP_USER_SYNC_2 = '\r\n' +
  '<amp-iframe width="1" title="User Sync"' + '\r\n' +
  '  height="1"' + '\r\n' +
  '  sandbox="allow-scripts allow-same-origin"' + '\r\n' +
  ' frameborder="0"' + '\r\n' +
  '  src="https://onetag-sys.com/usync/?pubId=${PUB_ID}&amp=1&prebid_instance=appnexus&gdpr=1">' + '\r\n' +
  '  <amp-img layout="fill" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" placeholder></amp-img>' + '\r\n' +
  '</amp-iframe>';

  static readonly HTML_TAG_DIV = '\r\n' +
  '<div class="onetag" data-placement-id="${ID_PLACEMENT}" data-pub="${PUB_ID}" style="${STYLE}"></div>';

  static readonly HTML_TAG_ADSERVER_CASE_OT = '\r\n' +
  '<script src="https://onetag-sys.com/tag/adserverPlacement.js"></script>';

  static readonly INCREMENTAL_FORMATS_TAG = '<script src="https://onetag-sys.com/tag/smartAds.js" data-pub="${PUB_ID}" data-formats="${FORMATS}" async></script>';

  static readonly USER_KEY_LOCALSTORAGE = 'usr-as';

  /**************************************************** DEALS ********************************************************/

  static readonly formatChoice: SelectItem[] = [{value: Constants.DISPLAY, label: 'Display'}, {value: Constants.VIDEO, label: 'Video'}];
  static readonly priceTypeChoice: SelectItem[] = [{value: PriceTypeEnum.fixed, label: 'Fixed'}, {value: PriceTypeEnum.auction, label: 'Auction'}];
  static readonly modeChoice: SelectItem[] = [{label: 'All sizes', value: false}, {label: 'Choose sizes', value: true}];
}

