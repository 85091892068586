import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SidePanelStackComponent} from '../../../_sharedMVC/sidePanel/sidePanelStack/sidePanelStack.component';
import {CreativeGroup} from '../../cerberusBuyer/cerEntity/CretiveGroup';

@Component({
  selector: 'app-groupsidebar',
  templateUrl: './groupSidebar.component.html',
  styleUrls: ['./groupSidebar.component.css', '../cerGlobalStyles.css']
})

export class GroupSidebarComponent {

  @Input() stack: SidePanelStackComponent;
  @Input() creativeGroup: CreativeGroup;
  @Input() controlOut: boolean; // True se voglio un alert di uscita
  @Input() cancelLabel: string;
  @Input() noCancel: boolean;
  @Input() notHideContentSmall?: boolean;
  @Input() isSubmitDisabled: boolean;

  // tslint:disable-next-line: no-input-rename
  @Input('label') submitLabel: string;
  @Output() alertCancel = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() submit = new EventEmitter();

  public stateMessage: string;

  constructor() {}

  onCancel() {
    if (this.controlOut) {
      this.alertCancel.emit();
    } else {
      this.removePanel();
    }
  }

  onSubmit() {
    if (!this.isSubmitDisabled) {
      this.submit.emit(true)
    }
  }

  removePanel() {
    this.cancel.emit(true);
    this.stack.pop()
  }

}
