import { NgModule } from '@angular/core';
import {LoginService} from '../services/login.service';
import {TableService} from '../services/table.service';
import {CampaignService} from '../services/campaign.service';
import {CanDeactivateGuard} from '../__helpers/can-deactivate-guard.service';
import {AuthGuard} from '../__helpers/authGuard/authGuard';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {JwtInterceptor} from '../__helpers/jwt.interceptor';
import {GroupSidebarComponent} from './cerberusUtility/groupSidebar/groupSidebar.component';
import {CommonModule} from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
    ],
  declarations: [
    GroupSidebarComponent,
  ],
  providers: [
    LoginService,
    TableService,
    CampaignService,
    CanDeactivateGuard,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
  ],
  exports: [
    GroupSidebarComponent,
  ]
})
export class GroupSidebarModule { }
