import {SelectItem} from 'primeng/api';

export class PubConstant {
  public static readonly SITE_NOT_FOUND = 1;
  public static readonly APP_NOT_FOUND = 2;
  public static readonly ADSTXT_FAIL = 3;
  public static readonly RENDER_RATE = 4;
  public static readonly INTERNAL_SPENT = 5;
  public static readonly COOKIE_MISSING = 6;
  public static readonly EXHAUSTED_QUOTA = 7;
  // public static readonly NEARLY_EXHAUSTED_QUOTA = 8;
  public static readonly PAYMENT_PROFILE_MISSING = 9;

  public static readonly ISSUES: SelectItem[] = [
    {value: PubConstant.SITE_NOT_FOUND, label: 'Missing approved Site'},
    {value: PubConstant.APP_NOT_FOUND, label: 'Missing approved Apps'},
    {value: PubConstant.ADSTXT_FAIL, label: 'Ads.txt not found'},
    {value: PubConstant.RENDER_RATE, label: 'Render rate'},
    {value: PubConstant.INTERNAL_SPENT, label: 'Internal Spent'},
    {value: PubConstant.COOKIE_MISSING, label: 'Low Cookie Matchrate'},
    {value: PubConstant.EXHAUSTED_QUOTA, label: 'Too many placements'},
    // {value: PubConstant.NEARLY_EXHAUSTED_QUOTA, label: 'Nearly Exhausted quota'},
    {value: PubConstant.PAYMENT_PROFILE_MISSING, label: 'Invalid Bank Details'},
  ];

  public static readonly REFERRER_ISSUES: SelectItem[] = [
    {value: PubConstant.SITE_NOT_FOUND, label: 'Missing approved Site'},
    {value: PubConstant.APP_NOT_FOUND, label: 'Missing approved Apps'},
    {value: PubConstant.ADSTXT_FAIL, label: 'Ads.txt not found'},
    {value: PubConstant.COOKIE_MISSING, label: 'Low Cookie Matchrate'}
  ];

  public static readonly TIER_CHOICES: SelectItem[] = [
    {label: 'Tier 1', value: 1},
    {label: 'Tier 2', value: 2},
    {label: 'Tier 3', value: 3},
    {label: 'Tier 4', value: 4}
  ];

  public static readonly LOW_SEVERITY = 0;
  public static readonly MEDIUM_SEVERITY = 1;
  public static readonly HIGH_SEVERITY = 2;

  public static readonly SEVERITIES: SelectItem[] = [
    {value: PubConstant.LOW_SEVERITY, label: 'Low', styleClass: 'darkGreyColor'},
    {value: PubConstant.MEDIUM_SEVERITY, label: 'Medium', styleClass: 'yellowColor'},
    {value: PubConstant.HIGH_SEVERITY, label: 'High', styleClass: 'redColor'},
  ];

  static readonly INACTIVE = 0;
  static readonly ACTIVE = 1;
  public static readonly STATUS_ARRAY = [{label: 'Inactive', value: PubConstant.INACTIVE, color: 'yellowColor'},
    {label: 'Active', value: PubConstant.ACTIVE, color: 'greenColor'}];

  public static DEFAULT_ADSTXT: string;
  public static HB_ADSTXT: string;

  public static readonly HTML_TAGS_SIZES: SelectItem[] = [
    {value: 1, label: 'Webpages - 300x250'},
    {value: 2, label: 'Webpages - 300x600'},
    {value: 3, label: 'Webpages - 160x600'},
    {value: 4, label: 'Webpages - Responsive 100%x250'},
    {value: 5, label: 'Adserver - 300x250'},
  ];

  public static readonly HB_STATUS: SelectItem[] = [
    {value: 'Prebid_js', label: 'Prebid.js'},
    {value: 'Prebid_Server', label: 'Prebid Server'},
    {value: 'OpenRTB_Sites', label: 'OpenRTB for sites'},
    {value: 'OpenRTB_Apps', label: 'OpenRTB for apps'}
  ];

  public static readonly PREVUE = 'Prevue';
  public static readonly CONTENT_AD = 'Content_Ad';
  public static readonly WING_AD = 'Wing_Ads';
  public static readonly ANCHOR_AD = 'Anchor_Ad';
  public static readonly HTML_TAGS = 'HTML_Tags';
  public static readonly VAST_TAGS = 'VAST_Tags';

  public static readonly IF_STATUS: SelectItem[] = [
    {value: PubConstant.PREVUE, label: 'PreVue'},
    {value: PubConstant.CONTENT_AD, label: 'Content Ad'},
    {value: PubConstant.WING_AD, label: 'Wing Ads'},
    {value: PubConstant.ANCHOR_AD, label: 'Anchor Ad'},
    {value: PubConstant.HTML_TAGS, label: 'HTML Tags'},
    {value: PubConstant.VAST_TAGS, label: 'VAST Tags'},
  ];

  public static readonly CURATORS_CHOICE: SelectItem[] = [
    {
      value: false,
      label: 'Not Curator',
    },
    {
      value: true,
      label: 'Curator',
    },
  ]

  public static readonly TEMPLATE_STATUS: SelectItem[] = [...PubConstant.HB_STATUS, ...PubConstant.IF_STATUS];

  public static readonly INTEGRATIONS_STATUS: SelectItem[] = [
    {value: 'Header_Bidding', label: 'Header Bidding'},
    {value: 'Incremental_Formats', label: 'Incremental Formats'},
  ];

  public static readonly BID_ADJUSTMENT_NONE: number = 0;
  public static readonly BID_ADJUSTMENT_PARTIAL: number = 1;
  public static readonly BID_ADJUSTMENT_TOTAL: number = 2;
  public static readonly BID_ADJUSTMENT_CHOICE: SelectItem[] = [
    {value: PubConstant.BID_ADJUSTMENT_NONE, label: 'No Optimization'},
    {value: PubConstant.BID_ADJUSTMENT_PARTIAL, label: 'Partial Optimization'},
    {value: PubConstant.BID_ADJUSTMENT_TOTAL, label: 'Total Optimization'},
  ];

  public static readonly APP_PLACEHOLDER = 'https://apps.apple.com/us/app/tubi-watch-movies-tvshows/id886445756\nhttps://play.google.com/store/apps/details?id=com.tubitv\nhttps://channelstore.roku.com/details/7c34d32446def64db7eb2cc359749fe5/tubi-free-movies-and-tv\nhttps://www.amazon.com/Tubi-Inc/dp/B075NTHVJW\n...'
  public static readonly DOMAIN_PLACEHOLDER = 'example.com\nanotherexample.com\nlastexample.com\n...'

  // Store

  static readonly NOT_FOUND_STORE = 0;
  static readonly ANDROID_STORE = 1;
  static readonly IOS_STORE = 2;
  static readonly ROKU_STORE = 3;
  static readonly AMAZON_STORE = 4;
  static readonly SAMSUNG_STORE = 5;
  static readonly LG_STORE = 6;
  static readonly PHILIPS_STORE = 8;
  static readonly MICROSOFT_STORE = 9;

  static readonly STORE_LIST: SelectItem[] = [
    {value: PubConstant.NOT_FOUND_STORE, label: 'App Unknown', styleClass: 'CTV_unknown.png'},
    {value: PubConstant.ANDROID_STORE, label: 'App Android', styleClass: 'CTV_android.png'},
    {value: PubConstant.IOS_STORE, label: 'App IOS', styleClass: 'CTV_ios.png'},
    {value: PubConstant.ROKU_STORE, label: 'App Roku', styleClass: 'CTV_roku.png'},
    {value: PubConstant.AMAZON_STORE, label: 'App Amazon', styleClass: 'CTV_amazon.png'},
    {value: PubConstant.SAMSUNG_STORE, label: 'App Samsung', styleClass: 'CTV_samsung.png'},
    {value: PubConstant.LG_STORE, label: 'App LG', styleClass: 'CTV_lg.png'},
    {value: PubConstant.PHILIPS_STORE, label: 'App Zeasn', styleClass: 'CTV_zeasn.png'},
    {value: PubConstant.MICROSOFT_STORE, label: 'App Microsoft', styleClass: 'CTV_microsoft.png'}
  ];
}
