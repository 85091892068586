export class InventoryFilter {
  domain?: number[];
  app?: number[];
  traffic?: number[];
  exchange?: number[];
  publisher?: number[];
  template?: number[];
  country?: number[];
  category?: number[];
  referrer?: number[];
}
