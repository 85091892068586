import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject, throwError} from 'rxjs';
import {GlobalSettings} from '../../GlobalSettings';
import {JwtPermissions} from './jwtPermissions.service';
import {UserAs} from './UserAs';
import {switchMap, take, filter, catchError} from 'rxjs/operators';
import {LoginService} from '../services/login.service';
import {TableService} from '../services/table.service';
import {Constants} from "./entities/Constants";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  private refreshTokenInProgress = false;
  private refreshTokenSubject: Subject<any> = new BehaviorSubject<any>(null);

  constructor(private loginService: LoginService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const idExpired = JwtPermissions.getCurrentTokenID() && (JwtPermissions.getExpirationIDToken() < ((new Date().getTime() / 1000) + 10));
    const accessExpired = JwtPermissions.getCurrentAccessToken() && (JwtPermissions.getExpirationAccessToken() < ((new Date().getTime() / 1000) + 10));

    if (request.url.indexOf('refresh') !== -1) {
      return next.handle(request);
    }
    if (accessExpired || idExpired) {
      if (!this.refreshTokenInProgress) {
        this.refreshTokenInProgress = true;
        this.refreshTokenSubject.next(null);

        let sso: boolean = false;
        if (JwtPermissions.getCognitoProviderNameFromToken() === Constants.AZURE_PROVIDER) {
          sso = true;
        }

        return this.loginService.refreshToken(sso).pipe(
          switchMap((authResponse) => {
            JwtPermissions.setToken(authResponse);
            this.refreshTokenInProgress = false;
            this.refreshTokenSubject.next(authResponse.token);
            return next.handle(this.injectToken(request));
          }),
          catchError((err) => {
            TableService.loading = false;
            this.loginService.logout();
            return throwError(err);
          })
        );
      } else {
        return this.refreshTokenSubject.pipe(
          filter(result => result !== null),
          take(1),
          switchMap((res) => {
            return next.handle(this.injectToken(request))
          })
        );
      }
    } else {
      return next.handle(this.injectToken(request));
    }
  }

  injectToken(request: HttpRequest<any>) {
    // add authorization header with jwt token if available
    const currentUser = JwtPermissions.getCurrentTokenID();
    if (currentUser) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser}`
        }
      });
      if (UserAs.userAsID && request.url.indexOf('api/getIdentify') === -1 && request.url.indexOf('/permissions') === -1) {
        request = request.clone({
          setParams: {'usr-in': UserAs.userAsID + ''}
        });
      }
    }
    return request;
  }
}
