import {Component, OnInit, AfterContentInit, Input} from '@angular/core';
import {InfoService} from '../../services/info.service';
import {NavigationEnd, Router} from '@angular/router';
import {Constants} from '../../__helpers/entities/Constants';
import {UtilsFun} from '../../__helpers/entities/UtilsFun';
import {PageContent} from '../../__helpers/entities/PageContent';
import {UserData} from '../../__helpers/entities/UserData';
import {JwtPermissions} from '../../__helpers/jwtPermissions.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent implements OnInit, AfterContentInit {

  @Input() shortView?: boolean;

  sideImg: string;
  className: string;

  url = '';
  Constants = Constants;
  UtilsFun = UtilsFun;
  JwtPermissions = JwtPermissions;

  constructor(public infoService: InfoService, private router: Router) {

    router.events.subscribe((val) => {
      if ( val instanceof NavigationEnd) {
        this.url = val.url;
      }
    });
  }

  ngOnInit() {
    this.loadLogo();
  }

  loadLogo() {
    if (UtilsFun.matchDestinationCarat()) {
      this.sideImg = '../../assets/imgs/caratpg.png';
      this.className = 'caratPgLogo';
    } else {
      this.sideImg = '../../assets/imgs/onetag_logo_wordmark_white.png';
      // this.sideImg = '../../assets/imgs/logo_white_background.png';
      // this.sideImg = '../../assets/imgs/logo_christmas_background.png';
      // this.sideImg = '../../assets/imgs/logo_halloween_background_gif.gif';
      this.className = 'otlogo';
    }
  }

  ngAfterContentInit() {
    const url = this.router.url;
    this.url = url;
    switch (url) {
      case '/' + Constants.LINK_HOME: {
        const items = document.getElementsByClassName('sidebarElement');
        for (let i = 0; i < items.length; i++) {
          items[i].className = 'sidebarElement';
        }
        break;
      }
    }
  }

  openMobileNav() {
    const myElement: HTMLElement | null = document.getElementById('myMobileSidenav');
    if (myElement) {
      myElement.style.width = '210px';
    }
  }

  closeMobileNav() {
    const myElement: HTMLElement | null = document.getElementById('myMobileSidenav');
    if (myElement) {
     myElement.style.width = '0px';
    }
  }

  get myElements(): PageContent {
    return this.infoService.elements;
  }

  get myUser(): UserData {
    return this.infoService.user;
  }

  isPublicis(): boolean {
    return this.myUser && this.myUser.entityId === Constants.PUBLICIS_MEDIA_BUYER_ENTITY_ID || this.myUser.entityId === Constants.PUBLICIS_MEDIA_PUBLISHER_ENTITY_ID;
  }
}
