import { LatLng } from './LatLng';

export class CountryLatLng {
    readonly countryMap = new Map([
        ['AD', new LatLng(42.546245, 1.601554)],
        ['AE', new LatLng(23.424076, 53.847818)],
        ['AF', new LatLng(33.93911, 67.709953)],
        ['AG', new LatLng(17.060816, -61.796428)],
        ['AI', new LatLng(18.220554, -63.068615)],
        ['AL', new LatLng(41.153332, 20.168331)],
        ['AM', new LatLng(40.069099, 45.038189)],
        ['AN', new LatLng(12.226079, -69.060087)],
        ['AO', new LatLng(-11.202692, 17.873887)],
        ['AQ', new LatLng(-75.250973, -0.071389)],
        ['AR', new LatLng(-38.416097, -63.616672)],
        ['AS', new LatLng(-14.270972, -170.132217)],
        ['AT', new LatLng(47.516231, 14.550072)],
        ['AU', new LatLng(-25.274398, 133.775136)],
        ['AW', new LatLng(12.52111, -69.968338)],
        ['AZ', new LatLng(40.143105, 47.576927)],
        ['BA', new LatLng(43.915886, 17.679076)],
        ['BB', new LatLng(13.193887, -59.543198)],
        ['BD', new LatLng(23.684994, 90.356331)],
        ['BE', new LatLng(50.503887, 4.469936)],
        ['BF', new LatLng(12.238333, -1.561593)],
        ['BG', new LatLng(42.733883, 25.48583)],
        ['BH', new LatLng(25.930414, 50.637772)],
        ['BI', new LatLng(-3.373056, 29.918886)],
        ['BJ', new LatLng(9.30769, 2.315834)],
        ['BM', new LatLng(32.321384, -64.75737)],
        ['BN', new LatLng(4.535277, 114.727669)],
        ['BO', new LatLng(-16.290154, -63.588653)],
        ['BR', new LatLng(-14.235004, -51.92528)],
        ['BS', new LatLng(25.03428, -77.39628)],
        ['BT', new LatLng(27.514162, 90.433601)],
        ['BV', new LatLng(-54.423199, 3.413194)],
        ['BW', new LatLng(-22.328474, 24.684866)],
        ['BY', new LatLng(53.709807, 27.953389)],
        ['BZ', new LatLng(17.189877, -88.49765)],
        ['CA', new LatLng(56.130366, -106.346771)],
        ['CC', new LatLng(-12.164165, 96.870956)],
        ['CD', new LatLng(-4.038333, 21.758664)],
        ['CF', new LatLng(6.611111, 20.939444)],
        ['CG', new LatLng(-0.228021, 15.827659)],
        ['CH', new LatLng(46.818188, 8.227512)],
        ['CI', new LatLng(7.539989, -5.54708)],
        ['CK', new LatLng(-21.236736, -159.777671)],
        ['CL', new LatLng(-35.675147, -71.542969)],
        ['CM', new LatLng(7.369722, 12.354722)],
        ['CN', new LatLng(35.86166, 104.195397)],
        ['CO', new LatLng(4.570868, -74.297333)],
        ['CR', new LatLng(9.748917, -83.753428)],
        ['CU', new LatLng(21.521757, -77.781167)],
        ['CV', new LatLng(16.002082, -24.013197)],
        ['CX', new LatLng(-10.447525, 105.690449)],
        ['CY', new LatLng(35.126413, 33.429859)],
        ['CZ', new LatLng(49.817492, 15.472962)],
        ['DE', new LatLng(51.165691, 10.451526)],
        ['DJ', new LatLng(11.825138, 42.590275)],
        ['DK', new LatLng(56.26392, 9.501785)],
        ['DM', new LatLng(15.414999, -61.370976)],
        ['DO', new LatLng(18.735693, -70.162651)],
        ['DZ', new LatLng(28.033886, 1.659626)],
        ['EC', new LatLng(-1.831239, -78.183406)],
        ['EE', new LatLng(58.595272, 25.013607)],
        ['EG', new LatLng(26.820553, 30.802498)],
        ['EH', new LatLng(24.215527, -12.885834)],
        ['ER', new LatLng(15.179384, 39.782334)],
        ['ES', new LatLng(40.463667, -3.74922)],
        ['ET', new LatLng(9.145, 40.489673)],
        ['FI', new LatLng(61.92411, 25.748151)],
        ['FJ', new LatLng(-16.578193, 179.414413)],
        ['FK', new LatLng(-51.796253, -59.523613)],
        ['FM', new LatLng(7.425554, 150.550812)],
        ['FO', new LatLng(61.892635, -6.911806)],
        ['FR', new LatLng(46.227638, 2.213749)],
        ['GA', new LatLng(-0.803689, 11.609444)],
        ['GB', new LatLng(51.50, -0.12)],
        ['GD', new LatLng(12.262776, -61.604171)],
        ['GE', new LatLng(42.315407, 43.356892)],
        ['GF', new LatLng(3.933889, -53.125782)],
        ['GG', new LatLng(49.465691, -2.585278)],
        ['GH', new LatLng(7.946527, -1.023194)],
        ['GI', new LatLng(36.137741, -5.345374)],
        ['GL', new LatLng(71.706936, -42.604303)],
        ['GM', new LatLng(13.443182, -15.310139)],
        ['GN', new LatLng(9.945587, -9.696645)],
        ['GP', new LatLng(16.995971, -62.067641)],
        ['GQ', new LatLng(1.650801, 10.267895)],
        ['GR', new LatLng(37.97, 23.73)],
        ['GS', new LatLng(-54.429579, -36.587909)],
        ['GT', new LatLng(15.783471, -90.230759)],
        ['GU', new LatLng(13.444304, 144.793731)],
        ['GW', new LatLng(11.803749, -15.180413)],
        ['GY', new LatLng(4.860416, -58.93018)],
        ['GZ', new LatLng(31.354676, 34.308825)],
        ['HK', new LatLng(22.396428, 114.109497)],
        ['HM', new LatLng(-53.08181, 73.504158)],
        ['HN', new LatLng(15.199999, -86.241905)],
        ['HR', new LatLng(45.1, 15.2)],
        ['HT', new LatLng(18.971187, -72.285215)],
        ['HU', new LatLng(47.162494, 19.503304)],
        ['ID', new LatLng(-0.789275, 113.921327)],
        ['IE', new LatLng(53.41291, -8.24389)],
        ['IL', new LatLng(31.046051, 34.851612)],
        ['IM', new LatLng(54.236107, -4.548056)],
        ['IN', new LatLng(20.593684, 78.96288)],
        ['IO', new LatLng(-6.343194, 71.876519)],
        ['IQ', new LatLng(33.223191, 43.679291)],
        ['IR', new LatLng(32.427908, 53.688046)],
        ['IS', new LatLng(64.963051, -19.020835)],
        ['IT', new LatLng(41.90, 12.49)],
        ['JE', new LatLng(49.214439, -2.13125)],
        ['JM', new LatLng(18.109581, -77.297508)],
        ['JO', new LatLng(30.585164, 36.238414)],
        ['JP', new LatLng(36.204824, 138.252924)],
        ['KE', new LatLng(-0.023559, 37.906193)],
        ['KG', new LatLng(41.20438, 74.766098)],
        ['KH', new LatLng(12.565679, 104.990963)],
        ['KI', new LatLng(-3.370417, -168.734039)],
        ['KM', new LatLng(-11.875001, 43.872219)],
        ['KN', new LatLng(17.357822, -62.782998)],
        ['KP', new LatLng(40.339852, 127.510093)],
        ['KR', new LatLng(35.907757, 127.766922)],
        ['KW', new LatLng(29.31166, 47.481766)],
        ['KY', new LatLng(19.513469, -80.566956)],
        ['KZ', new LatLng(48.019573, 66.923684)],
        ['LA', new LatLng(19.85627, 102.495496)],
        ['LB', new LatLng(33.854721, 35.862285)],
        ['LC', new LatLng(13.909444, -60.978893)],
        ['LI', new LatLng(47.166, 9.555373)],
        ['LK', new LatLng(7.873054, 80.771797)],
        ['LR', new LatLng(6.428055, -9.429499)],
        ['LS', new LatLng(-29.609988, 28.233608)],
        ['LT', new LatLng(55.169438, 23.881275)],
        ['LU', new LatLng(49.815273, 6.129583)],
        ['LV', new LatLng(56.879635, 24.603189)],
        ['LY', new LatLng(26.3351, 17.228331)],
        ['MA', new LatLng(31.791702, -7.09262)],
        ['MC', new LatLng(43.750298, 7.412841)],
        ['MD', new LatLng(47.411631, 28.369885)],
        ['ME', new LatLng(42.708678, 19.37439)],
        ['MG', new LatLng(-18.766947, 46.869107)],
        ['MH', new LatLng(7.131474, 171.184478)],
        ['MK', new LatLng(41.608635, 21.745275)],
        ['ML', new LatLng(17.570692, -3.996166)],
        ['MM', new LatLng(21.913965, 95.956223)],
        ['MN', new LatLng(46.862496, 103.846656)],
        ['MO', new LatLng(22.198745, 113.543873)],
        ['MP', new LatLng(17.33083, 145.38469)],
        ['MQ', new LatLng(14.641528, -61.024174)],
        ['MR', new LatLng(21.00789, -10.940835)],
        ['MS', new LatLng(16.742498, -62.187366)],
        ['MT', new LatLng(35.937496, 14.375416)],
        ['MU', new LatLng(-20.348404, 57.552152)],
        ['MV', new LatLng(3.202778, 73.22068)],
        ['MW', new LatLng(-13.254308, 34.301525)],
        ['MX', new LatLng(23.634501, -102.552784)],
        ['MY', new LatLng(4.210484, 101.975766)],
        ['MZ', new LatLng(-18.665695, 35.529562)],
        ['NA', new LatLng(-22.95764, 18.49041)],
        ['NC', new LatLng(-20.904305, 165.618042)],
        ['NE', new LatLng(17.607789, 8.081666)],
        ['NF', new LatLng(-29.040835, 167.954712)],
        ['NG', new LatLng(9.081999, 8.675277)],
        ['NI', new LatLng(12.865416, -85.207229)],
        ['NL', new LatLng(52.132633, 5.291266)],
        ['NO', new LatLng(60.472024, 8.468946)],
        ['NP', new LatLng(28.394857, 84.124008)],
        ['NR', new LatLng(-0.522778, 166.931503)],
        ['NU', new LatLng(-19.054445, -169.867233)],
        ['NZ', new LatLng(-40.900557, 174.885971)],
        ['OM', new LatLng(21.512583, 55.923255)],
        ['PA', new LatLng(8.537981, -80.782127)],
        ['PE', new LatLng(-9.189967, -75.015152)],
        ['PF', new LatLng(-17.679742, -149.406843)],
        ['PG', new LatLng(-6.314993, 143.95555)],
        ['PH', new LatLng(12.879721, 121.774017)],
        ['PK', new LatLng(30.375321, 69.345116)],
        ['PL', new LatLng(51.919438, 19.145136)],
        ['PM', new LatLng(46.941936, -56.27111)],
        ['PN', new LatLng(-24.703615, -127.439308)],
        ['PR', new LatLng(18.220833, -66.590149)],
        ['PS', new LatLng(31.952162, 35.233154)],
        ['PT', new LatLng(39.399872, -8.224454)],
        ['PW', new LatLng(7.51498, 134.58252)],
        ['PY', new LatLng(-23.442503, -58.443832)],
        ['QA', new LatLng(25.354826, 51.183884)],
        ['RE', new LatLng(-21.115141, 55.536384)],
        ['RO', new LatLng(45.943161, 24.96676)],
        ['RS', new LatLng(44.016521, 21.005859)],
        ['RU', new LatLng(61.52401, 105.318756)],
        ['RW', new LatLng(-1.940278, 29.873888)],
        ['SA', new LatLng(23.885942, 45.079162)],
        ['SB', new LatLng(-9.64571, 160.156194)],
        ['SC', new LatLng(-4.679574, 55.491977)],
        ['SD', new LatLng(12.862807, 30.217636)],
        ['SE', new LatLng(60.128161, 18.643501)],
        ['SG', new LatLng(1.352083, 103.819836)],
        ['SH', new LatLng(-24.143474, -10.030696)],
        ['SI', new LatLng(46.151241, 14.995463)],
        ['SJ', new LatLng(77.553604, 23.670272)],
        ['SK', new LatLng(48.669026, 19.699024)],
        ['SL', new LatLng(8.460555, -11.779889)],
        ['SM', new LatLng(43.94236, 12.457777)],
        ['SN', new LatLng(14.497401, -14.452362)],
        ['SO', new LatLng(5.152149, 46.199616)],
        ['SR', new LatLng(3.919305, -56.027783)],
        ['ST', new LatLng(0.18636, 6.613081)],
        ['SV', new LatLng(13.794185, -88.89653)],
        ['SY', new LatLng(34.802075, 38.996815)],
        ['SZ', new LatLng(-26.522503, 31.465866)],
        ['TC', new LatLng(21.694025, -71.797928)],
        ['TD', new LatLng(15.454166, 18.732207)],
        ['TF', new LatLng(-49.280366, 69.348557)],
        ['TG', new LatLng(8.619543, 0.824782)],
        ['TH', new LatLng(15.870032, 100.992541)],
        ['TJ', new LatLng(38.861034, 71.276093)],
        ['TK', new LatLng(-8.967363, -171.855881)],
        ['TL', new LatLng(-8.874217, 125.727539)],
        ['TM', new LatLng(38.969719, 59.556278)],
        ['TN', new LatLng(33.886917, 9.537499)],
        ['TO', new LatLng(-21.178986, -175.198242)],
        ['TR', new LatLng(38.963745, 35.243322)],
        ['TT', new LatLng(10.691803, -61.222503)],
        ['TV', new LatLng(-7.109535, 177.64933)],
        ['TW', new LatLng(23.69781, 120.960515)],
        ['TZ', new LatLng(-6.369028, 34.888822)],
        ['UA', new LatLng(48.379433, 31.16558)],
        ['UG', new LatLng(1.373333, 32.290275)],
        ['US', new LatLng(37.09024, -95.712891)],
        ['UY', new LatLng(-32.522779, -55.765835)],
        ['UZ', new LatLng(41.377491, 64.585262)],
        ['VA', new LatLng(41.902916, 12.453389)],
        ['VC', new LatLng(12.984305, -61.287228)],
        ['VE', new LatLng(6.42375, -66.58973)],
        ['VG', new LatLng(18.420695, -64.639968)],
        ['VI', new LatLng(18.335765, -64.896335)],
        ['VN', new LatLng(14.058324, 108.277199)],
        ['VU', new LatLng(-15.376706, 166.959158)],
        ['WF', new LatLng(-13.768752, -177.156097)],
        ['WS', new LatLng(-13.759029, -172.104629)],
        ['XK', new LatLng(42.602636, 20.902977)],
        ['YE', new LatLng(15.552727, 48.516388)],
        ['YT', new LatLng(-12.8275, 45.166244)],
        ['ZA', new LatLng(-30.559482, 22.937506)],
        ['ZM', new LatLng(-13.133897, 27.849332)],
        ['ZW', new LatLng(-19.015438, 29.154857)],
    ])
}
