export class MetricsIds {
  static readonly DYNAMIC_AUTO = 0;
  static readonly CPM = 1;
  static readonly CTR = 2;
  static readonly CPC = 3;
  static readonly VTR = 6;
  static readonly WINRATE = 7;
  static readonly RENDER_RATE = 21;
  static readonly CPS = 37;
  static readonly FILL_RATE = 39;
  static readonly COOKIE_MISSING_FAIL = 40;
  static readonly VIEWABILITY = 43;
  static readonly COST_PER_LANDING = 45;
  static readonly CPA = 53;
  static readonly INTERNAL_SPENT = 59;
  static readonly AVERAGE_CPM = 64;
  static readonly CPA_OVERALL = 70;
  static readonly BIDRESPONSE_RATE = 73;
  static readonly CPV = 86;
  static readonly CPCV = 96;
  static readonly CURRENCY_E_CPM = 101;
  static readonly CURRENCY_CPM_SPENT = 102;
  static readonly INTERNAL_MARGIN_GROSS_OLD = 106;
  static readonly COOKIE_MATCH_RATE_SSP = 107;
  static readonly MISSING_ADS_TXT = 108;
  static readonly INTERNAL_MARGIN_GROSS = 112;
  static readonly PROFIT_PERC = 115;
  static readonly CPM_V = 116;
  static readonly PROFIT_100K = 120;
  static readonly SCREEN_REAL_ESTATE = 121;
  static readonly NEW_CPA = 128;
  static readonly DEAL_RESPONSE_RATE = 129;
  static readonly CURRENCY_AVERAGE_CPM = 131;
}
