import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-loadingpage',
  templateUrl: './loadingPage.component.html',
  styleUrls: ['./loadingPage.component.css'],
})

export class LoadingPageComponent {

  @Input() isHeavy: boolean; // to cover all the screen
  @Input() isReport: boolean;

  constructor() {}

}
