import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GlobalSettings} from '../../GlobalSettings';
import {Observable} from 'rxjs';
import {TableService} from '../services/table.service';
import {BlockList} from '../__helpers/entities/BlockList';
import {BlocklistInsertionResponse} from './entity/BlocklistInsertionResponse';
import {Feature } from 'app/_cerberusMVC/cerberusPublisher/entity/Feature';
import {SelectItem} from 'primeng/api';
import {QualityBlocklistTable} from './qualityBlocklist/entity/QualityBlocklistTable';
import {QualityBlocklistDetail} from './qualityBlocklist/entity/QualityBlocklistDetail';
import {BlocklistDetailResponse} from './qualityBlocklist/entity/BlocklistDetailResponse';
import {BlockDomainDetail} from './_helpers/BlockDomainDetail';

@Injectable()
export class PublisherService {

  private readonly initPath = '/api/v1/publisher'
  private readonly baseURL = GlobalSettings.APIurl + this.initPath;

  private readonly blocklistURL = `${this.baseURL}/blocklist`;
  private readonly ignorelistURL = `${this.baseURL}/ignorelist`;
  private readonly featureURL = `${this.baseURL}/feature`;
  private readonly genericCategoryURL = GlobalSettings.APIurl + '/api/v1/global/category';
  private readonly sensitiveCategoryURL = GlobalSettings.APIurl + '/api/v1/global/sensitiveCategory';

  private readonly INIT_PATH_BLOCKLIST = '/api/v1/blocklist';
  private readonly BASE_URL_BLOCKLIST = GlobalSettings.APIurl + this.INIT_PATH_BLOCKLIST;
  private readonly BLOCKLIST_DETAILS_PATH = this.BASE_URL_BLOCKLIST + '/detail';
  private readonly BLOCKLIST_VALIDATION_PATH = this.BASE_URL_BLOCKLIST + '/validation';
  private readonly BLOCKLIST_UPDATE_PATH = this.BASE_URL_BLOCKLIST + '/update';

  private readonly siteCheckURL = `${this.baseURL}/siteCheck`;

  constructor(private http: HttpClient) { }

  /******************************************** Blocklist brand ****************************************************/
  getBlocklist(myEntity: number): Observable<BlockList[]> {
    const params: any = {};
    if (!TableService.isVoid(myEntity)) { params['entity'] = myEntity; }
    return this.http.get<BlockList[]>(this.blocklistURL, {params: params});
  }

  postBlocklist(myBlocklist: BlockList, myEntity: number): Observable<BlocklistInsertionResponse> {
    const params: any = {};
    if (!TableService.isVoid(myEntity)) { params['entity'] = myEntity; }
    return this.http.post<BlocklistInsertionResponse>(this.blocklistURL, myBlocklist, {params: params});
  }

  deleteBlocklist(blocklistId: number): Observable<{result: BlockList}> {
    const params: any = {};
    if (!TableService.isVoid(blocklistId)) { params['id'] = blocklistId; }
    return this.http.delete<{result: BlockList}>(this.blocklistURL, {params: params});
  }

  getSensitiveCategory(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(this.sensitiveCategoryURL);
  }

  /******************************************** Feature ****************************************************/
  getFeature(): Observable<Feature> {
    return this.http.get<Feature>(this.featureURL);
  }

  /******************************************** Feature ****************************************************/
  getGenericCategory(): Observable<{categoriesAndroid: SelectItem[], categoriesIOS: SelectItem[], categoriesIAB: SelectItem[]}> {
    return this.http.get<{categoriesAndroid: SelectItem[], categoriesIOS: SelectItem[], categoriesIAB: SelectItem[]}>(this.genericCategoryURL);
  }

  postFeature(feature: Feature, entityId: number) {
    const params: any = {};
    if (entityId !== undefined) { params['entityId'] = entityId; }
    return this.http.post<Feature>(this.featureURL, feature, {params: params});
  }

  /******************************************** Ignore list ****************************************************/
  getIgnorelist(myEntity?: number): Observable<string[]> {
    const params: any = {};
    if (!TableService.isVoid(myEntity)) { params['entity'] = myEntity; }
    return this.http.get<string[]>(this.ignorelistURL, {params: params});
  }

  postIgnorelist(myIgnorelist: string[], myEntity?: number): Observable<string> {
    const params: any = {};
    if (!TableService.isVoid(myEntity)) { params['entity'] = myEntity; }
    return this.http.post<string>(this.ignorelistURL, myIgnorelist, {params: params});
  }

  /******************************************** Quality Blocklist ****************************************************/
  getQualityBlocklist(): Observable<QualityBlocklistTable> {
    return this.http.get<QualityBlocklistTable>(this.BASE_URL_BLOCKLIST);
  }

  getQualityBlocklistDetails(myExchange: number, myBlockType: number): Observable<QualityBlocklistDetail> {
    const params: any = {};
    if (!TableService.isVoid(myExchange)) { params['exchange'] = myExchange; }
    if (!TableService.isVoid(myBlockType)) { params['blockType'] = myBlockType; }
    return this.http.get<QualityBlocklistDetail>(this.BLOCKLIST_DETAILS_PATH, {params: params});
  }

  validateQualityBlocklist(list: string[], myExchange: number, myBlockType: number, fieldName: string): Observable<BlocklistDetailResponse> {
    const params: any = {};
    if (!TableService.isVoid(myExchange)) { params['exchange'] = myExchange; }
    if (!TableService.isVoid(myBlockType)) { params['blockType'] = myBlockType; }
    return this.http.post<BlocklistDetailResponse>(this.BLOCKLIST_VALIDATION_PATH, {[fieldName]: list}, {params: params});
  }

  updateQualityBlocklist(myBlocklist: QualityBlocklistDetail, myExchange: number, myBlockType: number): Observable<any> {
    const params: any = {};
    if (!TableService.isVoid(myExchange)) { params['exchange'] = myExchange; }
    if (!TableService.isVoid(myBlockType)) { params['blockType'] = myBlockType; }
    return this.http.post<any>(this.BLOCKLIST_UPDATE_PATH, myBlocklist, {params: params});
  }

  /******************************************** Search block site ****************************************************/
  siteCheck(idDomain?: number): Observable<BlockDomainDetail> {
    const params: any = {};
    if (!TableService.isVoid(idDomain)) { params['id'] = idDomain; }
    return this.http.get<BlockDomainDetail>(this.siteCheckURL, {params: params});
  }


}
