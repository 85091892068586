import { NgModule } from '@angular/core';
import { SortablejsDirective } from './ngx-sortablejs/sortablejs.directive';
import { SortablejsModule } from './ngx-sortablejs/sortablejs.module';
import { SortablejsService } from './ngx-sortablejs/sortablejs.service';


@NgModule({
  imports: [
    SortablejsModule
  ],
  exports: [
    SortablejsDirective
  ],
  providers: [
    SortablejsService
  ]
})
export class OnetagComponentModule { }
