
<div *ngIf="stack.length !== 0" class="main-container" [@container]>
  <div class="overlay" [@overlay]></div>
  <div class="side-panels" (click)="this.onClick($event)">
    <ng-container *ngFor="let panel of stack; let i = index;">
      <div class="side-panel"
           [@panel]
           (@panel.start)="onPanelAnimationStart.bind(this, panel)($event)"
           (@panel.done)="onPanelAnimationEnd.bind(this, panel)($event)"
      >
        <div class="stacker" [@stacker]="(stack.length - 1 - i).toString()">
          <div class="content">
            <ng-container *ngTemplateOutlet="panel.content"></ng-container>
          </div>
          <div *ngIf="i !== stack.length - 1" class="overlay" [@overlay]></div>
          <div class="filler"></div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
