import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs/Observable";
import {UserData} from "../entities/UserData";
import {environment} from "../../../environments/environment";
import {GlobalSettings} from "../../../GlobalSettings";
import {AppConfig} from "../entities/AppConfig";
import {JwtPermissions} from "../jwtPermissions.service";
import {map} from "rxjs/operators";

/**
 *
 * Service utilizzata per la gestione dei feature flags di AppConfig
 *
 * Può essere utilizzato con due modalità:
 *
 * 1. Metodo polling -> viene richamato il metodo appConfigPolling() in un componente sempre attivo (come la navbar)
 * e poi per la lettura delle chiavi si utilizza il metodo getFlag(name: string): boolean, esempio di utlizzo:
 *
 * console.log('Enabled: ', this.appConfigService.getFlag(AppConfigEnum.ENABLED));
 *
 *
 * 2. Metodo runtime -> viene richiamato il metodo getFlagRuntime(name: string) e lui si incarica di chiamare il server
 * e resituire il valore della chiave (se esistente), esempio di utilizzo:
 *
 * async ngOnInit() {
 *   const enabled = await this.appConfigService.getFlagRuntime(AppConfigEnum.ENABLED);
 *   console.log('Enabled: ', enabled);
 * }
 *
 * N.B. codificare le chiavi dei feature flags in AppConfigEnum
 *
 */
@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private readonly APP_CONFIG_STORAGE = 'onetag_appconfig_storage';
  private readonly appConfigUrl = GlobalSettings.APIV2url + environment.appConfigRefreshUrl;

  constructor(private http: HttpClient) {}

  /**
   * Metodo che gestice il polling al server per l'aggiornamento dell'array dei feature flags
   *
   *
   */
  appConfigPolling() {
    this.getAppConfigState().subscribe(res => {
      this.setFlags(res);

      // setTimeout(() => {
      setInterval(() => {
        this.getAppConfigState().subscribe(res => {
          this.setFlags(res);
        }, err => {
          console.log("Error in appConfigPolling: ", err);
        });
      }, environment.appConfigPollingTime);
    }, err => {
      console.log("Error in first appConfigPolling: ", err);
    });
  }

  /**
   * Metodo che chiama il server e recupera la lista dei feature flags
   *
   */
  getAppConfigState(name?: string): Observable<any> {
    let url = this.appConfigUrl;
    url += name ? '/' + name : '';

    return this.http.get<any>(url, {
      headers: new HttpHeaders({
        'X-API-KEY': environment.appConfigXApiKey
      })
    });
  }

  /**
   * Metodo che scrive l'array dei feature flags sul local storage
   *
   * @param flags
   */
  setFlags(flags) {
    localStorage.setItem(this.APP_CONFIG_STORAGE, JSON.stringify(flags));
  }

  /**
   * Metodo che legge l'array dei feature flags dal local storage e recupera il valore della chiave passata nel campo name
   * restituisce null se non viene trovata la chiave
   *
   * @param name
   */
  getFlag(name: string): boolean {
    let ret = null;

    const flags: AppConfig[] = JSON.parse(localStorage.getItem(this.APP_CONFIG_STORAGE));
    for (const f of flags) {
      if (f.name === name) {
        ret = f.enable;
        break;
      }
    }

    return ret;
  }

  /**
   * Metodo per la richiesta puntuale di un feature flag
   * Miglioramento: endpoint a cui si passa il name
   *
   * @param name
   */
  async getFlagRuntime(name: string) {
    const flag: AppConfig = await this.getAppConfigState(name).toPromise();
    if (flag && flag.enable) {
      return flag.enable;
    }
    return null;
  }
}
