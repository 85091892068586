import {SelectItem} from 'primeng/api';

export class CurrencyHelper {
  static readonly currencyEUR = 'EUR';
  static readonly currencyUSD = 'USD';
  static readonly currencyGBP = 'GBP';

  static eurIcon = '€ ';
  static dollarIcon = '$ ';
  static gbpIcon = '£ '

  public static currencyArray: SelectItem[] = [
    {value: CurrencyHelper.currencyEUR, label: '€ (EUR)', icon: CurrencyHelper.eurIcon},
    {value: CurrencyHelper.currencyUSD, label: '$ (USD)', icon: CurrencyHelper.dollarIcon},
    ];

  public static currencyArrayWithGBP: SelectItem[] = [
    {value: CurrencyHelper.currencyEUR, label: '€ (EUR)', icon: CurrencyHelper.eurIcon},
    {value: CurrencyHelper.currencyUSD, label: '$ (USD)', icon: CurrencyHelper.dollarIcon},
    {value: CurrencyHelper.currencyGBP, label: '£ (GBP)', icon: CurrencyHelper.gbpIcon}
  ];


  static currencyValue: string; // EUR/ USD
  static curr: string; // Simbolo della currency (€/$)

  static loadCurrency(myCurrency: string) {
    CurrencyHelper.currencyValue = myCurrency;
    const tempCurr = CurrencyHelper.currencyArray.find(elem => elem.value === myCurrency);
    if (tempCurr) {
      CurrencyHelper.currencyValue = tempCurr.value;
      CurrencyHelper.curr = tempCurr.icon;
    }
  }

  static getIconFromValue(value: string): string {
    const myelem = CurrencyHelper.currencyArray.find(elem => elem.value === value);
    if (myelem) {
      return myelem.icon;
    }
  }
}
