import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorManager } from 'app/__helpers/errorManager';
import { GlobalSettings } from 'GlobalSettings';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TargetingService {

  private readonly getSuggestedTopicsURL = GlobalSettings.APIurl + '/api/v1/targeting/semanticTopic';
  private readonly getRelatedTopcisURL = GlobalSettings.APIurl + '/api/v1/targeting/relatedTopic';

  constructor(private http: HttpClient) {}

  getSemanticTopics(lang: string, keyword?: string, wikidata_id?: string): Observable<any> {
      const params: any = {};
      if (keyword !== null) {
          params['keyword'] = keyword;
      }
      if (wikidata_id !== null) {
        params['wikidata_id'] = wikidata_id;
      }
      if (lang !== null) {
          params['lang'] = lang;
      }

      return this.http.get<any>(this.getSuggestedTopicsURL, {params: params}).catch(ErrorManager.handleError);
  }

  getRelatedTopics(wikidata_id: string, lang: string) {
    const params: any = {};
    if (wikidata_id !== null) {
        params['wikidata_id'] = wikidata_id;
    }
    if (lang !== null) {
        params['lang'] = lang;
    }

    return this.http.get<any>(this.getRelatedTopcisURL, {params: params}).catch(ErrorManager.handleError);
  }
}
