/**
 * @Pipe
 * @description pipe to get symbol from metric
 * @input <number>metric, <string>currency
 * @output sum
 */

import { Pipe, PipeTransform } from '@angular/core';
import {CerConstants} from '../../../cerberusUtility/CerConstants';
import {UtilsFun} from '../../../../__helpers/entities/UtilsFun';
import {CurrencyHelper} from '../../../../__helpers/entities/CurrencyHelper';

@Pipe({
  name: 'metricSymbol'
})
export class MetricSymbolPipe implements PipeTransform {
  transform(myMetric: number, myCurrency): string {
    let myResult = CerConstants.CURRENCY_METRIC_MAP.get(myMetric);
    if (myResult === '€') {
      const myElement = UtilsFun.getElementFromChoice(myCurrency, CurrencyHelper.currencyArray);
      if (myElement) {
        myResult = myElement.icon;
      }
    }
    return myResult;
  }
}
