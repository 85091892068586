import {ErrorHandler, Injectable} from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  handleError(error: any): void {
    // Gestione errato caricamento lazy loading
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    const expressionChangeFailedMessage = /ExpressionChangedAfterItHasBeenCheckedError/;
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
      return;
    } else {
      if (expressionChangeFailedMessage.test(error.message)) {return; }
      console.error(error);
    }
  }
}
