import {Constants} from '../entities/Constants';

export class DateFun {

  static readonly MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  /**
   * Prende in input la data stringa nel formato DD/MM/YYYY e restituisce la data
   * @param dateStr
   * @param lastMoment
   */
  public static getDateFromString(dateStr: string, lastMoment?: boolean): Date {
    if (dateStr) {
      const parts = dateStr.split('/');
      const date = new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10));
      if (lastMoment) {
        date.setHours(23, 59, 59, 0);
      }
      return date;
    }
  }

  /**
   * Prende in input la data stringa nel formato DD/MM/YYYY HH:MM e restituisce la data
   * @param dateStr
   */
  private static getDateFromCompleteString(dateStr: string): Date {
    if (dateStr) {
      const parts = dateStr.split(' ');
      if (parts[0] && parts[1]) {
        const myDate = parts[0].split('/');
        const myTime = parts[1].split(':');
        return new Date(parseInt(myDate[2], 10), parseInt(myDate[1], 10) - 1, parseInt(myDate[0], 10),
          parseInt(myTime[0], 10), parseInt(myTime[1], 10));
      }
    }
  }

  /**
   * Prende in input la data e restituisce la data stringa nel formato YYYY-MM-DD
   * @param d
   */
  public static getStringYYYYMMDDFromDate(d: Date): string {
    return [d.getFullYear(), DateFun.pad(d.getMonth() + 1), DateFun.pad(d.getDate())].join('-')
  }

  /**
   * Prende in input la data e restituisce la data stringa nel formato DD/MM/YYYY
   * @param d
   */
  public static getStringFromDate(d: Date): string {
    return [DateFun.pad(d.getDate()), DateFun.pad(d.getMonth() + 1), d.getFullYear()].join('/')
  }

  /**
   * Prende in input la data e restituisce la data stringa nel formato DD/MM/YYYY HH:MM
   * @param d
   */
  public static getCompleteStringFromDate(d: Date): string {
    if (d) {
      return [DateFun.pad(d.getDate()), DateFun.pad(d.getMonth() + 1), d.getFullYear()].join('/') + ' ' + [DateFun.pad(d.getHours()), DateFun.pad(d.getMinutes())].join(':');
    }
  }

  /**
   * Prende una data di tipo dd/mm/aaaa e la converte in timestamp
   * @param dateStr
   * @param lastMoment
   */
  public static stringToTimestamp(dateStr: string, lastMoment?: boolean): number {
    return dateStr ? DateFun.getDateFromString(dateStr, lastMoment).getTime() : undefined;
  }

  /**
   * Prende una data di tipo dd/mm/aaaa hh:mm e la converte in timestamp
   * @param dateStr
   */
  public static completeStringToTimestamp(dateStr: string): number {
    return dateStr ? DateFun.getDateFromCompleteString(dateStr).getTime() : undefined;
  }

  /**
   * Prende una data di tipo dd/mm/aaaa e la converte nel mese abbreviato
   * @param dateStr
   */
  public static getMonthNameFromString(dateStr: string): string {
    if (dateStr) {
      const myMonth = DateFun.getDateFromString(dateStr).getMonth();
      if (myMonth >= 0 && myMonth < 12) {
        return DateFun.MONTH_NAMES[myMonth];
      }
    }
  }

  /** Prende in input la data nel formato MMDDYYYY e il carattere di split ('/','-',':' ecc)
   * e restituisce la data nel formato DDMMYYY nel tipo Date
   */
  public static getDDMMYYYYDateFormat(date: string, split: string): Date {
    const parts = date.split(split);
    return new Date(parseInt(parts[2], 10),
      parseInt(parts[1], 10) - 1,
      parseInt(parts[0], 10));
  }

  /**
   * Restituisce true se si tratta della data odierna
   * @param someDate data di input
   */
  public static isToday(someDate: Date): boolean {
    const today = new Date();
    return someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear();
  }

  /**
   * Aggiunge lo 0 alle date quando è necessario
   * @param val input
   */
  public static pad(val: number) {
    return (val < 10) ? '0' + val : val;
  }

  /**
   * Restituisce il budget speso giornaliero
   * @param myBudget: number
   * @param startDate: string
   * @param endDate string
   * @output dailySpent: number
   */
  public static getDailySpent(myBudget: number, startDate: string, endDate: string): number {
    if (!myBudget || !startDate || !startDate.split(' ') || !endDate || !endDate.split(' ')) {
      return undefined;
    }
    const startOnlyDate = startDate.split(' ')[0];
    const endOnlyDate = endDate.split(' ')[0];
    return (DateFun.getDailyPrenoSpent(myBudget, startOnlyDate, endOnlyDate));
  }

  /**
   * Restituisce il budget speso giornaliero preno
   * @param myBudget: number
   * @param startDate: string
   * @param endDate string
   * @output dailySpent: number
   */
  public static getDailyPrenoSpent(myBudget: number, startDate: string, endDate: string): number {
    if (!myBudget || !startDate || !endDate) {
      return undefined;
    }
    return (myBudget / Math.round(((DateFun.completeStringToTimestamp(endDate + ' 24:00') - DateFun.stringToTimestamp(startDate + ' 00:00')) / (3600 * 24 * 1000))));
  }

  /**
   * Prende una data di tipo mm/aaaa e la converte nel nome mese
   * @param date
   */
  public static getMonthLabel(date: string): string {
    if (date && date.length >= 7) {
      const month = date.slice(0, 2);
      if (month) {
        const result = Constants.monthsLabels[parseInt(month, 10) - 1] + ' ' + date.slice(3, 7);
        if (result) {
          return result;
        }
      }
    }
    return date;
  }
}
