import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RegistrationUser } from 'app/__helpers/entities/RegistrationUser';
import { UserData } from 'app/__helpers/entities/UserData';
import { GlobalSettings } from 'GlobalSettings';
import { Observable } from 'rxjs';
import {ResponseInfo} from '../__helpers/entities/ResponseInfo';
import {EntityAccount} from '../__helpers/entities/EntityAccount';
import {CerFun} from '../_cerberusMVC/cerberusUtility/cerFun';
import {ObFormBody} from '../_publicMVC/openBiddingSignup/_helpers/ObFormBody';
import {Column} from '../__helpers/entities/Column';

@Injectable()
export class UserService {

  private readonly userPath = GlobalSettings.APIurl + '/api/v1/user';
  private readonly userNonApiPath = GlobalSettings.APIurl + '/v1/user';
  private readonly registrationPath = this.userNonApiPath + '/register';
  private readonly registrationObPath = this.userNonApiPath + '/registerOb';
  private readonly accountObPath = this.userNonApiPath + '/account';
  private readonly newUserPath = this.userNonApiPath;
  private readonly buyersListURL = this.userPath + '/buyer';
  private readonly getReportBuyersURL =  GlobalSettings.APIurl + '/api/v1/report/buyer/management';
  private readonly adminCreationURL = this.userPath + '/admin';
  private readonly referrerCreationURL = this.userPath + '/referrer';
  private readonly curatorCreationURL = this.userPath + '/curator';

  constructor(private http: HttpClient) {}

  getUserDetail(idUser?: number, key?: string): Observable<UserData> {
    let params: any;
    let path: string;
    if (key) {
      path = this.userNonApiPath;
      params = {key: key};
    } else if (idUser) {
      path = this.userPath;
      params = {idUser: idUser.toString()};
    } else {
      return null;
    }

    return this.http.get<UserData>(path, {params: params});
  }

  postNewUser(userAccount: UserData, verificationCode: string): Observable<any> {
    const params = {
      verificationCode: verificationCode
    }
    return this.http.post<any>(this.newUserPath, userAccount, {params: params});
  }

  createAdmin(userAccount: UserData): Observable<UserData> {
    return this.http.post<UserData>(this.adminCreationURL, userAccount);
  }

  createReferrer(userAccount: UserData): Observable<UserData> {
    return this.http.post<UserData>(this.referrerCreationURL, userAccount);
  }

  createCurator(userAccount: UserData): Observable<UserData> {
    return this.http.post<UserData>(this.curatorCreationURL, userAccount);
  }

  createNewUser(userAccount: UserData, idEntity: number): Observable<UserData> {
    const params: any = {};
    if (!CerFun.isVoid(idEntity)) { params['idEntity'] = idEntity.toString(); }
    return this.http.post<UserData>(this.userPath, userAccount, {params: params });
  }

  updateUserDetail(userAccount: UserData): Observable<UserData> {
    return this.http.put<UserData>(this.userPath, userAccount);
  }

  registration(registrationUser: RegistrationUser, registrationType: string, key: string, verificationCode?: string): Observable<ResponseInfo> {
    let params: any;
    if (verificationCode) {
      params = {
        key: key,
        registrationType: registrationType,
        verificationCode: verificationCode
      }
    } else {
      params = {
        key: key,
        registrationType: registrationType
      }
    }
    return this.http.post<ResponseInfo>(this.registrationPath, registrationUser, {params: params });
  }

  registrationOb(registrationUser: ObFormBody, verificationCode?: string): Observable<string> {
    let params: any;
    params = {verificationCode: verificationCode};
    return this.http.post<string>(this.registrationObPath, registrationUser, {params: params });
  }

  getAccountFromMail(myOtLogin: {username: string, password: string}): Observable<ObFormBody> {
    return this.http.post<ObFormBody>(this.accountObPath, myOtLogin);
  }

  getBuyersList(entity?: number, status?: number, issues?: number[]): Observable<EntityAccount[]> {
    const params: any = {};
    if (!CerFun.isVoid(entity)) { params['entity'] = entity.toString(); }
    if (!CerFun.isVoid(status)) { params['status'] = status.toString(); }
    if (!CerFun.isVoidArray(issues)) { params['issues'] = issues.toString(); }
    return this.http.get<EntityAccount[]>(this.buyersListURL, {params: params});
  }

  getReport(myPeriod: number, myCols?: Column[], status?: number, issues?: number[], timezone?: string): Observable<any[]> {
    const params: any = {};
    if (myPeriod !== undefined) { params['period'] = myPeriod.toString()}
    if (!CerFun.isVoid(status)) { params['status'] = status.toString(); }
    if (!CerFun.isVoidArray(issues)) { params['issues'] = issues.toString(); }
    if (!CerFun.isVoid(timezone)) { params['timezone'] = timezone.toString(); }
    return this.http.post<any[]>(this.getReportBuyersURL, {cols: myCols}, {params: params});
  }
}
