import {Injectable} from '@angular/core';
import {SelectItem, TreeNode} from 'primeng/api';
import {GlobalSettings} from '../../GlobalSettings';
import {Advertiser} from 'app/__helpers/entities/Advertiser';
import {Site} from '../__helpers/entities/Domains';
import {Segment} from '../__helpers/entities/Segment';
import {TranslateService} from '@ngx-translate/core';
import {LineItem} from '../__helpers/entities/LineItem';
import {BillTable} from '../__helpers/entities/BillTable';
import {SocialPage} from '../__helpers/entities/SocialPage';
import {SegmentTypeElement} from '../__helpers/entities/SegmentTypeElement';
import {Integration} from '../__helpers/entities/Integration';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import 'rxjs/add/operator/catch';
import {PublisherLight} from '../__helpers/entities/PublisherLight';
import {SegmentPath} from '../__helpers/entities/SegmentPath';
import {ErrorManager} from '../__helpers/errorManager';
import { SiteInsertionResponse } from 'app/_publisherMVC/entity/SiteInsertionResponse';
import {ConversionElement} from '../_segmentsMVC/_helpers/ConversionElement';
import {UserData} from '../__helpers/entities/UserData';

@Injectable()
export class TableService {

  static segmentLimited = false;

  static translate: TranslateService;

  static loading: boolean;

  /******************************************************** Url Advertiser Call ***************************************/
  private readonly getAdvertisersURL = GlobalSettings.APIurl + '/api/getAdvertiserList';
  private readonly addAdvertiserURL = GlobalSettings.APIurl + '/api/addAdvertiser';
  private readonly modifyAdvertiserURL = GlobalSettings.APIurl + '/api/modifyAdvertiser';
  private readonly deleteAdvertiserURL = GlobalSettings.APIurl + '/api/deleteAdvertiser';

  /***************************************************** Url Exchange Call *********************************************/
  private readonly getCompleteExchangeURL = GlobalSettings.APIurl + '/api/getExchangeList';

  /************************************************ Url Dfp LineItem Call *********************************************/
  private readonly getMyLineItemsURL = GlobalSettings.APIurl + '/api/getLineItems';
  private readonly addLineItemURL = GlobalSettings.APIurl + '/api/addLineItem';
  private readonly modifyLineItemURL = GlobalSettings.APIurl + '/api/modifyLineItem';
  private readonly deleteLineItemURL = GlobalSettings.APIurl + '/api/deleteLineItem';
  private readonly getLineItemSizesURL = GlobalSettings.APIurl + '/api/getLineItemSizes';
  private readonly getDfpNetworkCodesURL = GlobalSettings.APIurl + '/api/getDfpNetworkCodes';
  private readonly verifyDfpNetworkCodeURL = GlobalSettings.APIurl + '/api/verifyDfpNetworkCode';
  private readonly verifyDfpAdUnitsURL = GlobalSettings.APIurl + '/api/verifyDfpAdUnits';

  /******************************************************* Url Domain Call ********************************************/

  private readonly sitesURL = GlobalSettings.APIurl + '/api/v1/publisher/site';
  private readonly siteApprovationURL = GlobalSettings.APIurl + '/api/siteApprovation';
  private readonly getPrincipalIABCategoriesURL = GlobalSettings.APIurl + '/api/getPrincipalIABCategories';
  private readonly getChildIABCategoriesURL = GlobalSettings.APIurl + '/api/getChildIABCategories';
  private readonly approveAllSitesURL = GlobalSettings.APIurl + '/api/manager/approveAllSites';

  /*************************************************** Url Integrations Call ********************************************/
  private readonly getIntegrationsDataURL =  GlobalSettings.APIurl + '/api/manager/getIntegrationsData';
  private readonly addIntegrationURL = GlobalSettings.APIurl + '/api/manager/addIntegration';
  private readonly getProtocolsURL = GlobalSettings.APIurl + '/api/manager/getProtocols';
  private readonly addIntegrationSSPURL = GlobalSettings.APIurl + '/api/addIntegrationSSP';

  /*************************************************** Url Pages Social Call ******************************************/
  private readonly getSocialPageURL = GlobalSettings.APIurl + '/api/getSocialPage';
  private readonly addSocialPageURL = GlobalSettings.APIurl + '/api/addSocialPage';
  private readonly requestSocialPageURL = GlobalSettings.APIurl + '/api/requestSocialPage';

  /******************************************************** Url Preno Call ********************************************/
  private readonly getPrenoURL = GlobalSettings.APIurl + '/api/listPreno';

  /****************************************************** Url Segment Call ********************************************/
  private readonly getSegmentsURL = GlobalSettings.APIurl + '/api/getSegmentsList';
  private readonly newUserSegmentURL = GlobalSettings.APIurl + '/api/newUserSegment';
  private readonly getFunnelListURL = GlobalSettings.APIurl + '/api/getFunnelList';
  private readonly addSegmentURL = GlobalSettings.APIurl + '/api/addSegment';
  private readonly addFunnelURL = GlobalSettings.APIurl + '/api/addFunnel';
  private readonly setStatusSegmentsURL = GlobalSettings.APIurl + '/api/segmentStatus';
  private readonly modifySegmentsURL = GlobalSettings.APIurl + '/api/modifySegment';
  private readonly deleteSegmentURL = GlobalSettings.APIurl + '/api/deleteSegment';
  private readonly getSegmentsTypesURL = GlobalSettings.APIurl + '/api/getSegmentsTypes';
  private readonly getSegmentCountersURL = GlobalSettings.APIurl + '/api/report/getSegmentCounters';
  private readonly getTreeConversionURL = GlobalSettings.APIurl + '/api/v1/segment/admin/conversion';
  private readonly getConversionURL = GlobalSettings.APIurl + '/api/v1/segment/conversion';
  private readonly getActivationURL = GlobalSettings.APIurl + '/api/v1/activation';


  advertChoice: SelectItem[];
  industriesChoice: SelectItem[];

  static isVoid(elem: any): boolean {
    return elem === undefined || elem === null || elem === '';
  }

  static isVoidArray(elem: any): boolean {
    return elem === undefined || elem.length === 0;
  }

  static isJSON(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  static getModalWidth(): number {
    if (window.innerWidth < 500) {
      return 320;
    }
    return 600;
  }

  static getMediaWidth(): number {
    if (window.innerWidth < 800) {
      return window.innerWidth - 30;
    }
    return 800;
  }

  static getTotalWidth(): number {
    return window.innerWidth - 40;
  }

  constructor(private translate: TranslateService, private http: HttpClient) {
    TableService.translate = translate;
  }

  /**************************************************** Get functions *************************************************/
  /** Queste chiamate salvano in TableService le scelte globali utilizzate da più componenti **/

  getAdverts() {
    this.getAdvertisers().subscribe(res => {
      this.advertChoice = res.map(el => ({label: el.name, value: el.id}));
    });
  }

  /************************************************ Advertiser ********************************************************/

  getAdvertisers(entity?: number[]): Observable<Advertiser[]> {
    const params: any = {};
    if (entity && entity.length > 0) { params['entity'] = entity.toString(); }
    return this.http.get<Advertiser[]>(this.getAdvertisersURL, {params: params}).catch(ErrorManager.handleError);
  }

  addAdvertiser(advert: Advertiser): Observable<Advertiser> {
    const params: any = {};
    if (advert.entityId) { params['entity'] = advert.entityId.toString(); }
    return this.http.post<Advertiser>(this.addAdvertiserURL, advert, {params: params}).catch(ErrorManager.handleError);
  }

  modifyAdvertiser(advert: Advertiser, inline?: number): Observable<Advertiser> {
    const params: any = {};
    if (inline) { params['inline'] = inline.toString(); }
    return this.http.post<Advertiser>(this.modifyAdvertiserURL, advert, {params: params}).catch(ErrorManager.handleError);
  }

  deleteAdvertiser(id: number): Observable<any> {
    const params: any = {};
    if (id !== undefined) { params['id'] = id.toString(); }
    return this.http.post(this.deleteAdvertiserURL, '', {params: params}).catch(ErrorManager.handleError);
  }

  /***************************************************** Exchange *****************************************************/

  getCompleteExchange(type?: number, active?: number): Observable<SelectItem[]> {
    const params: any = {};
    if (type !== undefined) { params['type'] = type.toString(); }
    if (active !== undefined) { params['active'] = active.toString(); }
    return this.http.get<SelectItem[]>(this.getCompleteExchangeURL, {params: params}).catch(ErrorManager.handleError);
  }

  /************************************************* Dfp LineItem *****************************************************/

  getMyLineItems(): Observable<LineItem[]> {
    return this.http.get<LineItem[]>(this.getMyLineItemsURL).catch(ErrorManager.handleError);
  }

  addLineItem(lineItem: LineItem): Observable<any> {
    return this.http.post<LineItem>(this.addLineItemURL, lineItem).catch(ErrorManager.handleError);
  }

  modifyLineItem(lineItem): Observable<LineItem> {
    return this.http.post<LineItem>(this.modifyLineItemURL, lineItem).catch(ErrorManager.handleError);
  }

  deleteLineItem(id: number): Observable<any> {
    const params: any = {};
    if (id !== undefined) { params['id'] = id.toString(); }
    return this.http.post(this.deleteLineItemURL, '', {params: params}).catch(ErrorManager.handleError);
  }

  getLineItemSizes(param: number): Observable<SelectItem[]> {
    const params: any = {};
    if (param !== undefined) { params['inventoryType'] = param.toString(); }
    return this.http.get<SelectItem[]>(this.getLineItemSizesURL, {params: params});
  }

  getDfpNetworkCodes(): Observable<number[]> {
    return this.http.get<number[]>(this.getDfpNetworkCodesURL);
  }

  verifyDfpNetworkCode(networkCode: string): Observable<void | number> {
    const params: any = {};
    if (!TableService.isVoid(networkCode)) { params['networkCode'] = networkCode; }
    return this.http.get<number>(this.verifyDfpNetworkCodeURL, {params: params});
  }

  verifyDfpAdUnits(list: string[], networkCode): Observable <void | string[]> {
    const params: any = {};
    if (!TableService.isVoid(networkCode)) { params['networkCode'] = networkCode.toString(); }
    return this.http.post<string[]>(this.verifyDfpAdUnitsURL, {array: list}, {params: params});
  }

  /***************************************************** Domain-Site ******************************************************/

  getSites(entity?: number, type?: number): Observable<Site[]> {
    const params: any = {};
    if (!TableService.isVoid(entity)) { params['entity'] = entity; }
    if (!TableService.isVoid(type)) { params['type'] = type; }
    return this.http.get<Site[]>(this.sitesURL, {params: params});
  }

  getSitesFromEntities(entityIds?: number[], type?: number): Observable<Site[]> {
    const params: any = {};
    if (!TableService.isVoid(entityIds)) { params['entityIds'] = entityIds.toString(); }
    if (!TableService.isVoid(type)) { params['type'] = type; }
    return this.http.get<Site[]>(this.sitesURL, {params: params});
  }

  getPaginationSites(event: any, entity?: number, type?: number, status?: number): Observable<any> {
    const params: any = {};
    if (!TableService.isVoid(entity)) { params['entity'] = entity; }
    if (!TableService.isVoid(type)) { params['type'] = type; }
    if (!TableService.isVoid(status)) { params['status'] = status; }
    for (const key in event) {
      if (key !== 'filters' && key !== 'multiSortMeta') {
        if (event[key] !== null && event[key] !== undefined) {
          params[key.toString()] = event[key];
        }
      }
    }

    return this.http.get<any>(this.sitesURL, {params: params});
  }

  postMultipleSites(list: string[], type: number, user_id?: number): Observable<SiteInsertionResponse> {
    const elem: {sites: string[], owner_id?: number, type?: number} = {sites: list};
    elem.type = type;
    if (user_id) { elem.owner_id = user_id; }
    return this.http.post<SiteInsertionResponse>(this.sitesURL, elem).catch(ErrorManager.handleError);
  }

  modifySite(site: Site): Observable<{result: Site}> {
    return this.http.put<{result: Site}>(this.sitesURL, site);
  }

  deleteSite(id: number): Observable<any> {
    const params: any = {};
    if (id !== undefined) { params['id'] = id.toString(); }
    return this.http.delete(this.sitesURL, {params: params}).catch(ErrorManager.handleError);
  }

  siteApprovation(id: number, approvalType: number): Observable<any> {
    const params: any = {};
    if (id !== undefined) { params['id'] = id.toString(); }
    if (approvalType !== undefined) { params['type'] = approvalType.toString(); }
    return this.http.post(this.siteApprovationURL, '', {params: params}).catch(ErrorManager.handleError);
  }

  getPrincipalIABCategories(allCategory?: boolean): Observable<SelectItem[]> {
    const params: any = {};
    if (allCategory) { params['all'] = '1'; }
    return this.http.get<SelectItem[]>(this.getPrincipalIABCategoriesURL, {params: params});
  }

  approveAllSites(myEntity: number): Observable<any> {
    const params: any = {};
    if (!TableService.isVoid(myEntity)) { params['entity'] = myEntity; }
    return this.http.post(this.approveAllSitesURL, {}, {params: params});
  }

  getChildIABCategories(id: number): Observable<SelectItem[]> {
    const params: any = {};
    if (id !== undefined) { params['id'] = id.toString(); }
    return this.http.get<SelectItem[]>(this.getChildIABCategoriesURL, {params: params});
  }

  /**************************************************** Integrations *******************************************************/

  getIntegrations(): Observable<Integration[]> {
    return this.http.get<Integration[]>(this.getIntegrationsDataURL).catch(ErrorManager.handleError);
  }

  addIntegration(integration: Integration): Observable<Integration> {
    return this.http.post<Integration>(this.addIntegrationURL, integration).catch(ErrorManager.handleError);
  }

  getProtocols(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(this.getProtocolsURL);
  }

  addIntegrationSSP(syncPixel: string, publisher?: PublisherLight): Observable<any> {
    const params: any = {};
    if (publisher && !publisher.isMaster) {
      if (publisher.entityId !== undefined) { params['entity'] = publisher.entityId.toString(); }
    }
    return this.http.post(this.addIntegrationSSPURL, {syncPixel: syncPixel}, {params: params}).catch(ErrorManager.handleError);
  }

  /***************************************************** Owner **********************************************************/

  getSocialPage(): Observable<SocialPage[]> {
    return this.http.get<SocialPage[]>(this.getSocialPageURL).catch(ErrorManager.handleError);
  }

  addSocialPage(socialPage: SocialPage): Observable<SocialPage> {
    return this.http.post<SocialPage>(this.addSocialPageURL, socialPage).catch(ErrorManager.handleError);
  }

  requestSocialPage(idPage: string): Observable<any> {
    const params: any = {};
    if (idPage !== undefined) { params['pageId'] = idPage.toString(); }
    return this.http.get(this.requestSocialPageURL, {params: params});
  }

  /***************************************************** Preno **********************************************************/

  getPrenoList(interval?: number): Observable<BillTable[]> {
    const params: any = {};
    if (interval !== undefined) { params['interval'] = interval.toString(); }
    return this.http.get<BillTable[]>(this.getPrenoURL, {params: params}).catch(ErrorManager.handleError);
  }

  /**************************************************** Segment *******************************************************/

  getSegments(language?: number, advertId?: number, typeId?: number, isGlobal?: boolean, entity?: number): Observable<Segment[]> {
    const params: any = {};
    if (entity !== undefined) { params['entity'] = entity.toString(); }
    if (language !== undefined) { params['lang'] = language.toString(); }
    if (advertId !== undefined) { params['advertiser'] = advertId.toString(); }
    if (typeId !== undefined) { params['type'] = typeId.toString(); }
    if (isGlobal) { params['global'] = '1'; }
    return this.http.get<Segment[]>(this.getSegmentsURL, {params: params});
  }

  newUserSegment(language?: number, advertId?: number): Observable<{value: number, label: string, category: string[]}[]> {
    const params: any = {};
    if (language !== undefined) { params['lang'] = language.toString(); }
    if (advertId !== undefined) { params['advertiser'] = advertId.toString(); }
    return this.http.get<{value: number, label: string, category: string[]}[]>(this.newUserSegmentURL, {params: params});
  }

  getFunnelList(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(this.getFunnelListURL);
  }

  addSegment(segm: Segment): Observable<Segment | SegmentPath> {
    return this.http.post<Segment | SegmentPath>(this.addSegmentURL, segm).catch(ErrorManager.handleError);
  }

  addFunnel(name: string): Observable<SelectItem> {
    return this.http.post<SelectItem>(this.addFunnelURL, {label: name}).catch(ErrorManager.handleError);
  }

  setStatusSegments(id: number, command: number): Observable<any> {
    return this.http.post(this.setStatusSegmentsURL, {id: id, command: command}).catch(ErrorManager.handleError);
  }

  modifySegment(segm: Segment | SegmentPath): Observable<Segment | SegmentPath> {
    return this.http.post<Segment | SegmentPath>(this.modifySegmentsURL, segm).catch(ErrorManager.handleError);
  }

  deleteSegment(id: number): Observable<string> {
    const params: any = {};
    if (id !== undefined) { params['id'] = id.toString(); }
    return this.http.post(this.deleteSegmentURL, '', {params: params}).catch(ErrorManager.handleError);
  }

  getSegmentsTypes(language: number): Observable<SegmentTypeElement[]> {
    const params: any = {};
    if (language !== undefined) { params['lang'] = language.toString(); }
    return this.http.get<SegmentTypeElement[]>(this.getSegmentsTypesURL, {params: params});
  }

  getSegmentCounters(ids: number[]): Observable<{ id: number, segment_load?: string, unique_users?: string }[]> {
    return this.http.post<{ id: number, segment_load: string }[]>(this.getSegmentCountersURL, {ids: ids});
  }

  getConversion(id: number, idAdvertiser: number, type: number, period: number, minDate: string, maxDate: string, isAdminReportForGaming: boolean): Observable<ConversionElement[]> {
    const params: any = {};
    if (id !== undefined) { params['segment'] = id.toString(); }
    if (idAdvertiser !== undefined) { params['advertiser'] = idAdvertiser.toString(); }
    if (type !== undefined) { params['type'] = type.toString(); }
    if (period !== undefined) { params['period'] = period.toString(); }
    if (minDate !== undefined) { params['minDate'] = minDate; }
    if (maxDate !== undefined) { params['maxDate'] = maxDate; }
    if (isAdminReportForGaming) { params['report'] = 1; }
    return this.http.get<ConversionElement[]>(this.getConversionURL, {params: params});
  }

  getTreeConversion(id: number, father: number): Observable<TreeNode[]> {
    const params: any = {};
    if (id !== undefined) { params['segment'] = id.toString(); }
    if (father !== undefined) { params['ipFather'] = father.toString(); }
    return this.http.get<{data: TreeNode[]}>(this.getTreeConversionURL, {params: params}).map(res => res.data);
  }

  getActivations(campaignId?: number): Observable<any[]> {
    const params: any = {};
    if (campaignId !== undefined) { params['campaignId'] = campaignId.toString(); }
    return this.http.get<any[]>(this.getActivationURL, {params: params});
  }

}
