import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GlobalSettings} from '../../GlobalSettings';
import {Observable} from 'rxjs';
import {CerCampaign} from './cerberusBuyer/cerEntity/CerCampaign';
import {CreativeGroup} from './cerberusBuyer/cerEntity/CretiveGroup';
import {CerMedia} from './cerberusBuyer/cerEntity/CerMedia';
import {CampaignService} from '../services/campaign.service';
import {SelectItem} from 'primeng/api';
import {CerConstants} from './cerberusUtility/CerConstants';
import { CerListElement } from 'app/_cerberusMVC/cerberusBuyer/cerEntity/CerListElement';
import {CerReport} from './cerberusBuyer/cerEntity/CerReport';
import {Advertiser} from '../__helpers/entities/Advertiser';
import {AudienceBuilder} from '../__helpers/entities/AudienceBuilder';
import {SemplifiedAudience} from './cerberusBuyer/cerEntity/semplifiedAudience';
import { AudienceReportModel } from 'app/_cerberusMVC/cerberusBuyer/cerEntity/AudienceReportModel';
import {CerPreno} from './cerberusBuyer/cerEntity/CerPreno';
import {StrategyWeb} from './cerberusBuyer/cerEntity/StrategyWeb';
import { UtilsFun } from 'app/__helpers/entities/UtilsFun';
import {AdGroup} from './cerberusBuyer/cerEntity/AdGroup';
import {CerFun} from './cerberusUtility/cerFun';
import { Column } from 'app/__helpers/entities/Column';
import { ErrorManager } from 'app/__helpers/errorManager';
import {Activities} from './cerberusBuyer/cerEntity/Activities';
import {isNumeric} from 'rxjs/internal-compatibility';

@Injectable()
export class CerCampaignService {

  static NATIVE_ON_BANNER_CHOICE: SelectItem[] = [];
  static VIDEO_ON_BANNER_CHOICE: SelectItem[] = [];

  static TARGETING_COUNTER = -1;

  static readonly CAMPAIGN_FOR_CALL = 'campaign';
  static readonly ADGROUP_FOR_CALL = 'strategy';
  static readonly SUBSTRATEGY_FOR_CALL = 'ad';

  private readonly getCampaignCerberusURL = GlobalSettings.APIurl + '/api/getCampaignDetailCerberus';
  private readonly creativeGroupListURL = GlobalSettings.APIurl + '/api/creativeGroupList';
  private readonly createCampaignURL = GlobalSettings.APIurl + '/api/createNewCampaignCerberus';
  private readonly ioModifyInlineURL = GlobalSettings.APIurl + '/api/ioModifyInline';
  private readonly clonePrenoURL = GlobalSettings.APIurl + '/api/clonePreno';
  private readonly deletePrenoURL = GlobalSettings.APIurl + '/api/deletePreno';
  private readonly buildSubStrategyURL = GlobalSettings.APIurl + '/api/buildSubStrategy';
  private readonly setCampaignStatusURL = GlobalSettings.APIurl + '/api/adUnitStatus';
  private readonly campaignsIssueURL = GlobalSettings.APIurl + '/api/campaignsIssue';
  private readonly campaignCloneCerberusURL = GlobalSettings.APIurl + '/api/campaignCloneCerberus';
  private readonly getCampaignsListCerberusURL = GlobalSettings.APIurl + '/api/getCampaignsListCerberus';
  private readonly getStrategiesListCerberusURL = GlobalSettings.APIurl + '/api/getStrategiesListCerberus';
  private readonly getSubStrategiesListCerberusURL = GlobalSettings.APIurl + '/api/getSubStrategiesListCerberus';
  private readonly reportTableListURL = GlobalSettings.APIurl + '/api/report/reportTableList';
  private readonly addNewCreativeURL = GlobalSettings.APIurl + '/api/addNewCreative';
  private readonly getBannerSizeURL = GlobalSettings.APIurl + '/api/getBannerSize';
  private readonly getAdvertiserURL = GlobalSettings.APIurl + '/api/getAdvertiser';
  private readonly saveAudienceURL = GlobalSettings.APIurl + '/api/saveAudience';
  private readonly saveShortAudienceURL = GlobalSettings.APIurl + '/api/saveSimpleAudience';
  private readonly getAudienceURL = GlobalSettings.APIurl + '/api/getAudience';
  private readonly audienceInsightURL = GlobalSettings.APIurl + '/api/audienceInsight';
  private readonly saveCreativeGroupURL = GlobalSettings.APIurl + '/api/saveCreativeGroup';
  private readonly creativeGroupCloneURL = GlobalSettings.APIurl + '/api/creativeGroupClone';
  private readonly creativeGroupDeleteURL = GlobalSettings.APIurl + '/api/creativeGroupDelete';
  private readonly creativeGroupArchiveURL = GlobalSettings.APIurl + '/api/v1/buyer/archiveCreativeGroup';
  private readonly creativeArchiveURL = GlobalSettings.APIurl + '/api/v1/buyer/archiveCreative';
  private readonly activityPollingURL = GlobalSettings.APIurl + '/api/v1/buyer/campaign/activity';
  private readonly getOldIoURL = GlobalSettings.APIurl + '/api/v1/campaign/cerberusPreno';
  private readonly getPrenoListURL = GlobalSettings.APIurl + '/api/v1/campaign/preno';

  constructor(private http: HttpClient, private campaignService: CampaignService) { }


  /******************************************** Dashboard Campagne ****************************************************/
  getCampaignsListCerberus(status?: number, source?: number, entity?: number, advertiser?: number): Observable<CerListElement[]> {
    const params: any = {};
    if (!CerFun.isVoid(status)) { params['status'] = status.toString(); }
    if (!CerFun.isVoid(source)) { params['source'] = source.toString(); }
    if (!CerFun.isVoid(entity)) { params['entity'] = entity.toString(); }
    if (!CerFun.isVoid(advertiser)) { params['advertiser'] = advertiser.toString(); }
    return this.http.get<CerListElement[]>(this.getCampaignsListCerberusURL, {params: params});
  }

  getStrategiesListCerberus(status?: number, source?: number, entity?: number, advertiser?: number, campaign?: number, insertionOrder?: number): Observable<CerListElement[]> {
    const params: any = {};
    if (!CerFun.isVoid(status)) { params['status'] = status.toString(); }
    if (!CerFun.isVoid(source)) { params['source'] = source.toString(); }
    if (!CerFun.isVoid(entity)) { params['entity'] = entity.toString(); }
    if (!CerFun.isVoid(advertiser)) { params['advertiser'] = advertiser.toString(); }
    if (!CerFun.isVoid(campaign)) { params['campaign'] = campaign.toString(); }
    if (!CerFun.isVoid(insertionOrder)) { params['insertionOrder'] = insertionOrder.toString(); }
    return this.http.get<CerListElement[]>(this.getStrategiesListCerberusURL, {params: params});
  }

  getSubStrategiesListCerberus(status?: number, source?: number, entity?: number, advertiser?: number, campaign?: number, insertionOrder?: number): Observable<CerListElement[]> {
    const params: any = {};
    if (!CerFun.isVoid(status)) { params['status'] = status.toString(); }
    if (!CerFun.isVoid(source)) { params['source'] = source.toString(); }
    if (!CerFun.isVoid(entity)) { params['entity'] = entity.toString(); }
    if (!CerFun.isVoid(advertiser)) { params['advertiser'] = advertiser.toString(); }
    if (!CerFun.isVoid(campaign)) { params['campaign'] = campaign.toString(); }
    if (!CerFun.isVoid(insertionOrder)) { params['insertionOrder'] = insertionOrder.toString(); }
    return this.http.get<CerListElement[]>(this.getSubStrategiesListCerberusURL, {params: params});
  }

  // aggiorna lo status campagna
  setCampaignStatus(id: number, type: string, status: number): Observable<any> {
    const el: any = {id: id, type: type, command: status};
    return this.http.post(this.setCampaignStatusURL, el).catch(ErrorManager.handleError);
  }

  // cancella campagna
  deleteCampaign(id: number, type: string): Observable<any> {
    return this.http.post(this.setCampaignStatusURL, {id: id, type: type, deleted: 1}).catch(ErrorManager.handleError);
  }

  // cancella campagna
  getCampaignsIssue(): Observable<CerListElement[]> {
    return this.http.get<CerListElement[]>(this.campaignsIssueURL);
  }

  campaignCloneCerberus(myId: number, duplicateForm: {inserionOrder: number, start: string, end: string, budget: number}): Observable<{id: number}> {
    const params: any = {};
    if (myId !== undefined) { params['id'] = myId.toString() }
    return this.http.post<{id: number}>(this.campaignCloneCerberusURL, duplicateForm, {params: params});
  }

  /*********************************************** Reports ************************************************************/

  getReport(myPeriod: number, myType: number, myCols: Column[], fatherParam?: any): Observable<any[]> {
    const myReport: CerReport = {};
    myReport.periodId = myPeriod;
    myReport.type = myType;
    myReport.cols = myCols;
    return this.reportTableList(myReport, fatherParam);
  }

  /**
   * Chiamata report
   */
  reportTableList(myReport: CerReport, fatherParam?: any): Observable<any[]> {
    return this.http.post<any[]>(this.reportTableListURL, myReport, {params: fatherParam});
  }

  /*********************************************** Nuova Campagna ****************************************************/
  loadCerInitCampaign(myChannel: number) {
    this.campaignService.getCoordsInfo();
    if (myChannel === CerConstants.CHANNEL_WEB) {
      this.getBannerSize(CerConstants.FORMAT_VIDEO).subscribe(res => {
        CerCampaignService.VIDEO_ON_BANNER_CHOICE = res;
      });
      this.getBannerSize(CerConstants.FORMAT_NATIVE).subscribe(res => {
        CerCampaignService.NATIVE_ON_BANNER_CHOICE = res;
      });
      this.campaignService.loadInitCampaign();
    }
  }

  getCampaignDetails(campaignId: number): Observable<CerCampaign> {
    const params: any = {};
    if (!CerFun.isVoid(campaignId)) { params['id'] = campaignId.toString(); }
    return this.http.get<CerCampaign>(this.getCampaignCerberusURL, {params: params});
  }

  creativeGroupList(advertiserId: number, campaignId?: number): Observable<{creativeGroups: CreativeGroup[]}> {
    const params: any = {};
    if (!CerFun.isVoid(advertiserId)) { params['advertiser'] = advertiserId.toString(); }
    if (!CerFun.isVoid(campaignId)) { params['campaign'] = campaignId.toString(); }
    return this.http.get<{creativeGroups: CreativeGroup[]}>(this.creativeGroupListURL, {params: params});
  }

  clonePreno(myPreno: CerPreno): Observable<CerPreno> {
    return this.http.post<CerPreno>(this.clonePrenoURL, myPreno);
  }

  deletePreno(idPreno: number): Observable<CerPreno> {
    const params: any = {};
    if (!CerFun.isVoid(idPreno)) { params['idPreno'] = idPreno; }
    return this.http.delete<CerPreno>(this.deletePrenoURL, {params: params});
  }

  // Prepara l'oggetto da inviare (Mette nell'oggetto campagna )
  buildSubStrategy(myCampaign: CerCampaign, myPreno: CerPreno, myAdgroup: AdGroup): Observable<StrategyWeb[]> {
    const tempCampaign: CerCampaign = UtilsFun.cloneField(myCampaign);
    const tempPreno: CerPreno = UtilsFun.cloneField(myPreno);
    tempPreno.adGroups = [myAdgroup];
    tempCampaign.insertionOrder = [tempPreno];
    return this.buildSubStrategyCall(tempCampaign);
  }

  // crea nuova campagna
  buildSubStrategyCall(campaign: CerCampaign): Observable<StrategyWeb[]> {
    return this.http.post<StrategyWeb[]>(this.buildSubStrategyURL, campaign);
  }

  // crea nuova campagna
  buildSubAllStrategy(campaign: CerCampaign): Observable<CerPreno[]> {
    const params: any = {type: 'updateAll'};
    return this.http.post<CerPreno[]>(this.buildSubStrategyURL, campaign, {params: params});
  }

  // crea nuova campagna
  createNewCampaign(campaign: CerCampaign): Observable<number> {
    return this.http.post<number>(this.createCampaignURL, campaign);
  }

  modifyPrenoInline(idPreno: number, lightIO: CerPreno): Observable<any> {
    return this.http.post(this.ioModifyInlineURL, lightIO);
  }

  /**
   * Modifica una creativeGroup
   * @param creativeGroup
   * @param myCampaignId
   */
  saveCreativeGroup(creativeGroup: CreativeGroup, myCampaignId: number): Observable<CreativeGroup> {
    const params: any = {};
    if (isNumeric(myCampaignId)) { params['campaignId'] = myCampaignId.toString() }
    return this.http.post<CreativeGroup>(this.saveCreativeGroupURL, creativeGroup, {params: params}).catch(ErrorManager.handleError);
  }

  creativeGroupClone(myId: number): Observable<CreativeGroup> {
    const params: any = {};
    if (myId !== undefined) { params['id'] = myId.toString() }
    return this.http.post<CreativeGroup>(this.creativeGroupCloneURL, '', {params: params});
  }

  /**
   * Tabella del pannello laterale nuova campagna
   */
  getAudienceInsight(idMainEvent: number): Observable<AudienceReportModel[]> {
    const params: any = {};
    if (idMainEvent) { params['typSegment'] = idMainEvent.toString(); }
    return this.http.get<AudienceReportModel[]>(this.audienceInsightURL, {params: params});
  }

  /**
   * Elimina un creativeGroup
   * @param id
   */
  creativeGroupDelete(id: number): Observable<CreativeGroup> {
    const params: any = {};
    if (!CerFun.isVoid(id)) { params['id'] = id.toString(); }
    return this.http.post(this.creativeGroupDeleteURL, null, {params: params}).catch(ErrorManager.handleError);
  }

  /**
   * @param idCG
   * @param idCampaign
   * @param archiveAction
   */
  creativeGroupArchive(idCG: number, idCampaign: number, archiveAction: number): Observable<CreativeGroup> {
    const params: any = {};
    if (!CerFun.isVoid(idCG)) { params['idCreativeGroup'] = idCG.toString(); }
    if (!CerFun.isVoid(idCampaign)) { params['idCampaign'] = idCampaign.toString(); }
    if (!CerFun.isVoid(archiveAction)) { params['archive'] = archiveAction.toString(); }
    return this.http.post(this.creativeGroupArchiveURL, null, {params: params}).catch(ErrorManager.handleError);
  }

  /**
   * @param idCreative
   * @param idCampaign
   * @param archiveAction
   */
  creativeArchive(idCreative: number, idCampaign: number, archiveAction: number): Observable<CreativeGroup> {
    const params: any = {};
    if (!CerFun.isVoid(idCreative)) { params['idCreative'] = idCreative.toString(); }
    if (!CerFun.isVoid(idCampaign)) { params['idCampaign'] = idCampaign.toString(); }
    if (!CerFun.isVoid(archiveAction)) { params['archive'] = archiveAction.toString(); }
    return this.http.post(this.creativeArchiveURL, null, {params: params}).catch(ErrorManager.handleError);
  }

  showArchivedCreativeGroups(idCampaign: number): Observable<{creativeGroups: CreativeGroup[]}> {
    const params: any = {};
    if (!CerFun.isVoid(idCampaign)) { params['idCampaign'] = idCampaign.toString(); }
    return this.http.get<{creativeGroups: CreativeGroup[]}>(this.creativeGroupArchiveURL, {params: params});
  }

  showArchivedCreatives(idCampaign: number, idCreativeGroup: number): Observable<CreativeGroup[]> {
    const params: any = {};
    if (!CerFun.isVoid(idCampaign)) { params['idCampaign'] = idCampaign.toString(); }
    if (!CerFun.isVoid(idCreativeGroup)) { params['idCreativeGroup'] = idCreativeGroup.toString(); }
    return this.http.get<CreativeGroup[]>(this.creativeArchiveURL, {params: params});
  }

  /**
   * Aggiunge una creative come nuovo CreativeGroup
   * @param creative
   */
  addNewCreative(creative: CerMedia): Observable<CreativeGroup> {
    return this.http.post<CreativeGroup>(this.addNewCreativeURL, creative).catch(ErrorManager.handleError);
  }

  activityPolling(myActivity: Activities): Observable<Activities> {
    return this.http.post<Activities>(this.activityPollingURL, myActivity);
  }

  stopActivityPolling(myActivity: Activities): Observable<any> {
    return this.http.put<any>(this.activityPollingURL, myActivity);
  }

  getBannerSize(format: number): Observable<SelectItem[]> {
    const params: any = {};
    if (!CerFun.isVoid(format)) { params['format'] = format.toString(); }
    return this.http.get<SelectItem[]>(this.getBannerSizeURL, {params: params});
  }

  getAdvertiser(id: number): Observable<Advertiser> {
    const params: any = {};
    if (id) { params['id'] = id.toString(); }
    return this.http.get<Advertiser>(this.getAdvertiserURL, {params: params});
  }

  saveAudience(audience: AudienceBuilder): Observable<AudienceBuilder> {
    const params: any = {};
    return this.http.post<AudienceBuilder>(this.saveAudienceURL, audience, {params: params});
  }

  saveShortAudience(audience: SemplifiedAudience): Observable<SemplifiedAudience> {
    const params: any = {};
    return this.http.post<SemplifiedAudience>(this.saveShortAudienceURL, audience, {params: params});
  }

  getAudience(advertiser_id): Observable<AudienceBuilder> {
    const params: any = {};
    if (!CerFun.isVoid(advertiser_id)) { params['idAdvertiser'] = advertiser_id.toString(); }
    return this.http.get<AudienceBuilder>(this.getAudienceURL, {params: params});
  }

  getOldInsertionOrder(prenoId: number): Observable<CerPreno> {
    const params: any = {};
    if (!CerFun.isVoid(prenoId)) { params['id'] = prenoId.toString(); }
    return this.http.get<CerPreno>(this.getOldIoURL, {params: params});
  }

  getPrenoList(idCampaign: number): Observable<{endDate: string, prenoId: number, startDate: string}[]> {
    const params: any = {};
    if (!CerFun.isVoid(idCampaign)) { params['idCampaign'] = idCampaign.toString(); }
    return this.http.get<{endDate: string, prenoId: number, startDate: string}[]>(this.getPrenoListURL, {params: params});
  }

}
