
<div [class.hideSmallmodalSidebarContent]="!notHideContentSmall">
  <ng-content></ng-content>
</div>

<div class="savePanel">
  <div class="savePanelMessage">{{stateMessage}}</div>
  <button *ngIf="!noCancel" class="otp-btn default submitSideButtons marginRightIcon" type="button" (click)="onCancel()">{{cancelLabel  || 'Cancel'}}</button>
  <button class="otp-btn success submitSideButtons" [disabled]="isSubmitDisabled" type="submit" (click)="onSubmit()" id="group-sidebar-save-button">{{submitLabel || 'Done'}}</button>
</div>
