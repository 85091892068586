<ng-container *ngIf="isEventBusLoaded()">
  <ng-container *ngIf="isLoggedIn()">
    <app-navbar [shortView]="isNewCampaign"></app-navbar>
    <app-sidebar [shortView]="isNewCampaign"></app-sidebar>
  </ng-container>
  <router-outlet *ngIf="!infoService.changingLang"></router-outlet>
</ng-container>

<!--<ng-container *ngIf="isDev && !TableService.loading && isEventBusLoaded() === false">-->
<!--  <div class="err-container">-->
<!--    <img src="./assets/imgs/logo_quadrato.png" alt="OT logo" height="94" width="100"/>-->
<!--    <div>System is currently down.</div>-->
<!--    <div>Working on it, stay tuned.</div>-->
<!--    <div>Contact dev team for details.</div>-->
<!--  </div>-->
<!--</ng-container>-->

<!------------------------------------------------Modal "avviso di errore"--------------------------------------------->

<p-dialog [(visible)]="!!ErrorManager.myError" [modal]="true" [style]="{width: 350 + 'px'}">
  <p-header>
    {{'GLOBAL.ERROR' | translate}}
  </p-header>
  <div class="dialog-body">
    {{ErrorManager.myError}}
  </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix">
      <button class="otp-btn danger" (click)="ErrorManager.myError = undefined"><i class="fal fa-check"></i> Ok</button>
    </div>
  </p-footer>
</p-dialog>


<!------------------------------------------------------------------Alert/Messaggi------------------------------------------------------>

<app-loadingpage *ngIf="TableService.loading"></app-loadingpage>
<app-loadingpage *ngIf="myHeavyLoading" [isHeavy]="true"></app-loadingpage>
<p-toast [style]="{marginTop: '80px'}"></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
