import { Pipe, PipeTransform } from '@angular/core';
import {NotificationBarElement} from './notificationBarElement';

@Pipe({
  name: 'firstIfNotEmpty'
})
export class FirstIfNotEmptyPipe implements PipeTransform {
  transform(firstArray: NotificationBarElement[], secondArray: NotificationBarElement[]): NotificationBarElement[] {
    if (firstArray && firstArray.length > 0) { return firstArray}
    return secondArray;
  }
}
