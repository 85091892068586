/*
styles
  "decimal" for plain number formatting.
  "currency" for currency formatting.
  "percent" for percent formatting
  "unit" for unit formatting
  */
export enum StyleFormat {
  decimal= 'decimal',
  currency= 'currency',
  percent= 'percent',
  unit= 'unit'
}

