import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidePanelComponent } from './sidePanel.component';
import { SidePanelStackComponent } from './sidePanelStack/sidePanelStack.component';

@NgModule({
  declarations: [
    SidePanelComponent,
    SidePanelStackComponent,
  ],
  exports: [
    SidePanelStackComponent,
    SidePanelComponent
  ],
  imports: [
    CommonModule
  ]
})
export class SidePanelModule { }
