/**
 * @Pipe
 * @description pipe to format numeric values to argentina readable currency values
 * @input number
 * @output formatted numeric value
 */

import { Pipe, PipeTransform } from '@angular/core';
import {ItemsElements} from '../entities/ItemsElement';
import {PageContent} from '../entities/PageContent';

@Pipe({
  name: 'containsUrl'
})
export class ContainsUrlPipe implements PipeTransform {
  transform( url: string, item: ItemsElements, myElements: PageContent): boolean {
    if (url) {
      const baseUrl = url.split('?')[0];
      if (item && item.subItems && item.subItems.length > 0) {
        if (item.subItems.some(elem => '/' + elem.link === baseUrl)) {
          return true;
        }
      }
    }
    return false;
  }
}

