<div *ngIf="!isAdmin() && isPublisher() && haveToShowPaymentProfileAlert(entityInfo)" class="message-notification message-alert-notification">
  <i class="fal fa-exclamation-triangle yellowColor marginRightIcon"></i>
  <span>Please make sure to update your <a [routerLink]="'/' + Constants.LINK_MANAGER_PART + '/' + Constants.LINK_PAYMENT_PROFILE">Payment Profile</a> with your accurate tax and bank wire information.</span>
</div>

<ng-container *ngIf="(currentPreview | firstIfNotEmpty: myBarList) as barList">
  <ng-container *ngIf="barList?.length > 0">
    <ng-container *ngFor="let myBar of barList; let i=index">
      <ng-container *ngIf="(MAP_NOTIFIC_CLASS | getElValue: myBar.communicationType) as colorClass">
        <div class="message-notification {{colorClass.label}} w3-hide-small">
          <i [class]="colorClass.icon" style="margin-right: 5px"></i>
          <span class="body-notification-text">{{myBar.communicationBody}}</span>
          <a class="notification-dismiss" (click)="removeMyBar(i, barList)"><i class="fal fa-times-circle"></i> Dismiss</a>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
