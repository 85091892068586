import {environment} from './environments/environment';
import {UtilsFun} from './app/__helpers/entities/UtilsFun';

export class GlobalSettings {
  // private static myAddressPinkTree = 'http://217.182.134.42:9003';
  // private static myAddressPink = 'http://217.182.134.42:9002';
  // private static myAddressBlue = 'http://217.182.134.39:9001';
  private static myAddress0 = 'http://frontend-0.dev.platform.svc.fra.onetag';
  private static myAddress1 = 'http://frontend-1.dev.platform.svc.fra.onetag';
  private static myAddress2 = 'http://frontend-2.dev.platform.svc.fra.onetag';
  private static myAddress3 = 'http://frontend-3.dev.platform.svc.fra.onetag';

  private static gitAddress1 = 'http://dev-01.platform-dev.svc.fra.onetag';
  private static gitAddress2 = 'http://dev-02.platform-dev.svc.fra.onetag';
  private static gitAddress3 = 'http://dev-03.platform-dev.svc.fra.onetag';
  private static gitAddress4 = 'http://dev-04.platform-dev.svc.fra.onetag';

  private static springLocal = 'http://localhost:8080';

  private static developmentV1 = 'https://api-dev.onetag.com';
  private static developmentV2 = 'https://api-v2-dev.onetag.com';
  // private static development = 'https://api-dev.crserv.net';

  private static localhost = 'http://localhost:9090';
  private static myAddress = GlobalSettings.developmentV1;
  private static myAddressV2 = GlobalSettings.developmentV2;

  public static APIurl = !environment.local ? GlobalSettings.getProductionUrl() : GlobalSettings.myAddress; // Url non di test
  public static APIV2url = !environment.local ? GlobalSettings.getProductionUrl(true) : GlobalSettings.myAddressV2; // Url non di test
  public static CDNUrl = environment.production ? 'https://onetag-cdn.com/media' : 'https://test.onetag-cdn.com/media';
  public static S3Url = environment.production ? 'https://awsplatformstack-platform6d260a32-irc8c8k8f2cr.s3.eu-central-1.amazonaws.com/' : 'https://awsplatformstack-platform6d260a32-15oas0pcwndp6.s3.eu-central-1.amazonaws.com/';
  public static readonly RENDERERESCRIPT_BASEURL = 'https://onetag-sys.com/static';
  public static readonly debug: boolean = !environment.production;

  private static getProductionUrl(v2?: boolean) {
    if (UtilsFun.matchDestinationTest()) {
      return 'https://api-test.onetag.com';
    }
    if (UtilsFun.matchDestinationDev()) {
      return v2 ? GlobalSettings.developmentV2 : this.developmentV1;
    }
    if (environment.sandbox) {
      return v2 ? 'https://api-v2-sandbox.onetag.com' : 'https://api-sandbox.onetag.com';
    }
    return v2 ? 'https://api-v2.onetag.com' : 'https://api.onetag.com';
  }
}
