import {Injectable} from '@angular/core';
import {GlobalSettings} from '../../../GlobalSettings';
import {Observable} from 'rxjs';
import {ErrorManager} from '../../__helpers/errorManager';
import {HttpClient} from '@angular/common/http';
import {Approval} from '../../__helpers/entities/Approval';
import {ApprovalUser} from '../../__helpers/entities/ApprovalUser';
import {CerFun} from '../../_cerberusMVC/cerberusUtility/cerFun';
import { ApproveRequest } from 'app/__helpers/entities/ApproveRequest';

@Injectable()
export class ApprovalService {

  /******************************************************** Url Approval Call *****************************************/
  private readonly getApprovalURL = GlobalSettings.APIurl + '/api/manager/getApproval';
  private readonly manageApprovalURL = GlobalSettings.APIurl + '/api/manager/approve';

  /******************************************************** Url Approval User Call *****************************************/
  private readonly getUserToApproveURL = GlobalSettings.APIurl + '/api/v1/user/approveList';
  private readonly approveUserURL = GlobalSettings.APIurl + '/api/approveUser';

  /***************************************************** Url Approval Creative Call *****************************************/
  private readonly approveCreativeURL = GlobalSettings.APIurl + '/api/manager/approveCreative';

  constructor(private http: HttpClient) {}


  /*********************************************  Approval Campaigns **************************************************/

  getApproval(): Observable<Approval[]> {
    return this.http.get<Approval[]>(this.getApprovalURL).catch(ErrorManager.handleError);
  }

  manageApproval(id: number, approval: number, rejectReason?: string): Observable<any> {
    const params: any = {};
    if (id !== undefined) { params['id'] = id }
    if (approval !== undefined) { params['approval'] = approval }
    if (rejectReason !== undefined) { params['rejectReason'] = rejectReason }
    return this.http.post(this.manageApprovalURL, params).catch(ErrorManager.handleError);
  }

  getPublisherApproval(): Observable<Approval[]> {
    return this.http.get<Approval[]>(this.getApprovalURL).catch(ErrorManager.handleError);
  }

  managePublisherApproval(id: number, approval: number): Observable<any> {
    return this.http.post(this.manageApprovalURL, {id: id, approval: approval}).catch(ErrorManager.handleError);
  }

  /************************************************  Approval User ****************************************************/

  getUserToApprove(type, status): Observable<ApprovalUser[]> {
    const params: any = {};
    if (!CerFun.isVoid(type)) { params['type'] = type.toString(); }
    if (!CerFun.isVoid(status)) { params['status'] = status.toString(); }
    return this.http.get<ApprovalUser[]>(this.getUserToApproveURL, {params: params}).catch(ErrorManager.handleError);
  }

  approveUser(id: number, approval: number, currentBill: {paymentDays?: number, tech_fee?: number, filename?: string}): Observable<any> {
    const elem: any = {id: id, approval: approval};
    if (currentBill) {
      if (!CerFun.isVoid(currentBill.paymentDays)) { elem.paymentDays = currentBill.paymentDays}
      if (!CerFun.isVoid(currentBill.tech_fee)) { elem.tech_fee = currentBill.tech_fee}
      if (!CerFun.isVoid(currentBill.filename)) { elem.filename = currentBill.filename}
    }
    return this.http.post(this.approveUserURL, elem).catch(ErrorManager.handleError);
  }

  approvePublisher(approveRequest: ApproveRequest): Observable<any> {
    return this.http.post(this.approveUserURL, approveRequest).catch(ErrorManager.handleError);
  }

  uploadContract(id: number, contract_file?: string, entityId?: number): Observable<any> {
    const elem: any = {id: id};
    if (contract_file !== undefined) { elem.filename = contract_file; }
    if (entityId !== undefined) { elem.entityId = entityId; }
    return this.http.post(this.approveUserURL, elem).catch(ErrorManager.handleError);
  }

  /*********************************************  Approval Creatives **************************************************/

  approveCreative(id: number, approval: number): Observable<any> {
    return this.http.post(this.approveCreativeURL, {id: id, approval: approval}).catch(ErrorManager.handleError);
  }

}
