import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GlobalSettings} from '../../../GlobalSettings';
import {Observable} from 'rxjs/Observable';
import {ErrorManager} from '../../__helpers/errorManager';
import {NotificationBarElement} from './_helpers/notificationBarElement';

@Injectable()
export class CommunicationBarService {

  static currentPreview: NotificationBarElement[];

  private readonly communicationBarURL = GlobalSettings.APIurl + '/api/v1/communicationBar';

  constructor(private http: HttpClient) { }

  getNotifications(): Observable<NotificationBarElement[]> {
    return this.http.get<NotificationBarElement[]>(this.communicationBarURL).catch(ErrorManager.handleError);
  }

  addNotification(myNotification: NotificationBarElement): Observable<NotificationBarElement> {
    return this.http.post<NotificationBarElement>(this.communicationBarURL, myNotification);
  }

  uploadNotification(myNotification: NotificationBarElement): Observable<NotificationBarElement> {
    return this.http.post<NotificationBarElement>(this.communicationBarURL, myNotification);
  }

  statusNotification(id: number, status: number): Observable<NotificationBarElement> {
    const el: any = {id: id, status: status};
    return this.http.put(this.communicationBarURL, el).catch(ErrorManager.handleError);
  }

  deleteNotification(notificId: number): Observable<any> {
    const params: any = { idCommunication: notificId };
    return this.http.delete(this.communicationBarURL, { params: params }).catch(ErrorManager.handleError);
  }
}
