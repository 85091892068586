import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {InfoService} from '../../services/info.service';
import {Constants} from '../entities/Constants';
import {JwtPermissions} from '../jwtPermissions.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private infoService: InfoService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.infoService.elements && this.router.url === '/' + Constants.LINK_SIGNUP ||
      this.router.url === '/' + Constants.LINK_SIGNUP + '/' + Constants.LINK_SIGNUP_BUYER ||
      this.router.url === '/' + Constants.LINK_SIGNUP + '/' + Constants.LINK_SIGNUP_PUBLISHER ||
      this.router.url.startsWith('/' + Constants.LINK_INVITE_PUBLISHER) || // Si impalla la pagina di invite
      this.router.url.startsWith('/' + Constants.LINK_INVITE_GOOGLE_PUBLISHER) || // Si impalla la pagina di invite
      this.router.url.startsWith('/' + Constants.LINK_INVITE_ADDITIONAL_USER) ||
      this.router.url === '/' + Constants.LINK_INVITATION ||
      this.router.url === '/' + Constants.LINK_PASS_RECOVERY) {
      return false;
    }
    if (JwtPermissions.api) {
      return true;
    }
    this.router.navigate(['/' + Constants.LINK_LOGIN], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
