export const environment = {
  production: false,
  local: false,
  sandbox: false,
  versionCheckURL: 'version.json',
  clientId: 'go9q1oasm8d51sp67v9aiqm6o',
  urlSso: 'https://adminplatformonetagdev.auth.eu-central-1.amazoncognito.com/oauth2/authorize?client_id={$CLIENTID}&response_type=code&scope=openid+profile+aws.cognito.signin.user.admin&redirect_uri={$MYURL}/login-sso',
  netsuiteVendor: 'https://9294064-sb1.app.netsuite.com/app/common/entity/vendor.nl?id={$ID}',
  netsuitePO: 'https://9294064-sb1.app.netsuite.com/app/accounting/transactions/purchord.nl?id={$ID}&whence=',
  netsuiteJE: 'https://9294064-sb1.app.netsuite.com/app/accounting/transactions/journal.nl?id={$ID}&whence=',
  appConfigPollingTime: 60000,
  appConfigXApiKey: 'ba38edb4-72f6-4d43-878e-2cc0e8185720',
  appConfigRefreshUrl: '/internal/feature-flags'
};
