import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {CreativeGroup} from '../cerberusBuyer/cerEntity/CretiveGroup';
import {CerPreno} from '../cerberusBuyer/cerEntity/CerPreno';
import {CerCampaign} from '../cerberusBuyer/cerEntity/CerCampaign';
import {StrategyWeb} from '../cerberusBuyer/cerEntity/StrategyWeb';
import {CerConstants} from './CerConstants';
import {DateFun} from '../../__helpers/dates/DateFun';
import {AdGroupWeb} from '../cerberusBuyer/cerEntity/AdGroupWeb';
import {AdGroup} from '../cerberusBuyer/cerEntity/AdGroup';
import { CerMedia } from '../cerberusBuyer/cerEntity/CerMedia';
import {ValuesObj} from '../../__helpers/entities/ValuesObj';
import {SelectItem} from 'primeng/api';
import {FileRichMedia, Pixel} from '../../__helpers/entities/Media';
import {Capping} from '../../__helpers/entities/Capping';
import {Constants} from '../../__helpers/entities/Constants';
import {Advertiser} from '../../__helpers/entities/Advertiser';
import {CurrencyHelper} from '../../__helpers/entities/CurrencyHelper';
import {MetricsIds} from '../../__helpers/entities/MetricsIds';

export class CerFun {

  static getCampaignWebForm(myCampaign: CerCampaign, timezone: string): { [key: string]: any; } {
    const webControls = {
      'creativeGroups': new UntypedFormControl(myCampaign ? myCampaign.creativeGroups : []),
      'media': new UntypedFormControl(myCampaign ? myCampaign.media : []),
    }
    return {...CerFun.getCampaignForm(myCampaign, timezone, CerConstants.CHANNEL_WEB), ...webControls};
  }

  static getCampaignForm(myCampaign: CerCampaign, timezone: string, defaultChannel: number): { [key: string]: any; } {
    return {
      'id': new UntypedFormControl(myCampaign ? myCampaign.id : undefined),
      'idEntity': new UntypedFormControl(myCampaign ? myCampaign.idEntity : undefined),
      'advertiser': new UntypedFormControl(myCampaign ? myCampaign.advertiser : undefined, Validators.required),
      'name': new UntypedFormControl(myCampaign ? myCampaign.name : '', Validators.required),
      'campaignCurrency': new UntypedFormControl(myCampaign ? myCampaign.campaignCurrency : (CurrencyHelper.currencyEUR)),
      'market': new UntypedFormControl(myCampaign ? myCampaign.market : ''),
      'isManual': new UntypedFormControl(myCampaign ? myCampaign.isManual : CerConstants.AUTOMATIC),
      'salesOwner': new UntypedFormControl(myCampaign ? myCampaign.salesOwner : undefined),
      'status': new UntypedFormControl(myCampaign ? myCampaign.status : CerConstants.DRAFT),
      'timezone': new UntypedFormControl(myCampaign ? myCampaign.timezone : timezone),
      'campaignGoal': new UntypedFormControl(myCampaign ? myCampaign.campaignGoal : CerConstants.GOAL_BUTTON_BRAND_AWARENESS, Validators.required),
      'channels': new UntypedFormControl(myCampaign ? myCampaign.channels : [defaultChannel], Validators.required),

      'nonTransparentFee': new UntypedFormControl(myCampaign ? myCampaign.nonTransparentFee : undefined),
      'nonTransparentFeeType': new UntypedFormControl(myCampaign ? myCampaign.nonTransparentFeeType : CerConstants.FEE_ON_TOTAL_COST),

      'discrepancy': new UntypedFormControl(myCampaign ? myCampaign.discrepancy : undefined),
      'internalMargin': new UntypedFormControl(myCampaign ? myCampaign.internalMargin : undefined),
      'logs': new UntypedFormControl(myCampaign ? myCampaign.logs : undefined),
      'insertionOrder': new UntypedFormControl(myCampaign ? myCampaign.insertionOrder : []),
      'targetingGroups': new UntypedFormControl(myCampaign ? myCampaign.targetingGroups : []),
      'suppressedRules': new UntypedFormControl(myCampaign ? myCampaign.suppressedRules : []),
      'activities': new UntypedFormControl(myCampaign ? myCampaign.activities : undefined),
      'fbMode': new UntypedFormControl(myCampaign ? myCampaign.fbMode : defaultChannel === CerConstants.CHANNEL_FACEBOOK),
    }
  }


  static getPrenoWebForm(io: CerPreno): { [key: string]: any; } {
    const webControls = {
      'deliveryGoalValue' : new UntypedFormControl(io ? io.deliveryGoalValue : undefined),
      'deliveryGoalMetric' : new UntypedFormControl(io ? io.deliveryGoalMetric : undefined, Validators.required),
      'goalDelivered' : new UntypedFormControl(io ? io.goalDelivered : undefined),
      'goalExpected' : new UntypedFormControl(io ? io.goalExpected : undefined),
      'viewabilityGoal' : new UntypedFormControl(io ? io.viewabilityGoal : undefined),
      'learning_progress': new UntypedFormControl(io ? io.learning_progress : 0),
      'dnFee': new UntypedFormControl(io ? io.dnFee : undefined),
      'dnFeeType': new UntypedFormControl(io ? io.dnFeeType : CerConstants.FEE_ON_MEDIA_COST),
      'consultantFee': new UntypedFormControl(io ? io.consultantFee : undefined),
      'consultantFeeType': new UntypedFormControl(io ? io.consultantFeeType : undefined),
      'referrerFee': new UntypedFormControl(io ? io.referrerFee : undefined),
      'referrerFeeType': new UntypedFormControl(io ? io.referrerFeeType : CerConstants.FEE_ON_MEDIA_COST),
      'freeImpressionType' : new UntypedFormControl(io ? io.freeImpressionType : undefined),
      'freeImpressionValue' : new UntypedFormControl(io ? io.freeImpressionValue : undefined),
    }
    return {...CerFun.getPrenoForm(io), ...webControls};
  }

  static getPrenoForm(io: CerPreno, attributionMode?: number): { [key: string]: any; } {
    return {
      'id': new UntypedFormControl(io ? io.id : undefined),
      'budget': new UntypedFormControl(io ? io.budget : undefined, Validators.required),
      'budgetType': new UntypedFormControl(io ? io.budgetType : 0),
      'start': new UntypedFormControl(io ? io.start : undefined, Validators.required),
      'end': new UntypedFormControl(io ? io.end : undefined, Validators.required),
      'filename': new UntypedFormControl(io ? io.filename : undefined),
      'description': new UntypedFormControl(io ? io.description : ''),
      'mainConversionPixel' : new UntypedFormControl(io ? io.mainConversionPixel : undefined),
      'attributedConversionEvents' : new UntypedFormControl(io ? io.attributedConversionEvents : []),
      'eventAttribution' : new UntypedFormControl(io ? io.eventAttribution : attributionMode),
      'kpiGoalValue' : new UntypedFormControl(io ? io.kpiGoalValue : undefined, Validators.required),
      'kpiGoalMetric' : new UntypedFormControl(io ? io.kpiGoalMetric : undefined, Validators.required),
      'delivered_budget_value': new UntypedFormControl(io ? io.delivered_budget_value : undefined),
      'delivered_budget': new UntypedFormControl(io ? io.delivered_budget : undefined),
      'expected_delivered_budget': new UntypedFormControl(io ? io.expected_delivered_budget : undefined),
      'eligibilityPercentage': new UntypedFormControl(io ? io.eligibilityPercentage : 0),
      'advertiserForExtendedConversions': new UntypedFormControl(io ? io.advertiserForExtendedConversions : undefined),
      'extendedConversions': new UntypedFormControl(io ? io.extendedConversions : undefined),
      'loaded': new UntypedFormControl(io ? io.loaded : true),
      'adGroups': new UntypedFormControl(io ? io.adGroups : []),
    }
  }

  // Common fields adgroup
  static getAdgroupForm(ad: AdGroup, myPreno?: CerPreno, type?: string): { [key: string]: any; } {
    return {
      'id': new UntypedFormControl(ad ? ad.id : undefined),
      'name': new UntypedFormControl(ad ? ad.name : undefined, Validators.required),
      'status': new UntypedFormControl(ad ? ad.status : CerConstants.ACTIVE),
      'budgetPercentage': new UntypedFormControl(ad ? ad.budgetPercentage : undefined, [Validators.required, Validators.min(0.1)]),
      'start': new UntypedFormControl({value: ((ad && ad.start) ? ad.start : CerFun.getAdgroupStart(myPreno)), disabled: (ad && (ad.inDelivery === 1 || ad.delivered_budget > 0))}, Validators.required),
      'end': new UntypedFormControl((ad && ad.end) ? ad.end : ((myPreno && myPreno.end) ? myPreno.end + ' 23:59' : undefined), Validators.required),
      'isInterval': new UntypedFormControl(ad ? ad.isInterval : undefined),
      'fromInterval': new UntypedFormControl(ad ? ad.fromInterval : undefined),
      'toInterval': new UntypedFormControl(ad ? ad.toInterval : undefined),
      'intent': new UntypedFormControl(ad ? ad.intent : undefined, Validators.required),
      'goal': new UntypedFormControl(ad ? ad.goal : undefined),
      'goalMetric': new UntypedFormControl(ad ? ad.goalMetric : undefined),
      'cappingId': new UntypedFormControl(ad ? ad.cappingId : undefined),
      'biddingStrategy': new UntypedFormControl(ad ? ad.biddingStrategy : CerConstants.AUTOMATIC),
      'subType': new UntypedFormControl(ad ? ad.subType : type),
      'hasFlaggedRule': new UntypedFormControl(ad ? ad.hasFlaggedRule : undefined),
      'inDelivery': new UntypedFormControl(ad ? ad.inDelivery : undefined),
      'delivered_budget_value': new UntypedFormControl(ad ? ad.delivered_budget_value : undefined),
      'delivered_budget': new UntypedFormControl(ad ? ad.delivered_budget : undefined),
      'expected_delivered_budget': new UntypedFormControl(ad ? ad.expected_delivered_budget : undefined),
      'targetingGroups': new UntypedFormControl(ad ? ad.targetingGroups : []),
      'suppressedRules': new UntypedFormControl(ad ? ad.suppressedRules : []),
      'creativeGroups': new UntypedFormControl(ad ? ad.creativeGroups : []),
      'ads': new UntypedFormControl(ad ? ad.ads : []),
    }
  }

  // Web
  static getWebAdgroupForm(ad: AdGroupWeb, myPreno: CerPreno, type: string): { [key: string]: any; } {
    const webControls = {
      'kpi': new UntypedFormControl(ad ? ad.kpi : undefined),
      'auctionType': new UntypedFormControl(ad ? ad.auctionType : CerConstants.AUCTION_TYPE_OPEN),
      'adGroupDeal': new UntypedFormControl(ad ? ad.adGroupDeal : undefined),
      'capping': new UntypedFormControl(ad ? ad.capping : new Capping()),
      'adspaceFormat': new UntypedFormControl(ad ? ad.adspaceFormat : undefined, Validators.required),
      'priceIAS': new UntypedFormControl(ad ? ad.priceIAS : undefined)
    }
    return {...CerFun.getAdgroupForm(ad, myPreno, type), ...webControls};
  }

  static getCreativegroupForm(media: CreativeGroup, advertiser: Advertiser, campaignId: number, type: string, formatGroup: number, fb: UntypedFormBuilder, timezone: string): { [key: string]: any; } {
    return {
      'id': new UntypedFormControl(media ? media.id : undefined),
      'name': new UntypedFormControl(media ? media.name : undefined, Validators.required),
      'advertiserId': new UntypedFormControl(advertiser.id ? advertiser.id : undefined),
      'campaignId': new UntypedFormControl(campaignId ? campaignId : undefined),
      'subType': new UntypedFormControl(media ? media.subType : type),
      'intent': new UntypedFormControl(media ? media.intent : undefined, Validators.required),
      'formatGroup': new UntypedFormControl(media ? media.formatGroup : formatGroup),
      'connectedStrategies': new UntypedFormControl(media ? media.connectedStrategies : []),
      'sizeSpaces': new UntypedFormControl(media ? media.sizeSpaces : CerConstants.ALL_SIZES),
      'bannerSizes': new UntypedFormControl(media ? media.bannerSizes : []),
      'isTracking': new UntypedFormControl(media ? media.isTracking : undefined),
      'impressionTracker': new UntypedFormControl(media ? media.impressionTracker : undefined, Validators.pattern(Constants.isurlHttpRegexLight)),
      'start': new UntypedFormControl(media ? media.start : undefined),
      'end': new UntypedFormControl(media ? media.end : undefined),
      'days_week': new UntypedFormControl(media ? media.days_week : []),
      'isFlight': new UntypedFormControl(media ? media.isFlight : undefined),
      'timezone': new UntypedFormControl(media ? media.timezone : timezone),
      'landingPage': new UntypedFormControl(media ? media.landingPage : undefined, [Validators.required, Validators.pattern(Constants.isurlHttpRegexLight)]),
      'creatives': new UntypedFormControl(media ? media.creatives : undefined),
      'pixels': fb.array([]),
      'fkIdLabel': new UntypedFormControl(media ? media.fkIdLabel : undefined),
      'fkIdRelatedCampaign': new UntypedFormControl(media ? media.fkIdRelatedCampaign : undefined)
    }
  }

  static getWebMediaForm(media: CerMedia, myFormat: number, advertiserId: number, cg: CreativeGroup, fb: UntypedFormBuilder): { [key: string]: any; } {
    return {
      'id': new UntypedFormControl(media ? media.id : undefined),
      'name': new UntypedFormControl(media ? media.name : CerFun.getCreativeName(cg), Validators.required),
      'status': new UntypedFormControl(media ? media.status : CerConstants.ACTIVE),
      'advertiserId': new UntypedFormControl(media ? media.advertiserId : advertiserId),
      'formatGroup': new UntypedFormControl(media ? media.formatGroup : myFormat),
      'landing': new UntypedFormControl(media ? media.landing : cg.landingPage, Validators.pattern(Constants.isurlHttpRegexLight)),
      'intent': new UntypedFormControl(media ? media.intent : undefined),
      'isTracking': new UntypedFormControl(media ? media.isTracking : undefined),
      'impressionTracker': new UntypedFormControl(media ? media.impressionTracker : undefined, Validators.pattern(Constants.isurlHttpRegexLight)),
      'pixels': fb.array([]),
    }
  }

  static getBannerMediaForm(media: CerMedia, myFormat: number, advertiserId: number, fb: UntypedFormBuilder, cg: CreativeGroup): { [key: string]: any; } {
    const webControls = {
      'creativeType': new UntypedFormControl(media ? media.creativeType : CerConstants.HTML_CREATIVE_ID), // HTML, VAST, UPLOAD, TEXTLINK
      'size': new UntypedFormControl(media ? media.size : undefined, Validators.required),
      'width': new UntypedFormControl(media ? media.width : undefined),
      'height': new UntypedFormControl(media ? media.height : undefined),
      'html': new UntypedFormControl(media ? media.html : undefined),
      'upload': new UntypedFormControl(media ? media.upload : {}),
      'format': new UntypedFormControl(media ? (media.format ? media.format : myFormat) : myFormat),
      'margin_top': new UntypedFormControl(media ? media.margin_top : undefined),
      'site_width': new UntypedFormControl(media ? media.site_width : undefined),
      'fallbackImage': new UntypedFormControl(media ? media.fallbackImage : undefined),
    }
    return {...CerFun.getWebMediaForm(media, myFormat, advertiserId, cg, fb), ...webControls};
  }

  static getVideoMediaForm(media: CerMedia, myFormat: number, advertiserId: number, fb: UntypedFormBuilder, cg: CreativeGroup): { [key: string]: any; } {
    const webControls = {
      'creativeType': new UntypedFormControl(media ? media.creativeType : CerConstants.VAST_CREATIVE_ID), // HTML, VAST, UPLOAD, TEXTLINK
      'format': new UntypedFormControl(media ? (media.format ? media.format : myFormat) : myFormat),
      'vast': new UntypedFormControl(media ? media.vast : undefined, Validators.pattern(Constants.isurlHttpRegexLight)),
      'vpaidDisabled': new UntypedFormControl(media ? media.vpaidDisabled : undefined),
      'checkboxVpaidVisible': new UntypedFormControl(media ? media.checkboxVpaidVisible : undefined),
      'upload': new UntypedFormControl(media ? media.upload : {}),
    }
    return {...CerFun.getWebMediaForm(media, myFormat, advertiserId, cg, fb), ...webControls};
  }

  static getNativeMediaForm(media: CerMedia, myFormat: number, advertiser: Advertiser, fb: UntypedFormBuilder, cg: CreativeGroup): { [key: string]: any; } {
    const webControls = {
      'creativeType': new UntypedFormControl(media ? media.creativeType : CerConstants.TEXTLINK_CREATIVE_ID),
      // 'brand': new FormControl(media ? media.brand : (advertiser.domain ? Constants.urlRegex.exec(advertiser.domain)[3] : ' ')),
      // 'rgbColor': new FormControl(media ? media.rgbColor : undefined),
      // 'ctaByLang': fb.array([]),
      'isTracking': new UntypedFormControl(media ? media.isTracking : 1),
      'format': new UntypedFormControl(media ? (media.format ? media.format : myFormat) : myFormat),
      'textlink': fb.group({
        'title': new UntypedFormControl(media && media.textlink ? media.textlink.title : undefined, Validators.required),
        'description': new UntypedFormControl(media && media.textlink ? media.textlink.description : undefined),
        'thumbnail': new UntypedFormControl(media && media.textlink ? media.textlink.thumbnail : undefined),
        'contained': new UntypedFormControl(media && media.textlink ? media.textlink.contained : 1),
        'focus': new UntypedFormControl(media && media.textlink ? media.textlink.focus : new ValuesObj()),
      }),
    }
    return {...CerFun.getWebMediaForm(media, myFormat, advertiser.id, cg, fb), ...webControls};
  }

  static getParallaxMediaForm(media: CerMedia, myFormat: number, advertiserId: number, fb: UntypedFormBuilder, cg: CreativeGroup): { [key: string]: any; } {
    const webControls = {
      'creativeType': new UntypedFormControl(media ? media.creativeType : CerConstants.RICHMEDIA_CREATIVE_ID),
      'format': new UntypedFormControl(media ? media.format : CerConstants.FORMAT_PARALLAX),
      // 'rgbColor': new FormControl(media ? media.rgbColor : undefined),
      'richMedia': fb.array([]),
    }
    return {...CerFun.getWebMediaForm(media, myFormat, advertiserId, cg, fb), ...webControls};
  }

  static getCarouselMediaForm(media: CerMedia, myFormat: number, advertiserId: number, fb: UntypedFormBuilder, cg: CreativeGroup): { [key: string]: any; } {
    const webControls = {
      'creativeType': new UntypedFormControl(media ? media.creativeType : CerConstants.RICHMEDIA_CREATIVE_ID),
      'format': new UntypedFormControl(media ? media.format : CerConstants.FORMAT_CAROUSEL),
      'rgbColor': new UntypedFormControl(media ? media.rgbColor : undefined),
      'logo': new UntypedFormControl(media ? media.logo : undefined, Validators.required),
      'ctaByLang': fb.array([], [Validators.required, Validators.minLength(1)]),
      'richMedia': fb.array([]),
    }
    return {...CerFun.getWebMediaForm(media, myFormat, advertiserId, cg, fb), ...webControls};
  }

  static getSkinMediaForm(media: CerMedia, myFormat: number, advertiserId: number, fb: UntypedFormBuilder, cg: CreativeGroup): { [key: string]: any; } {
    const webControls = {
      'creativeType': new UntypedFormControl(media ? media.creativeType : CerConstants.UPLOAD_CREATIVE_ID),
      'format': new UntypedFormControl(media ? media.format : CerConstants.FORMAT_SKIN),
      'skinType': new UntypedFormControl(media ? media.skinType : 0),
      'margin_top': new UntypedFormControl(media ? media.margin_top : undefined),
      'rgbColor': new UntypedFormControl(media ? media.rgbColor : undefined),
      'upload': fb.group({
        'file': new UntypedFormControl(media && media.upload ? media.upload.file : undefined, Validators.required),
        'file2': new UntypedFormControl(media && media.upload ? media.upload.file2 : undefined),
        'file3': new UntypedFormControl(media && media.upload ? media.upload.file3 : undefined),
      }),
    }
    return {...CerFun.getWebMediaForm(media, myFormat, advertiserId, cg, fb), ...webControls};
  }

  static getPixelForm(pixel: Pixel, pixelType?: number): { [key: string]: any; } {
    return {
      'id': new UntypedFormControl(pixel ? pixel.id : undefined),
      'url': new UntypedFormControl(pixel ? pixel.url : undefined, [Validators.required, Validators.pattern(Constants.isurlHttpRegexLight)]),
      'eventId': new UntypedFormControl(pixel ? pixel.eventId : pixelType),
    }
  }

  static getCtaByLangForm(io: SelectItem): { [key: string]: any; } {
    return {
      'value': new UntypedFormControl(io ? io.value : undefined, Validators.required),
      'label': new UntypedFormControl(io ? io.label : undefined, Validators.required),
    }
  }

  static getRichMediaParallaxForm(rich: FileRichMedia): { [key: string]: any; } {
    return {
      'url': new UntypedFormControl(rich ? rich.url : undefined),
      'landingPage': new UntypedFormControl(rich ? rich.landingPage : undefined, Validators.pattern(Constants.isurlHttpRegexLight)),
      // 'text': new FormControl(rich ? rich.text : undefined),
      'format': new UntypedFormControl(CerConstants.FORMAT_BANNER)
    }
  }

  static getRichMediaCarouselForm(rich: FileRichMedia): { [key: string]: any; } {
    return {
      'url': new UntypedFormControl(rich ? rich.url : undefined),
      'landingPage': new UntypedFormControl(rich ? rich.landingPage : undefined, Validators.pattern(Constants.isurlHttpRegexLight)),
      'text': new UntypedFormControl(rich ? rich.text : undefined),
      'storeLanding': new UntypedFormControl(rich ? rich.storeLanding : undefined),
      'price': new UntypedFormControl(rich ? rich.price : undefined),
      'discountedPrice': new UntypedFormControl(rich ? rich.discountedPrice : undefined),
    }
  }

  static getCappingForm(capping: Capping): { [key: string]: any; } {
    return {
      'deliver': new UntypedFormControl(capping ? capping.deliver : undefined, Validators.required),
      'eachNumber': new UntypedFormControl(capping ? capping.eachNumber : undefined, Validators.required),
      'everyNumber': new UntypedFormControl(capping ? capping.everyNumber : undefined, Validators.required),
      'deliverType': new UntypedFormControl(capping ? capping.deliverType : undefined, Validators.required),
      'eachType': new UntypedFormControl(capping ? capping.eachType : undefined, Validators.required),
      'everyType': new UntypedFormControl(capping ? capping.everyType : undefined, Validators.required),
      'disabled': new UntypedFormControl(capping ? capping.disabled : undefined),
    }
  }

  static getShortCappingForm(capping: Capping, mydevice: number): { [key: string]: any; } {
    return {
      'deliver': new UntypedFormControl(capping ? capping.deliver : undefined, Validators.required),
      'everyNumber': new UntypedFormControl(capping ? capping.everyNumber : undefined, Validators.required),
      'everyType': new UntypedFormControl(capping ? capping.everyType : undefined, Validators.required),
      'device': new UntypedFormControl(capping ? capping.device : (mydevice !== undefined ? mydevice : 0)),
      'disabled': new UntypedFormControl(capping ? capping.disabled : undefined),
    }
  }

  static removeIdsFromWebStrategy(ad: StrategyWeb, myAdgroup?: AdGroup): StrategyWeb {
    delete ad.id;
    delete ad.monitor; // Delivery
    delete ad.inDelivery; // Delivery
    ad.adName = ad.adName + '(2)';
    if (ad.flights) {
      delete ad.flights.id;
      if (myAdgroup) {
        ad.flights.from = myAdgroup.start;
        ad.flights.to = myAdgroup.end;
      }
    }
    ad.supplyRequired = undefined;
    ad.deliveredTotal = undefined;
    if (ad.targetingGroups && ad.targetingGroups.length > 0) {
      for (const tar of ad.targetingGroups) {
        delete tar.id;
        for (const ta of tar.targetingRules) {
          delete ta.id;
        }
      }
    }
    if (ad.htmlTargetingGroups && ad.htmlTargetingGroups.length > 0) {
      for (const tar of ad.htmlTargetingGroups) {
        delete tar.id;
        for (const ta of tar.targetingRules) {
          delete ta.id;
        }
      }
    }
    if (ad.kpi && ad.kpi.length > 0) {
      for (const kpi of ad.kpi) {
        delete kpi.id;
      }
    }
    return ad;
  }

  static getCreativeName(cg: CreativeGroup) {
    if (cg && cg.formatGroup !== CerConstants.FORMAT_BANNER) {
      return CerConstants.INTENTS_MAP.get(cg.intent);
    }
  }

  static setRightPreno(prenoArray: CerPreno[]): CerPreno {
    for (const preno of prenoArray) {
      if (CerFun.checkTemporalPreno(preno) === 0) {
        return preno;
      }
    }
    return prenoArray[0];
  }

  /**
   * Stabilisce se una preno è passata (-1), presente (0) o futura (1)
   * @param preno da testare
   */
  static checkTemporalPreno(preno: CerPreno): number {
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    const nowTime: number = new Date().getTime()
    if (preno.start && preno.end && (preno.status !== CerConstants.DELETED)) {
      const start = DateFun.getDateFromString(preno.start).getTime();
      const end = DateFun.getDateFromString(preno.end, true).getTime();
      if (start <= nowTime && end + millisecondsPerDay >= nowTime) { return 0; }
      if (start < nowTime && end + millisecondsPerDay < nowTime) { return -1; }
      if (start > nowTime && end > nowTime) { return 1; }
    }
  }

  static getStartHour(myPreno: CerPreno) {
    if (myPreno && myPreno.start && DateFun.isToday(DateFun.getDateFromString(myPreno.start))) {
      const nowDate = new Date();
      return ' ' + DateFun.pad(nowDate.getHours()) + ':' + DateFun.pad(nowDate.getMinutes());
    }
    return ' 00:00';
  }

  static getAdgroupStart(myPreno: CerPreno) {
    if (DateFun.stringToTimestamp(myPreno.start) < new Date().getTime()) {
      return DateFun.getCompleteStringFromDate(new Date());
    }
    return (myPreno && myPreno.start) ? myPreno.start + CerFun.getStartHour(myPreno) : undefined;
  }

  static isVoid(elem: any): boolean {
    return elem === undefined || elem === null || elem === '';
  }

  static isVoidArray(elem: any): boolean {
    return elem === undefined || elem === null || elem.length === 0;
  }

  static evaluateResult(budget: number, goalValue: number, goalMetric: number): number {
    if (budget && goalValue && goalMetric) {
      switch (goalMetric) {
        case MetricsIds.CPM: {
          return Math.round(budget * 1000 / goalValue);
        }
        case MetricsIds.CPC:
        case MetricsIds.CPCV:
        case MetricsIds.COST_PER_LANDING:
        case MetricsIds.CPA:
        case MetricsIds.NEW_CPA: {
          return Math.round(budget / goalValue);
        }
        case MetricsIds.CPS: {
          return Math.round(budget * goalValue / 100);
        }
      }
    }
  }

  static evaluateFromResult(budget: number, goalResult: number, goalMetric: number): number {
    if (budget && goalMetric) {
      if (!goalResult) {
        return undefined;
      }
      switch (goalMetric) {
        case MetricsIds.CPM: {
          return Math.round((budget * 1000 / goalResult) * 100) / 100;
        }
        case MetricsIds.CPC: case MetricsIds.CPA: case MetricsIds.NEW_CPA: case MetricsIds.COST_PER_LANDING: case MetricsIds.CPCV: {
          return Math.round((budget / goalResult) * 100) / 100;
        }
        case MetricsIds.CPS: {
          return Math.round(goalResult * 100 * 100 / budget) / 100;
        }
      }
    }
  }

  static clearReportFields(myRow: any): any {
    myRow['COST PER LANDING'] = undefined;
    myRow['CPA'] = undefined;
    myRow['NEW CPA'] = undefined;
    myRow['CPC (Total Cost)'] = undefined;
    myRow['CPS'] = undefined;
    myRow['CTR'] = undefined;
    myRow['Click'] = undefined;
    myRow['INTERNAL SPENT'] = undefined;
    myRow['Impression'] = undefined;
    myRow['Total Cost'] = undefined;
    myRow['VIEWABILITY'] = undefined;
    myRow['kpiGoal'] = undefined;
    myRow['kpiMetric'] = undefined;
    myRow['kpiValue'] = undefined;
    myRow['kpiGoodness'] = undefined;
    return myRow;
  }
}
