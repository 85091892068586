import {Constants} from './entities/Constants';

export class UserAs {
  static userAsID: number; // ID dell'utente con cui sto accedendo

  static emptyFields() {
    this.userAsID = undefined;
    localStorage.removeItem(Constants.USER_KEY_LOCALSTORAGE);
  }
}
